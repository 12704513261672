import service from '@/plugins/axios';

// 廣告
const root = '/a/a107';

export function ad(params) {
  return service({
    url: `${root}/ad`,
    method: 'get',
    params,
  });
}

export function carousels(params) {
  return service({
    url: `${root}/carousels`,
    method: 'get',
    params,
  });
}
