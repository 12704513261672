import metaBg from '@/assets/meta/meta_bg.png';

const publisherRouter = {
  path: 'publisher/:identity/:id',
  component: () => import('@/views/publisher/PublisherView.vue'),
  children: [
    {
      path: '',
      name: 'publisherHome',
      component: () => import('@/views/publisher/page/HomeView.vue'),
      props: { store: true },
      meta: {
        title: '',
        description: 'about.description1',
        image: metaBg,
      },
    },
    {
      path: 'rental',
      component: () => import('@/views/publisher/page/RentalList.vue'),
      children: [
        {
          path: '',
          name: 'publisherRental',
          meta: {
            title: 'publisher.rentList',
            description: 'about.description1',
            image: metaBg,
          },
        },
        {
          path: 'factory',
          name: 'publisherRentalFactory',
          meta: {
            title: 'publisher.rentList',
            description: 'about.description1',
            image: metaBg,
          },
        },
        {
          path: 'land',
          name: 'publisherRentalLand',
          meta: {
            title: 'publisher.rentList',
            description: 'about.description1',
            image: metaBg,
          },
        },
        {
          path: 'office',
          name: 'publisherRentalOffice',
          meta: {
            title: 'publisher.rentList',
            description: 'about.description1',
            image: metaBg,
          },
        },
        {
          path: 'shop',
          name: 'publisherRentalShop',
          meta: {
            title: 'publisher.rentList',
            description: 'about.description1',
            image: metaBg,
          },
        },
      ],
    },
    {
      path: 'sale',
      component: () => import('@/views/publisher/page/RentalList.vue'),
      children: [
        {
          path: '',
          name: 'publisherSale',
          meta: {
            title: 'publisher.saleList',
            description: 'about.description1',
            image: metaBg,
          },
        },
        {
          path: 'factory',
          name: 'publisherSaleFactory',
          meta: {
            title: 'publisher.saleList',
            description: 'about.description1',
            image: metaBg,
          },
        },
        {
          path: 'land',
          name: 'publisherSaleLand',
          meta: {
            title: 'publisher.saleList',
            description: 'about.description1',
            image: metaBg,
          },
        },
        {
          path: 'office',
          name: 'publisherSaleOffice',
          meta: {
            title: 'publisher.saleList',
            description: 'about.description1',
            image: metaBg,
          },
        },
        {
          path: 'shop',
          name: 'publisherSaleShop',
          meta: {
            title: 'publisher.saleList',
            description: 'about.description1',
            image: metaBg,
          },
        },
      ],
    },
    {
      path: 'introduce',
      name: 'publisherIntroduce',
      meta: {
        title: 'publisher.tabs.detail',
        description: 'about.description1',
        image: metaBg,
      },
    },
    {
      path: 'message',
      name: 'publisherMessage',
      component: () => import('@/views/publisher/page/MessageView.vue'),
      props: { store: true },
      meta: {
        title: 'publisher.tabs.message',
        description: 'about.description1',
        image: metaBg,
      },
    },
  ],
};
export default publisherRouter;
