import store from '@/store';

const initFacebook = () => {
  window.fbAsyncInit = function () {
    /* global FB */
    FB.init({
      appId: '691476263121446',
      cookie: true,
      xfbml: true,
      version: 'v18.0',
    });
    store.commit('SET_FB', FB);
    FB.AppEvents.logPageView();
  };
};

export default initFacebook;
