import metaBg from '@/assets/meta/meta_bg.png';

const factoryRouter = {
  path: 'factory',
  name: 'factory',
  component: () => import('@/views/rent/RentView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.factory',
        pathName: 'factory',
      },
    ],
    title: 'factoryList.title',
    enTitle: 'factoryList.enTitle',
    description: 'about.description1',
    image: metaBg,
    recommendedTitle: 'factoryList.recommended',
    allTitle: 'factoryList.forFactory',
  },
};
export default factoryRouter;
