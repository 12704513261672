<template>
  <div class="home_contentView--swiperView">
    <button
      class="avatar_button--md swiper-button-prev"
      @click.stop="prevEl"
    >
      <SvgIcon
        icon-class="arrow_left"
        class-name="form_imgView--icon"
      />
    </button>
    <div class="home_contentView--swiper">
      <swiper
        v-if="!bestNodataText"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
        :pagination="{
          clickable: true,
        }"
        :modules="modules"
        :slides-per-view="bestNodataText || isMobile ? 1 : 4"
        :space-between="12"
      >
        <swiper-slide v-for="obj in bestLand" :key="obj.id">
          <LandCard
            :object="obj"
            type="land_5"
            :is-load="bestLoading"
            @favorite="
              () => {
                favoriteClick(obj);
              }
            "
          />
        </swiper-slide>
      </swiper>
      <span
          v-else
          class="home_contentView--nodata"
      >{{ bestNodataText }}</span>
    </div>
    <button
      class="avatar_button--md swiper-button-next"
      @click="nextEl"
    >
      <SvgIcon
        icon-class="arrow_right"
        class-name="form_imgView--icon"
      />
    </button>
  </div>
</template>

<script>
import LandCard from '@/components/card/LandCard.vue';
import { bestLand } from '@/plugins/api/a/a109';
import { mapGetters } from 'vuex';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
  favorite,
  unFavorite,
} from '@/plugins/api/b/b102';

export default {
  name: 'BestLand',
  props: {
    choose: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'lang', 'bestLand', 'suitableLand']),
  },
  components: {
    LandCard,
    Swiper,
    SwiperSlide,
    Navigation,
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
  data() {
    return {
      bestLoading: false,
      bestNodataText: null,
      swiperOption: {
        pagination: '.swiper-pagination',
        clickable: true,
      },
    };
  },
  watch: {
    choose(value, oldValue) {
      if (value !== oldValue) {
        this.getConditionList();
      }
    },
  },
  created() {
    this.getConditionList();
  },
  methods: {
    getConditionList() {
      this.$store.commit('UPDATE_BEST_LAND', []);
      this.bestNodataText = null;
      this.bestLoading = true;
      bestLand({
        page: 1,
        limit: 10,
        lang: this.lang,
        type: this.choose,
      }).then((res) => {
        this.$store.commit('UPDATE_BEST_LAND', res.data.list);
        if (res.data.list.length === 0) {
          this.bestNodataText = this.$t('noDataText.noData');
        }
        setTimeout(() => {
          this.bestLoading = false;
        }, 500);
      }).catch(() => {
        this.bestLoading = false;
      });
    },
    favoriteClick(obj) {
      if (obj.favorite) {
        unFavorite(obj.id).then((res) => {
          const { success } = res;
          if (success) {
            obj.favorite = false;
            this.$toast.success(this.$t('noticeText.removeFavorite'));
            if (this.suitableLand.length > 0) {
              this.suitableLand.forEach((item) => {
                if (item.id === obj.id) {
                  item.favorite = false;
                }
              });
            }
          }
        }).catch((e) => {
          console.log(e);
        });
      } else {
        favorite({
          id: obj.id,
        }).then((res) => {
          const { success } = res;
          if (success) {
            obj.favorite = true;
            this.$toast.success(this.$t('noticeText.addFavorite'));
            if (this.suitableLand.length > 0) {
              this.suitableLand.forEach((item) => {
                if (item.id === obj.id) {
                  item.favorite = true;
                }
              });
            }
          }
        }).catch((e) => {
          console.log(e);
        });
      }
    },
  },
};
</script>
