<template>
  <div class="dialog" :class="{ 'show': show }">
    <div class="dialog_content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DialogTemplate',
  props: {
    show: {
      type: Boolean,
    },
  },
  watch: {
    show(value) {
      const html = document.querySelector('html');
      if (value) {
        html.style.overflow = 'hidden';
      } else {
        html.removeAttribute('style');
      }
    },
  },
};
</script>
