import service from '@/plugins/axios';

// 辦公室
const root = '/a/a110';

// 精選辦公室
export function bestOffice(params) {
  return service({
    url: `${root}/best_office`,
    method: 'get',
    params,
  });
}

// 適合辦公室
export function suitableOffice(params) {
  return service({
    url: `${root}/suitable_office`,
    method: 'get',
    params,
  });
}

// 推薦辦公室
export function recommendOffice(params) {
  return service({
    url: `${root}/recommend_office`,
    method: 'get',
    params,
  });
}

// 條件辦公室
export function conditionOffice(params) {
  return service({
    url: `${root}/condition_office`,
    method: 'get',
    params,
  });
}

// 相似辦公室
export function similarOffice(params) {
  return service({
    url: `${root}/similar_office`,
    method: 'get',
    params,
  });
}

// 辦公室詳細
export function officeDetail(params) {
  return service({
    url: `${root}/office_detail`,
    method: 'get',
    params,
  });
}

// 今日新增辦公室數
export function officeOfTodayCount(params) {
  return service({
    url: `${root}/today_office_count`,
    method: 'get',
    params,
  });
}
