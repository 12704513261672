import service from '@/plugins/axios';

// 廠房
const root = '/a/a108';

// 精選廠房
export function bestFactory(params) {
  return service({
    url: `${root}/best_factory`,
    method: 'get',
    params,
  });
}

// 適合廠房
export function suitableFactory(params) {
  return service({
    url: `${root}/suitable_factory`,
    method: 'get',
    params,
  });
}

// 推薦廠房
export function recommendFactory(params) {
  return service({
    url: `${root}/recommend_factory`,
    method: 'get',
    params,
  });
}

// 條件廠房
export function conditionFactory(params) {
  return service({
    url: `${root}/condition_factory`,
    method: 'get',
    params,
  });
}

// 相似廠房
export function similarFactory(params) {
  return service({
    url: `${root}/similar_factory`,
    method: 'get',
    params,
  });
}

// 廠房詳情
export function factoryDetail(params) {
  return service({
    url: `${root}/factory_detail`,
    method: 'get',
    params,
  });
}

// 今日新增廠房數
export function factoryOfTodayCount(params) {
  return service({
    url: `${root}/today_count`,
    method: 'get',
    params,
  });
}
