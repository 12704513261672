const factoryRentDetailRouter = {
  path: 'factory/rent/:id',
  name: 'factoryRentDetail',
  component: () => import('@/views/detail/factory/DetailView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.factory',
        pathName: 'factory',
      },
      {
        name: 'breadcrumbList.rent',
        pathName: 'factory',
      },
      {
        name: ':id',
        pathName: 'factoryRentDetail',
      },
    ],
    title: '',
    description: '',
    image: '',
  },
};
export default factoryRentDetailRouter;
