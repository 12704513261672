import metaBg from '@/assets/meta/meta_bg.png';

const guestRouter = {
  path: 'guest',
  component: () => import('@/views/memberCenter/page/HomeView.vue'),
  children: [
    // 首頁
    {
      path: 'home',
      name: 'guestHome',
      component: () => import('@/views/memberCenter/page/common/home/HomeView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'guestHome',
          },
        ],
        title: 'memberCenter.home',
        description: 'about.description1',
        image: metaBg,
        scrollAnchor: true,
        requiredAuth: true,
      },
    },
    // 帳號
    {
      path: 'account',
      component: () => import('@/views/memberCenter/page/common/account/AccountView.vue'),
      children: [
        {
          path: '',
          name: 'guestAccount',
          component: () => import('@/views/memberCenter/page/common/account/InfoView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.account',
                pathName: 'guestAccount',
              },
            ],
            title: 'memberCenter.account',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'requirements',
          name: 'guestRequirements',
          component: () => import('@/views/memberCenter/page/common/account/requirements/RequirementsView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.account',
                pathName: 'guestAccount',
              },
              {
                name: 'breadcrumbList.myRequirements',
                pathName: 'guestRequirements',
              },
            ],
            title: 'breadcrumbList.myRequirements',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
      ],
    },
    {
      path: 'account/change-phone',
      name: 'guestChangePhone',
      component: () => import('@/views/memberCenter/page/common/account/ChangePhone.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'guestHome',
          },
          {
            name: 'breadcrumbList.account',
            pathName: 'guestAccount',
          },
          {
            name: 'breadcrumbList.changePhone',
            pathName: 'guestChangePhone',
          },
        ],
        title: 'breadcrumbList.changePhone',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'account/change-password',
      name: 'guestChangePwd',
      component: () => import('@/views/memberCenter/page/common/account/ChangePwd.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'guestHome',
          },
          {
            name: 'breadcrumbList.account',
            pathName: 'guestAccount',
          },
          {
            name: 'breadcrumbList.changePwd',
            pathName: 'guestChangePwd',
          },
        ],
        title: 'breadcrumbList.changePwd',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    // 訊息
    {
      path: 'message',
      component: () => import('@/views/memberCenter/page/common/message/MessageLayout.vue'),
      children: [
        {
          path: '',
          name: 'guestMessage',
          component: () => import('@/views/memberCenter/page/common/message/message/MessageView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.message',
                pathName: 'guestMessage',
              },
            ],
            title: 'breadcrumbList.message',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'qa/house',
          name: 'guestHouseQA',
          component: () => import('@/views/memberCenter/page/common/message/qa/HouseQA.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.message',
                pathName: 'guestMessage',
              },
              {
                name: 'breadcrumbList.houseQA',
                pathName: 'guestHouseQA',
              },
            ],
            title: 'breadcrumbList.houseQA',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'qa/shop',
          name: 'guestStoreQA',
          component: () => import('@/views/memberCenter/page/common/message/qa/StoreQA.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.message',
                pathName: 'guestMessage',
              },
              {
                name: 'breadcrumbList.storeQA',
                pathName: 'guestStoreQA',
              },
            ],
            title: 'breadcrumbList.storeQA',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'impeach',
          name: 'guestHouseImpeach',
          component: () => import('@/views/memberCenter/page/common/message/impeach/HouseImpeach.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.message',
                pathName: 'guestMessage',
              },
              {
                name: 'breadcrumbList.impeach',
                pathName: 'guestHouseImpeach',
              },
            ],
            title: 'breadcrumbList.impeach',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'matching',
          name: 'guestHouseMatching',
          component: () => import('@/views/memberCenter/page/common/message/matching/MatchingView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.message',
                pathName: 'guestMessage',
              },
              {
                name: 'memberCenter.matchingObject',
                pathName: 'guestHouseMatching',
              },
            ],
            title: 'memberCenter.matchingObject',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
      ],
    },
    {
      path: 'message/content/:id',
      name: 'guestMessageContent',
      component: () => import('@/views/memberCenter/page/common/message/message/MessageDetail.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'guestHome',
          },
          {
            name: 'breadcrumbList.message',
            pathName: 'guestMessage',
          },
          {
            name: 'breadcrumbList.messageContent',
            pathName: 'guestMessageContent',
          },
        ],
        title: 'breadcrumbList.messageContent',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'message/qa/house/content/:id',
      name: 'guestHouseQAContent',
      component: () => import('@/views/memberCenter/page/common/message/qa/HouseQADetail.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'guestHome',
          },
          {
            name: 'breadcrumbList.message',
            pathName: 'guestMessage',
          },
          {
            name: 'breadcrumbList.houseQA',
            pathName: 'guestHouseQA',
          },
          {
            name: ':id',
            pathName: 'guestHouseQAContent',
          },
        ],
        title: 'breadcrumbList.houseQA',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'message/qa/shop/content/:id',
      name: 'guestStoreQAContent',
      component: () => import('@/views/memberCenter/page/common/message/qa/StoreQADetail.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'guestHome',
          },
          {
            name: 'breadcrumbList.message',
            pathName: 'guestMessage',
          },
          {
            name: 'breadcrumbList.storeQA',
            pathName: 'guestStoreQA',
          },
          {
            name: ':id',
            pathName: 'guestStoreQAContent',
          },
        ],
        title: 'breadcrumbList.storeQA',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'message/impeach/content/:id',
      name: 'guestImpeachContent',
      component: () => import('@/views/memberCenter/page/common/message/impeach/HouseImpeachDetail.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'guestHome',
          },
          {
            name: 'breadcrumbList.message',
            pathName: 'guestMessage',
          },
          {
            name: 'breadcrumbList.impeach',
            pathName: 'guestHouseImpeach',
          },
          {
            name: ':id',
            pathName: 'guestImpeachContent',
          },
        ],
        title: 'breadcrumbList.impeach',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'message/matching/content/:id',
      name: 'guestMatchingContent',
      component: () => import('@/views/memberCenter/page/common/message/matching/MatchingDetail.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'guestHome',
          },
          {
            name: 'breadcrumbList.message',
            pathName: 'guestMessage',
          },
          {
            name: 'memberCenter.matchingObject',
            pathName: 'guestHouseMatching',
          },
          {
            name: ':id',
            pathName: 'guestMatchingContent',
          },
        ],
        title: 'memberCenter.matchingObject',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    // 紀錄
    {
      path: 'history',
      component: () => import('@/views/memberCenter/page/common/history/HistoryLayout.vue'),
      children: [
        {
          path: '',
          name: 'guestHistory',
          component: () => import('@/views/memberCenter/page/common/history/HistoryView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.history',
                pathName: 'guestHistory',
              },
            ],
            title: 'breadcrumbList.history',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'favorite',
          name: 'guestHistoryFavorite',
          component: () => import('@/views/memberCenter/page/common/history/HistoryView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.history',
                pathName: 'guestHistory',
              },
              {
                name: 'breadcrumbList.favoriteObjects',
                pathName: 'guestHistoryFavorite',
              },
            ],
            title: 'breadcrumbList.favoriteObjects',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
      ],
    },
    // 推廣
    {
      path: 'promotions',
      component: () => import('@/views/memberCenter/page/common/promotions/PromotionsLayout.vue'),
      children: [
        {
          path: '',
          name: 'guestPromotions',
          component: () => import('@/views/memberCenter/page/common/promotions/PromotionsView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.promotions',
                pathName: 'guestPromotions',
              },
            ],
            title: 'breadcrumbList.promotions',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
        {
          path: ':id',
          name: 'guestPromotionsDetail',
          component: () => import('@/views/memberCenter/page/common/promotions/PromotionsDetail.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.promotions',
                pathName: 'guestPromotions',
              },
              {
                name: 'breadcrumbList.activityDetails',
                pathName: 'guestPromotionsDetail',
              },
            ],
            title: 'breadcrumbList.activityDetails',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
      ],
    },
    // 交易
    {
      path: 'transaction',
      component: () => import('@/views/memberCenter/page/common/transaction/TransactionLayout.vue'),
      children: [
        {
          path: '',
          name: 'guestTransaction',
          component: () => import('@/views/memberCenter/page/common/transaction/point/TransactionView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.transaction',
                pathName: 'guestTransaction',
              },
            ],
            title: 'breadcrumbList.transaction',
            description: 'about.description1',
            image: metaBg,
            showBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'plan',
          name: 'guestTransactionOfPlan',
          component: () => import('@/views/memberCenter/page/common/transaction/plan/PlanView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.plansManagement',
                pathName: 'guestTransactionOfPlan',
              },
            ],
            title: 'breadcrumbList.plansManagement',
            description: 'about.description1',
            image: metaBg,
            showBtn: false,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'cash',
          name: 'guestTransactionOfCash',
          component: () => import('@/views/memberCenter/page/common/transaction/cash/CashView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.cashManagement',
                pathName: 'guestTransactionOfCash',
              },
            ],
            title: 'breadcrumbList.cashManagement',
            description: 'about.description1',
            image: metaBg,
            showBtn: false,
            requiredAuth: true,
          },
        },
        // TODO: 信用卡先隱藏
        // {
        //   path: 'credit-card',
        //   name: 'guestCreditCard',
        //   component: () => import('@/views/memberCenter/page/common/transaction/creditCard/CreditCard.vue'),
        //   meta: {
        //     breadcrumbs: [
        //       {
        //         name: 'breadcrumbList.home',
        //         pathName: 'home',
        //       },
        //       {
        //         name: 'breadcrumbList.paymentManagement',
        //         pathName: 'guestCreditCard',
        //       },
        //     ],
        //     showBtn: false,
        //   },
        // },
      ],
    },
    {
      path: 'transaction/purchase/:type/:id',
      name: 'guestPurchasePlan',
      component: () => import('@/views/memberCenter/page/common/transaction/PurchasePlan.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'guestHome',
          },
          {
            name: 'breadcrumbList.plansManagement',
            pathName: 'guestTransactionOfPlan',
          },
          {
            name: 'breadcrumbList.purchasePlan',
            pathName: 'guestPurchasePlan',
          },
        ],
        title: 'breadcrumbList.purchasePlan',
        description: 'about.description1',
        image: metaBg,
        showBtn: false,
        requiredAuth: true,
      },
    },
    // TODO: 信用卡先隱藏
    // {
    //   path: 'credit-card/add',
    //   name: 'guestAddCreditCard',
    //   component: () => import('@/views/memberCenter/page/common/transaction/creditCard/AddCreditCard.vue'),
    //   meta: {
    //     breadcrumbs: [
    //       {
    //         name: 'breadcrumbList.home',
    //         pathName: 'home',
    //       },
    //       {
    //         name: 'breadcrumbList.paymentManagement',
    //         pathName: 'guestCreditCard',
    //       },
    //       {
    //         name: 'breadcrumbList.addNewCard',
    //         pathName: 'guestAddCreditCard',
    //       },
    //     ],
    //     showBtn: false,
    //   },
    // },
    {
      path: 'advertisement',
      component: () => import('@/views/memberCenter/page/common/advertisement/AdLayout.vue'),
      children: [
        {
          path: '',
          name: 'guestAdvertisement',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'guestAdvertisement',
              },
            ],
            title: 'breadcrumbList.advertisement',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
        {
          path: 'closed',
          name: 'guestAdvertisementClosed',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'guestAdvertisementClosed',
              },
            ],
            title: 'breadcrumbList.advertisement',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application',
          name: 'guestAdvertisementApplication',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'guestAdvertisement',
              },
              {
                name: 'breadcrumbList.advertisementApplicationRecord',
                pathName: 'guestAdvertisementApplication',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/approve',
          name: 'guestAdvertisementApplicationApprove',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'guestAdvertisement',
              },
              {
                name: 'breadcrumbList.advertisementApplicationRecord',
                pathName: 'guestAdvertisementApplicationApprove',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/review',
          name: 'guestAdvertisementApplicationReview',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'guestAdvertisement',
              },
              {
                name: 'breadcrumbList.advertisementApplicationRecord',
                pathName: 'guestAdvertisementApplicationReview',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/not-reviewed',
          name: 'guestAdvertisementApplicationNotReview',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'guestAdvertisement',
              },
              {
                name: 'breadcrumbList.advertisementApplicationRecord',
                pathName: 'guestAdvertisementApplicationNotReview',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/decline',
          name: 'guestAdvertisementApplicationDecline',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'guestAdvertisement',
              },
              {
                name: 'breadcrumbList.advertisementApplicationRecord',
                pathName: 'guestAdvertisementApplicationDecline',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showBtn: true,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/plan',
          name: 'guestAdvertisementPlan',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'guestHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'guestAdvertisementPlan',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showBtn: true,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
      ],
    },
    {
      path: 'advertisement/apply/:id',
      name: 'guestAdevertisementApply',
      component: () => import('@/views/memberCenter/page/common/advertisement/apply/ApplyView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'guestHome',
          },
          {
            name: 'breadcrumbList.advertisement',
            pathName: 'guestAdevertisement',
          },
          {
            name: 'breadcrumbList.applyForAds',
            pathName: 'guestAdevertisementApply',
          },
        ],
        title: 'breadcrumbList.applyForAds',
        description: 'about.description1',
        image: metaBg,
        showBtn: false,
        requiredAuth: true,
      },
    },
  ],
};
export default guestRouter;
