import service from '@/plugins/axios';

// 切換身份
const root = '/b/b111';

// 切換身份
export default function changeRole(data) {
  return service({
    url: `${root}/change_role`,
    method: 'post',
    data,
  });
}
