<template>
  <DialogTemplate :show="show">
    <div class="memberCenter_identity">
      <div class="memberCenter_identity--header">
        <h4 class="memberCenter_identity--title">{{ $t('memberCenter.switchIdentity') }}</h4>
        <SvgIcon
            icon-class="close"
            class-name="memberCenter_identity--closeBtn"
            @click="closeDialog"
        />
      </div>
      <a
          class="memberCenter_identity--content"
          v-for="(item, index) in identityList"
          :key="index"
          :class="{ 'activate': item.pathName === identity }"
          @click="switchIdentity(item.pathName)"
      >
        <span class="memberCenter_identity--text">{{ item.name }}</span>
      </a>
    </div>
  </DialogTemplate>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogTemplate from '@/components/dialog/DialogTemplate.vue';
import changeRole from '@/plugins/api/b/b111';

export default {
  name: 'SwitchIdentity',
  components: {
    DialogTemplate,
  },
  props: {
    show: {
      type: Boolean,
    },
    go: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['close', 'redirect'],
  // inject: ['reload'],
  watch: {
    $route() {
      // this.reload();
    },
    lang(value, oldValue) {
      if (value !== oldValue) {
        this.identityList = [
          {
            name: this.$t('account_role.type.1'),
            pathName: 'guest',
          },
          {
            name: this.$t('account_role.type.2'),
            pathName: 'host',
          },
          {
            name: this.$t('account_role.type.3'),
            pathName: 'medium',
          },
          {
            name: this.$t('account_role.type.4'),
            pathName: 'company',
          },
        ];
      }
    },
  },
  computed: {
    ...mapGetters(['identity', 'lang']),
  },
  data() {
    return {
      identityList: [
        {
          name: this.$t('account_role.type.1'),
          pathName: 'guest',
        },
        {
          name: this.$t('account_role.type.2'),
          pathName: 'host',
        },
        {
          name: this.$t('account_role.type.3'),
          pathName: 'medium',
        },
        {
          name: this.$t('account_role.type.4'),
          pathName: 'company',
        },
      ],
    };
  },
  methods: {
    switchIdentity(name) {
      if (name !== this.identity) {
        let role = 0;
        switch (name) {
          case 'guest':
            role = 1;
            break;
          case 'host':
            role = 2;
            break;
          case 'medium':
            role = 3;
            break;
          case 'company':
            role = 4;
            break;
          default:
            return;
        }
        changeRole({ role }).then((res) => {
          const { success } = res;
          const { data } = res;
          if (success) {
            this.$store.commit('UPDATE_COMPANY_INFO', {});
            this.$store.commit('CHANGEiDENTITY', {
              token: data,
              identity: name,
            });
            if (this.$route.path.indexOf('guest') !== -1
            || this.$route.path.indexOf('host') !== -1
            || this.$route.path.indexOf('medium') !== -1
            || this.$route.path.indexOf('company') !== -1) {
              this.$router.replace({ name: `${name}Home` });
            }
            if (Object.keys(this.go).length > 0) {
              this.$emit('redirect', this.go);
            }
            this.closeDialog();
          }
        });
      }
    },
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>
