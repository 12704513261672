import metaBg from '@/assets/meta/meta_bg.png';

const forumRouter = {
  path: 'forum',
  component: () => import('@/views/forum/ForumLayout.vue'),
  children: [
    // home
    {
      path: '',
      component: () => import('@/views/forum/page/home/ForumHomeLayout.vue'),
      children: [
        {
          path: '',
          name: 'forumAllArticlesOnHome',
          component: () => import('@/views/forum/page/home/ArticleList.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.forum',
                pathName: 'forumAllArticlesOnHome',
              },
            ],
            title: 'forumPage.forumHome',
            description: 'about.description1',
            image: metaBg,
            keepAlive: true,
          },
        },
        {
          path: 'general',
          name: 'forumGeneralArticlesOnHome',
          component: () => import('@/views/forum/page/home/ArticleList.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.forum',
                pathName: 'forumGeneralArticlesOnHome',
              },
            ],
            title: 'forumPage.forumHome',
            description: 'about.description1',
            image: metaBg,
            keepAlive: true,
          },
        },
        // featured
        {
          path: 'featured',
          name: 'forumFeaturedArticlesOnHome',
          component: () => import('@/views/forum/page/home/ArticleList.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.forum',
                pathName: 'forumFeaturedArticlesOnHome',
              },
            ],
            title: 'forumPage.forumHome',
            description: 'about.description1',
            image: metaBg,
            keepAlive: true,
          },
        },
        // popular
        {
          path: 'popular',
          name: 'forumPopularArticlesOnHome',
          component: () => import('@/views/forum/page/home/ArticleList.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.forum',
                pathName: 'forumPopularArticlesOnHome',
              },
            ],
            title: 'forumPage.forumHome',
            description: 'about.description1',
            image: metaBg,
            keepAlive: true,
          },
        },
      ],
    },
    // my articles
    {
      path: 'my-articles',
      component: () => import('@/views/forum/page/home/ForumHomeLayout.vue'),
      children: [
        {
          path: '',
          name: 'forumAllArticlesOnMyArticles',
          component: () => import('@/views/forum/page/home/ArticleList.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.forum',
                pathName: 'forumAllArticlesOnHome',
              },
              {
                name: 'breadcrumbList.myArticles',
                pathName: 'forumAllArticlesOnMyArticles',
              },
            ],
            title: 'forumPage.myArticles',
            description: 'about.description1',
            image: metaBg,
            keepAlive: true,
          },
        },
        // general
        {
          path: 'general',
          name: 'forumGeneralArticlesOnMyArticles',
          component: () => import('@/views/forum/page/home/ArticleList.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.forum',
                pathName: 'forumAllArticlesOnHome',
              },
              {
                name: 'breadcrumbList.myArticles',
                pathName: 'forumGeneralArticlesOnMyArticles',
              },
            ],
            title: 'breadcrumbList.myArticles',
            description: 'about.description1',
            image: metaBg,
          },
        },
        // featured
        {
          path: 'featured',
          name: 'forumFeaturedArticlesOnMyArticles',
          component: () => import('@/views/forum/page/home/ArticleList.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.forum',
                pathName: 'forumAllArticlesOnHome',
              },
              {
                name: 'breadcrumbList.myArticles',
                pathName: 'forumFeaturedArticlesOnMyArticles',
              },
            ],
            title: 'breadcrumbList.myArticles',
            description: 'about.description1',
            image: metaBg,
          },
        },
        // popular
        {
          path: 'popular',
          name: 'forumPopularArticlesOnMyArticles',
          component: () => import('@/views/forum/page/home/ArticleList.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.forum',
                pathName: 'forumAllArticlesOnHome',
              },
              {
                name: 'breadcrumbList.myArticles',
                pathName: 'forumPopularArticlesOnMyArticles',
              },
            ],
            title: 'breadcrumbList.myArticles',
            description: 'about.description1',
            image: metaBg,
          },
        },
      ],
    },
    {
      path: ':id',
      name: 'forumDetail',
      component: () => import('@/views/forum/page/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.forum',
            pathName: 'forumAllArticlesOnHome',
          },
          {
            name: ':id',
            pathName: 'forumDetail',
          },
        ],
        title: 'breadcrumbList.forum',
        description: 'about.description1',
        image: metaBg,
      },
    },
    {
      path: 'publish',
      name: 'forumPublish',
      component: () => import('@/views/forum/page/publish/PublishView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.forum',
            pathName: 'forumAllArticlesOnHome',
          },
          {
            name: 'breadcrumbList.publishArticle',
            pathName: 'forumPublish',
          },
        ],
        title: 'forumPage.publishArticle',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: ':id/edit',
      name: 'forumEdit',
      component: () => import('@/views/forum/page/publish/PublishView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.forum',
            pathName: 'forumAllArticlesOnHome',
          },
          {
            name: ':id',
            pathName: 'forumDetail',
          },
          {
            name: 'breadcrumbList.editArticle',
            pathName: 'forumEdit',
          },
        ],
        title: 'forumPage.editArticle',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
  ],
};
export default forumRouter;
