import metaBg from '@/assets/meta/meta_bg.png';

const registerRouter = {
  path: 'register',
  name: 'register',
  component: () => import('@/views/register/RegisterView.vue'),
  children: [
    {
      path: 'step1/:id',
      name: 'registerStep1',
      component: () => import('@/views/register/components/Step1.vue'),
      meta: {
        title: 'navbar.register',
        description: 'about.description1',
        image: metaBg,
      },
    },
    {
      path: 'step2/:id',
      name: 'registerStep2',
      component: () => import('@/views/register/components/Step2.vue'),
      meta: {
        title: 'navbar.register',
        description: 'about.description1',
        image: metaBg,
      },
    },
    {
      path: 'step3',
      name: 'registerStep3',
      component: () => import('@/views/register/components/Step3.vue'),
      meta: {
        title: 'navbar.register',
        description: 'about.description1',
        image: metaBg,
      },
    },
  ],
};
export default registerRouter;
