import service from '@/plugins/axios';

// 新建案
const root = '/a/a106';

// 條件查詢新建案房屋
export function preSaleConditionHouse(params) {
  return service({
    url: `${root}/condition_house`,
    method: 'get',
    params,
  });
}

// 類似新建案房屋
export function preSaleSimilarHouse(params) {
  return service({
    url: `${root}/similar_house`,
    method: 'get',
    params,
  });
}

// 適合新建案房屋
export function preSaleSuitableHouse(params) {
  return service({
    url: `${root}/suitable_house`,
    method: 'get',
    params,
  });
}

// 新建案房屋詳細資訊
export function preSaleHouseDetail(params) {
  return service({
    url: `${root}/house_detail`,
    method: 'get',
    params,
  });
}
export function preSaleTodayCount(params) {
  return service({
    url: `${root}/today_count`,
    method: 'get',
    params,
  });
}
