import metaBg from '@/assets/meta/meta_bg.png';

const identityRouter = {
  path: 'identity',
  name: 'identity',
  component: () => import('@/views/identity/IdentityView.vue'),
  meta: {
    title: 'register.registrationStatus',
    description: 'about.description1',
    image: metaBg,
  },
};
export default identityRouter;
