<template>
  <div ref="draggable" class="draggable">
    <div class="draggable_section">
        <div ref="outer" class="draggable_outer">
          <div class="draggable_fill" :style="{ 'width': `${thunk}%`, 'left': `${leftRange}%` }"></div>
          <!-- <div class="draggable_left" :style="{ 'width': `${currentMinPosition}%` }"></div>
          <div class="draggable_right" :style="{ 'width': `${currentMaxPosition}%` }"></div> -->
          <div ref="min" class="draggable_circle" :style="{ 'left': `${leftRange}%` }"></div>
          <div ref="max" class="draggable_circle" :style="{ 'left': `${rightRange}%` }"></div>
        </div>
        <div class="draggable_outer--right"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DefaultDraggable',
  props: {
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
  },
  emits: ['minNumber', 'maxNumber'],
  data() {
    return {
      thunk: 100,
      leftRange: 0,
      rightRange: 100,
    };
  },
  mounted() {
    let distance = 0;
    this.$refs.min.onmousedown = (e) => {
      const curX = this.$refs.min.offsetLeft;
      distance = e.pageX - curX;
      document.onmousemove = (target) => {
        if (this.leftRange <= this.rightRange - 3) {
          if (distance <= target.clientX) {
            const width = target.clientX - distance;
            const range = width / this.$refs.draggable.offsetWidth;
            const m = Number((Math.abs(range) * 100).toPrecision(15));
            const mm = (Math.round(m) / 100) * Math.sign(range);
            this.leftRange = mm * 100;
            this.thunk = 100 - this.leftRange - (100 - this.rightRange);
            this.$emit('minNumber', this.leftRange);
          } else {
            this.leftRange = 0;
            this.$emit('minNumber', this.leftRange);
          }
        } else {
          this.leftRange = this.rightRange - 3;
          this.$emit('minNumber', this.leftRange);
        }
        document.onmouseup = () => {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };
    };
    this.$refs.max.onmousedown = (e) => {
      const curX = this.$refs.max.offsetLeft;
      distance = e.pageX - curX;
      document.onmousemove = (target) => {
        if (this.leftRange < this.rightRange - 3) {
          if (target.clientX - distance <= this.$refs.draggable.offsetWidth) {
            const width = target.clientX - distance;
            const range = width / this.$refs.draggable.offsetWidth;
            const m = Number((Math.abs(range) * 100).toPrecision(15));
            const mm = (Math.round(m) / 100) * Math.sign(range);
            const percent = 100 - (mm * 100);
            this.rightRange = 100 - percent;
            this.thunk = 100 - percent - this.leftRange;
            this.$emit('maxNumber', this.rightRange);
          } else {
            this.rightRange = 100;
            this.$emit('maxNumber', this.rightRange);
          }
        } else {
          this.rightRange = this.leftRange + 3;
          this.$emit('maxNumber', this.rightRange);
        }
        document.onmouseup = () => {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };
    };
  },
  methods: {
    onMouseStart(target) {
      console.log(target);
    },
    onMouseEnd(target) {
      console.log(target);
    },
  },
};
</script>
