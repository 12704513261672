<template>
    <DialogTemplate :show="open" >
        <div class="home_section--container">
            <div class="home_dialog">
                <div class="home_dialog--header">
                    <div class="flex align_center">
                      <div class="keyword_input">
                        <input
                          type="text"
                          aria-label="keyword"
                          :placeholder="$t('home.keywordPlaceholder')"
                          :value="cond.key_word"
                          @input="enterKeywords($event.target.value)"
                        />
                      </div>
                      <button type="button" class="avatar_button" @click="closeFilter">
                        <SvgIcon icon-class="close" class-name="icon" />
                      </button>
                    </div>
                </div>
                <div class="home_dialog--content">
                    <!-- 篩選條件 -->
                    <div class="home_dialog--filterView">
                      <h4 class="home_dialog--subtitle">{{ $t('home.filter') }}</h4>
                      <div class="home_dialog--row">
                        <div class="col-3 home_filter--spacing col-mobile-12">
                            <DefaultSelect
                            :value="cond.city"
                            :placeholder="$t('search.city')"
                            :list="cityList"
                            @choose="chooseCity"
                            id="filter-select1"
                            />
                        </div>
                        <div class="col-3 home_filter--spacing col-mobile-12">
                            <DefaultSelect
                              v-if="type === 1 || type === 2"
                              :value="cond.house_categories"
                              :placeholder="$t('home.houseType')"
                              :list="type === 1 ? rentCategoryList : saleCategoryList"
                              multiple
                              @choose="chooseCategory"
                              id="filter-select2"
                            />
                          <DefaultSelect
                              v-else-if="type === 3"
                              :value="cond.case_type"
                              :placeholder="$t('search.caseType')"
                              :list="casesList"
                              multiple
                              @choose="chooseCase"
                              id="filter-select2"
                          />
                          <DefaultSelect
                              v-else-if="type === 4"
                              :value="cond.factory_types"
                              :placeholder="$t('publish.form.factoryType')"
                              :list="factoryCategoryList"
                              multiple
                              @choose="chooseFactoryType"
                              id="filter-select2"
                          />
                          <DefaultSelect
                              v-else-if="type === 5"
                              :value="cond.land_types"
                              :placeholder="$t('publish.form.landType')"
                              :list="landCategoryList"
                              multiple
                              @choose="chooseLandType"
                              id="filter-select2"
                          />
                          <DefaultSelect
                              v-else-if="type === 6"
                              :value="cond.office_category"
                              :placeholder="$t('publish.form.officeCategory')"
                              :list="officeCategoryList"
                              multiple
                              @choose="chooseOfficeCategory"
                              id="filter-select2"
                          />
                          <DefaultSelect
                              v-else-if="type === 7"
                              :value="cond.storefront_category"
                              :placeholder="$t('publish.form.shopCategory')"
                              :list="shopCategoryList"
                              multiple
                              @choose="chooseShopCategory"
                              id="filter-select2"
                          />
                        </div>
                        <div class="col-3 home_filter--spacing col-mobile-12">
                            <DefaultSelect
                                v-if="type === 1 || type === 2 || type === 3"
                                :value="cond.house_types"
                                :placeholder="$t('publish.form.housingType')"
                                :list="typeList"
                                multiple
                                @choose="chooseType"
                                id="filter-select4"
                            />
                          <DefaultSelect
                              v-else-if="type === 4 || type === 5"
                              :value="cond.type"
                              :placeholder="$t('memberCenter.publishType')"
                              :list="publishTypeList"
                              @choose="choosePublishType"
                              id="filter-select4"
                          />
                          <DefaultSelect
                              v-else-if="type === 6"
                              :value="cond.office_type"
                              :placeholder="$t('publish.form.officeType')"
                              :list="officeTypeList"
                              multiple
                              @choose="chooseOfficeType"
                              id="filter-select4"
                          />
                          <DefaultSelect
                              v-else-if="type === 7"
                              :value="cond.storefront_type"
                              :placeholder="$t('publish.form.shopType')"
                              :list="officeTypeList"
                              multiple
                              @choose="chooseShopType"
                              id="filter-select4"
                          />
                        </div>
                        <div class="col-3 home_filter--spacin col-mobile-12">
                            <DefaultSelect
                                v-if="type === 1 || type === 2 || type === 3"
                                :value="cond.room_num"
                                :placeholder="$t('home.housingLayout')"
                                :list="roomList"
                                multiple
                                @choose="chooseRoom"
                                id="filter-select3"
                            />
                          <DefaultSelect
                              v-else-if="type === 6 || type === 7"
                              :value="cond.type"
                              :placeholder="$t('memberCenter.publishType')"
                              :list="publishTypeList"
                              @choose="choosePublishType"
                              id="banner-select4"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- id -->
                    <div class="home_dialog--filterView">
                      <h4 class="home_dialog--subtitle">{{ $t('register.invoiceType.tax') }}</h4>
                      <div class="home_dialog--row">
                        <div class="home_dialog--id">
                          <DefaultInput
                              type="number"
                              :value="id"
                              :placeholder="$t('placeholder.searchId')"
                              @change-value="enterId"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="type !== 3" class="home_dialog--filterView">
                      <h4 class="home_dialog--subtitle">
                        {{ calcPriceText }}
                      </h4>
                      <div class="home_dialog--draggable">
                          <DefaultDraggable
                            ref="price-range"
                            :min="minPrice"
                            :max="maxPrice"
                            @min-number="dragMinPrice"
                            @max-number="dragMaxPrice"
                          />
                      </div>
                      <div class="home_dialog--row justify_between">
                        <div class="home_dialog--priceInput">
                            <DefaultInput
                              type="number"
                              :value="cond.min_price"
                              :placeholder="$t('home.minimumPrice')"
                              @change-value="enterMinPrice"
                            />
                        </div>
                        <div class="home_dialog--priceInput">
                            <DefaultInput
                              type="number"
                              :value="cond.max_price"
                              :placeholder="$t('home.maximumPrice')"
                              @change-value="enterMaxPrice"
                            />
                        </div>
                      </div>
                    </div>
                    <div v-else-if="type === 3" class="home_dialog--filterView">
                      <h4 class="home_dialog--subtitle">{{ $t('publish.form.price') }}</h4>
                      <div class="home_dialog--draggable">
                        <DefaultDraggable
                            ref="price-range"
                            :min="minPrice"
                            :max="maxPrice"
                            @min-number="dragMinPrice"
                            @max-number="dragMaxPrice"
                        />
                      </div>
                      <div class="home_dialog--row justify_between">
                        <div class="home_dialog--priceInput">
                          <DefaultInput
                              type="number"
                              :value="cond.square_price_min"
                              :placeholder="$t('home.minimumPrice')"
                              @change-value="enterMinPrice"
                          />
                        </div>
                        <div class="home_dialog--priceInput">
                          <DefaultInput
                              type="number"
                              :value="cond.square_price_max"
                              :placeholder="$t('home.maximumPrice')"
                              @change-value="enterMaxPrice"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- 面積 -->
                    <div class="home_dialog--filterView">
                      <h4 class="home_dialog--subtitle">{{ $t('home.proportion') }}</h4>
                      <div class="home_dialog--draggable">
                          <DefaultDraggable
                            ref="square-range"
                            :min="30"
                            :max="500"
                            @min-number="dragMinSquare"
                            @max-number="dragMaxSquare"
                          />
                      </div>
                      <div class="home_dialog--row justify_between">
                        <div class="home_dialog--priceInput">
                            <DefaultInput
                              type="number"
                              :value="cond.square_min"
                              :placeholder="$t('home.minimumArae')"
                              @change-value="(value) => cond.square_min = parseInt(value)"
                            />
                        </div>
                        <div class="home_dialog--priceInput">
                            <DefaultInput
                              type="number"
                              :value="cond.square_max"
                              :placeholder="$t('home.maximumArae')"
                              @change-value="(value) => cond.square_max = parseInt(value)"
                            />
                        </div>
                      </div>
                    </div>
                    <!-- 特殊需求 -->
                    <div v-if="type === 1 || type === 2" class="home_dialog--filterView">
                      <h4 class="home_dialog--subtitle">{{ $t('home.specialRequirment') }}</h4>
                      <div class="home_dialog--row">
                        <div
                          class="home_dialog--checkbox"
                          v-for="option in type === 1 ? rentSpecialList : saleSpecialList"
                          :key="option.value"
                        >
                            <DefaultCheckBox
                              :option="option"
                              @change="chooseSpecial"
                            />
                        </div>
                      </div>
                    </div>
                    <!-- 身份 -->
                    <div v-if="type === 1" class="home_dialog--filterView">
                      <h4 class="home_dialog--subtitle">{{ $t('home.identity') }}</h4>
                      <div class="home_dialog--row">
                        <div
                          class="home_dialog--checkbox"
                          v-for="option in identityList"
                          :key="option.value"
                        >
                            <DefaultCheckBox
                              :option="option"
                              @change="chooseidentity"
                            />
                        </div>
                      </div>
                    </div>
                </div>
                <div class="home_dialog--bottom">
                    <div class="home_dialog--bottomRow">
                        <div class="home_dialog--btn">
                            <button type="button" class="fill_button" @click = onSearch>
                                {{ $t('home.search') }}
                            </button>
                        </div>
                        <div class="home_dialog--btn">
                            <button type="button" class="clear_button" @click="clearFilter">
                                {{ $t('home.clearFilter') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </DialogTemplate>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogTemplate from '@/components/dialog/DialogTemplate.vue';
import DefaultSelect from '@/components/select/DefaultSelect.vue';
import DefaultDraggable from '@/components/draggable/DefaultDraggable.vue';
import DefaultInput from '@/components/input/DefaultInput.vue';
import DefaultCheckBox from '@/components/checkbox/DefaultCheckBox.vue';

export default {
  name: 'FilterDialog',
  components: {
    DialogTemplate,
    DefaultSelect,
    DefaultDraggable,
    DefaultInput,
    DefaultCheckBox,
  },
  props: {
    open: {
      type: Boolean,
    },
    type: {
      type: Number,
    },
  },
  emits: ['close'],
  data() {
    return {
      rentCategoryList: [
        {
          value: 2,
          name: this.$t('house_info.house_category.2'),
        },
        {
          value: 3,
          name: this.$t('house_info.house_category.3'),
        },
        {
          value: 4,
          name: this.$t('house_info.house_category.4'),
        },
      ],
      saleCategoryList: [
        {
          value: 1,
          name: this.$t('house_info.house_category.1'),
        },
        {
          value: 5,
          name: this.$t('house_info.house_category.5'),
        },
        {
          value: 6,
          name: this.$t('house_info.house_category.6'),
        },
        {
          value: 7,
          name: this.$t('house_info.house_category.7'),
        },
      ],
      casesList: [
        {
          value: '1',
          name: this.$t('new_house.case_type.1'),
        },
        {
          value: '2',
          name: this.$t('new_house.case_type.2'),
        },
        {
          value: '3',
          name: this.$t('new_house.case_type.3'),
        },
      ],
      landCategoryList: [
        {
          value: 1,
          name: this.$t('land.type.1'),
        },
        {
          value: 2,
          name: this.$t('land.type.2'),
        },
        {
          value: 3,
          name: this.$t('land.type.3'),
        },
        {
          value: 4,
          name: this.$t('land.type.4'),
        },
        {
          value: 5,
          name: this.$t('land.type.5'),
        },
        {
          value: 6,
          name: this.$t('land.type.6'),
        },
      ],
      factoryCategoryList: [
        {
          value: 1,
          name: this.$t('factory.type.1'),
        },
        {
          value: 2,
          name: this.$t('factory.type.2'),
        },
        {
          value: 3,
          name: this.$t('factory.type.3'),
        },
        {
          value: 4,
          name: this.$t('factory.type.4'),
        },
        {
          value: 5,
          name: this.$t('factory.type.5'),
        },
      ],
      officeCategoryList: [
        {
          value: 1,
          name: this.$t('office.category.1'),
        },
        {
          value: 2,
          name: this.$t('office.category.2'),
        },
      ],
      shopCategoryList: [
        {
          value: 1,
          name: this.$t('shop.category.1'),
        },
        {
          value: 2,
          name: this.$t('shop.category.2'),
        },
        {
          value: 3,
          name: this.$t('shop.category.3'),
        },
        {
          value: 4,
          name: this.$t('shop.category.4'),
        },
        {
          value: 5,
          name: this.$t('shop.category.5'),
        },
        {
          value: 6,
          name: this.$t('shop.category.6'),
        },
        {
          value: 7,
          name: this.$t('shop.category.7'),
        },
        {
          value: 8,
          name: this.$t('shop.category.8'),
        },
      ],
      officeTypeList: [
        {
          value: 1,
          name: this.$t('office.type.1'),
        },
        {
          value: 2,
          name: this.$t('office.type.2'),
        },
        {
          value: 3,
          name: this.$t('office.type.3'),
        },
        {
          value: 4,
          name: this.$t('office.type.4'),
        },
        {
          value: 5,
          name: this.$t('office.type.5'),
        },
      ],
      typeList: [
        {
          value: 1,
          name: this.$t('house_info.house_type.1'),
        },
        {
          value: 2,
          name: this.$t('house_info.house_type.2'),
        },
        {
          value: 3,
          name: this.$t('house_info.house_type.3'),
        },
        {
          value: 4,
          name: this.$t('house_info.house_type.4'),
        },
      ],
      roomList: [
        {
          value: '1',
          name: `1${this.$t('search.room')}`,
        },
        {
          value: '2',
          name: `2${this.$t('search.room')}`,
        },
        {
          value: '3',
          name: `3${this.$t('search.room')}`,
        },
        {
          value: '4',
          name: `4${this.$t('search.roomUp')}`,
        },
      ],
      rentSpecialList: [
        {
          value: '1',
          name: this.$t('search.pet'),
          choose: false,
        },
        {
          value: '2',
          name: this.$t('search.furnace'),
          choose: false,
        },
        {
          value: '3',
          name: this.$t('search.parking'),
          choose: false,
        },
        {
          value: '4',
          name: this.$t('search.elevator'),
          choose: false,
        },
        {
          value: '5',
          name: this.$t('search.balcony'),
          choose: false,
        },
        // {
        //   value: '6',
        //   name: this.$t('search.shortTerm'),
        //   choose: false,
        // },
        {
          value: '6',
          name: this.$t('search.hostPublished'),
          choose: false,
        },
      ],
      saleSpecialList: [
        {
          value: '1',
          name: this.$t('search.parking'),
          choose: false,
        },
        {
          value: '2',
          name: this.$t('search.elevator'),
          choose: false,
        },
        {
          value: '3',
          name: this.$t('search.balcony'),
          choose: false,
        },
        {
          value: '4',
          name: this.$t('search.layout'),
          choose: false,
        },
      ],
      identityList: [
        {
          value: '1',
          name: this.$t('house_identity_rule.type.1'),
        },
        {
          value: '2',
          name: this.$t('house_identity_rule.type.2'),
        },
        {
          value: '3',
          name: this.$t('house_identity_rule.type.3'),
        },
        {
          value: '4',
          name: this.$t('house_identity_rule.type.4'),
        },
        {
          value: '5',
          name: this.$t('house_identity_rule.type.5'),
        },
      ],
      genderList: [
        {
          value: '1',
          name: this.$t('search.maleOnly'),
          choose: false,
        },
        {
          value: '2',
          name: this.$t('search.femaleOnly'),
          choose: false,
        },
      ],
      publishTypeList: [
        {
          value: 3,
          name: this.$t('breadcrumbList.rent'),
        },
        {
          value: 4,
          name: this.$t('breadcrumbList.sale'),
        },
      ],
      id: null,
      minPrice: 0,
      maxPrice: 100000000000,
      minSquare: 30,
      maxSquare: 500,
      cond: {
        key_word: '',
        city: '',
        house_categories: [],
        office_category: [],
        storefront_category: [],
        type: null,
        house_types: [],
        factory_types: [],
        land_types: [],
        office_type: [],
        storefront_type: [],
        room_num: [],
        case_type: [],
        min_price: null,
        max_price: null,
        square_min: null,
        square_max: null,
        square_min_price: null,
        square_max_price: null,
        is_pet: null,
        is_fire: null,
        include_parking: null,
        is_elevator: null,
        include_balcony: null,
        is_owner: null,
        identity_rule: [],
      },
    };
  },
  watch: {
    type: {
      handler(value) {
        switch (value) {
          case 3:
            this.maxPrice = 7000000000;
            break;
          case 4:
            this.publishTypeList = [
              {
                value: 3,
                name: this.$t('breadcrumbList.rent'),
              },
              {
                value: 4,
                name: this.$t('breadcrumbList.sale'),
              },
            ];
            break;
          case 5:
            this.publishTypeList = [
              {
                value: 5,
                name: this.$t('breadcrumbList.rent'),
              },
              {
                value: 6,
                name: this.$t('breadcrumbList.sale'),
              },
            ];
            break;
          case 6:
            this.publishTypeList = [
              {
                value: 7,
                name: this.$t('breadcrumbList.rent'),
              },
              {
                value: 8,
                name: this.$t('breadcrumbList.sale'),
              },
            ];
            break;
          case 7:
            this.publishTypeList = [
              {
                value: 9,
                name: this.$t('breadcrumbList.rent'),
              },
              {
                value: 10,
                name: this.$t('breadcrumbList.sale'),
              },
            ];
            break;
          default:
            break;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['cityList']),
    calcPriceText() {
      switch (this.type) {
        case 1:
          return this.$t('home.rent');
        case 2:
          return this.$t('home.sale');
        default:
          return this.$t('memberCenter.price');
      }
    },
  },
  methods: {
    enterKeywords(value) {
      this.cond.key_word = value;
    },
    enterId(id) {
      this.id = Number(id);
      switch (this.type) {
        case 1:
          this.cond.house_id = Number(id);
          break;
        case 2:
          this.cond.house_id = Number(id);
          break;
        case 3:
          this.cond.new_house_id = Number(id);
          break;
        default:
          this.cond.house_id = Number(id);
          break;
      }
    },
    chooseCity(city) {
      this.cond.city = city.value;
    },
    chooseCategory(category) {
      const index = this.cond.house_categories.indexOf(category.value);
      if (index === -1) {
        this.cond.house_categories.push(category.value);
      } else {
        this.cond.house_categories = this.cond.house_categories.filter((ele) => ele !== category.value);
      }
      this.cond.house_categories.sort((a, b) => a - b);
    },
    chooseOfficeCategory(value) {
      const index = this.cond.office_category.indexOf(value.value);
      if (index === -1) {
        this.cond.office_category.push(value.value);
      } else {
        this.cond.office_category = this.cond.office_category.filter((ele) => ele !== value.value);
      }
      this.cond.office_category.sort((a, b) => a - b);
    },
    chooseShopCategory(value) {
      const index = this.cond.storefront_category.indexOf(value.value);
      if (index === -1) {
        this.cond.storefront_category.push(value.value);
      } else {
        this.cond.storefront_category = this.cond.storefront_category.filter((ele) => ele !== value.value);
      }
      this.cond.storefront_category.sort((a, b) => a - b);
    },
    chooseCase(value) {
      const index = this.cond.case_type.indexOf(value.value);
      if (index === -1) {
        this.cond.case_type.push(value.value);
      } else {
        this.cond.case_type = this.cond.case_type.filter((ele) => ele !== value.value);
      }
      this.cond.case_type.sort((a, b) => a - b);
    },
    chooseType(type) {
      const index = this.cond.house_types.indexOf(type.value);
      if (index === -1) {
        this.cond.house_types.push(type.value);
      } else {
        this.cond.house_types = this.cond.house_types.filter((ele) => ele !== type.value);
      }
      this.cond.house_types.sort((a, b) => a - b);
    },
    chooseFactoryType(value) {
      const index = this.cond.factory_types.indexOf(value.value);
      if (index === -1) {
        this.cond.factory_types.push(value.value);
      } else {
        this.cond.factory_types = this.cond.factory_types.filter((ele) => ele !== value.value);
      }
      this.cond.factory_types.sort((a, b) => a - b);
    },
    chooseLandType(value) {
      const index = this.cond.land_types.indexOf(value.value);
      if (index === -1) {
        this.cond.land_types.push(value.value);
      } else {
        this.cond.land_types = this.cond.land_types.filter((ele) => ele !== value.value);
      }
      this.cond.land_types.sort((a, b) => a - b);
    },
    chooseOfficeType(value) {
      const index = this.cond.office_type.indexOf(value.value);
      if (index === -1) {
        this.cond.office_type.push(value.value);
      } else {
        this.cond.office_type = this.cond.office_type.filter((ele) => ele !== value.value);
      }
      this.cond.office_type.sort((a, b) => a - b);
    },
    chooseShopType(value) {
      const index = this.cond.storefront_type.indexOf(value.value);
      if (index === -1) {
        this.cond.storefront_type.push(value.value);
      } else {
        this.cond.storefront_type = this.cond.storefront_type.filter((ele) => ele !== value.value);
      }
      this.cond.storefront_type.sort((a, b) => a - b);
    },
    choosePublishType(value) {
      this.cond.type = value.value;
    },
    chooseRoom(room) {
      if (this.type === 1 || this.type === 2 || this.type === 3) {
        const index = this.cond.room_num.indexOf(room.value);
        if (index === -1) {
          this.cond.room_num.push(room.value);
        } else {
          this.cond.room_num = this.cond.room_num.filter((ele) => ele !== room.value);
        }
        this.cond.room_num.sort((a, b) => a - b);
      }
    },
    closeFilter() {
      this.$emit('close');
    },
    chooseSpecial(value) {
      if (this.type === 1) {
        switch (value.value) {
          case '1':
            this.searchPet(value);
            break;
          case '2':
            this.searchFire(value);
            break;
          case '3':
            this.searchParking(value);
            break;
          case '4':
            this.searchElevator(value);
            break;
          case '5':
            this.searchBalcony(value);
            break;
          case '6':
            this.searchOwner(value);
            break;
          default:
            break;
        }
      } else {
        switch (value.value) {
          case '1':
            this.searchParking(value);
            break;
          case '2':
            this.searchElevator(value);
            break;
          case '3':
            this.searchBalcony(value);
            break;
          case '4':
            this.searchLayout(value);
            break;
          default:
            break;
        }
      }
    },
    dragMinPrice(value) {
      if (this.type !== 3) {
        this.cond.min_price = Math.floor(this.maxPrice * value) / 100;
      } else {
        this.cond.square_price_min = Math.floor(this.maxPrice * value) / 100;
      }
    },
    dragMaxPrice(value) {
      if (this.type !== 3) {
        this.cond.max_price = (this.maxPrice * value) / 100;
      } else {
        this.cond.square_price_max = (this.maxPrice * value) / 100;
      }
    },
    enterMinPrice(value) {
      if (this.type !== 3) {
        this.cond.min_price = parseInt(value, 10);
      } else {
        this.cond.square_price_min = parseInt(value, 10);
      }
    },
    enterMaxPrice(value) {
      if (this.type !== 3) {
        this.cond.max_price = parseInt(value, 10);
      } else {
        this.cond.square_price_max = parseInt(value, 10);
      }
    },
    dragMinSquare(value) {
      this.cond.square_min = Math.floor(this.maxSquare * value) / 100;
    },
    dragMaxSquare(value) {
      this.cond.square_max = Math.floor(this.maxSquare * value) / 100;
    },
    searchPet(value) {
      this.cond.is_pet = value.choose ? 1 : null;
    },
    searchFire(value) {
      this.cond.is_fire = value.choose ? 1 : null;
    },
    searchParking(value) {
      this.cond.include_parking = value.choose ? 1 : null;
    },
    searchElevator(value) {
      this.cond.is_elevator = value.choose ? 1 : null;
    },
    searchBalcony(value) {
      this.cond.include_balcony = value.choose ? 1 : null;
    },
    searchOwner(value) {
      this.cond.is_owner = value.choose ? 1 : null;
    },
    searchLayout(value) {
      this.cond.include_layout = value.choose ? 1 : null;
    },
    chooseidentity(value) {
      let newArr = [];
      if (value.choose) {
        this.cond.identity_rule.push(value.value);
      } else {
        newArr = this.cond.identity_rule.filter((item) => value.value !== item);
        this.cond.identity_rule = newArr;
      }
      this.cond.identity_rule.sort((a, b) => a - b);
    },
    onSearch() {
      const cond = JSON.parse(JSON.stringify(this.cond));
      Object.keys(cond).forEach((key) => {
        if (cond[key]?.length === 0 || cond[key] === null) {
          delete cond[key];
        }
      });
      if (cond.case_type) {
        cond.case_type = cond.case_type.toString();
      }
      if (cond.house_categories) {
        cond.house_categories = cond.house_categories.toString();
      }
      if (cond.office_category) {
        cond.office_category = cond.office_category.toString();
      }
      if (cond.storefront_category) {
        cond.storefront_category = cond.storefront_category.toString();
      }
      if (cond.house_types) {
        cond.house_types = cond.house_types.toString();
      }
      if (cond.factory_types) {
        cond.factory_types = cond.factory_types.toString();
      }
      if (cond.land_types) {
        cond.land_types = cond.land_types.toString();
      }
      if (cond.office_type) {
        cond.office_type = cond.office_type.toString();
      }
      if (cond.storefront_type) {
        cond.storefront_type = cond.storefront_type.toString();
      }
      if (cond.room_num) {
        cond.room_num = cond.room_num.toString();
      }
      if (cond.identity_rule) {
        cond.identity_rule = cond.identity_rule.toString();
      }
      switch (this.type) {
        case 1:
          this.$router.push({
            name: 'rent',
            query: cond,
          });
          break;
        case 2:
          this.$router.push({
            name: 'sale',
            query: cond,
          });
          break;
        case 3:
          this.$router.push({
            name: 'preSale',
            query: cond,
          });
          break;
        case 4:
          if (!cond.type) cond.type = 4;
          this.$router.push({
            name: 'factory',
            query: cond,
          });
          break;
        case 5:
          if (!cond.type) cond.type = 6;
          this.$router.push({
            name: 'land',
            query: cond,
          });
          break;
        case 6:
          if (!cond.type) cond.type = 8;
          this.$router.push({
            name: 'office',
            query: cond,
          });
          break;
        case 7:
          if (!cond.type) cond.type = 10;
          this.$router.push({
            name: 'shop',
            query: cond,
          });
          break;
        default:
          break;
      }
      this.closeFilter();
    },
    clearFilter() {
      this.cond = {
        key_word: '',
        city: '',
        house_categories: [],
        house_types: [],
        case_type: [],
        room_num: [],
        room_min: null,
        room_max: null,
        min_price: null,
        max_price: null,
        square_min: null,
        square_max: null,
        office_category: [],
        storefront_category: [],
        type: null,
        factory_types: [],
        land_types: [],
        office_type: [],
        storefront_type: [],
      };
      this.minPrice = 0;
      this.maxPrice = 100000000000;
      this.minSquare = 30;
      this.maxSquare = 500;
      this.$refs['price-range'].rightRange = 100;
      this.$refs['price-range'].leftRange = 0;
      this.$refs['price-range'].thunk = 100;
      this.$refs['square-range'].rightRange = 100;
      this.$refs['square-range'].leftRange = 0;
      this.$refs['square-range'].thunk = 100;
    },
  },
};
</script>
