import metaBg from '@/assets/meta/meta_bg.png';

const forgetPwdRouter = {
  path: 'forgot-password',
  component: () => import('@/views/register/RegisterView.vue'),
  children: [
    {
      path: 'step1',
      name: 'forgotPwdStep1',
      component: () => import('@/views/forgetPassword/Step1.vue'),
      meta: {
        title: 'login.forget',
        description: 'about.description1',
        image: metaBg,
      },
    },
    {
      path: 'step2/:id',
      name: 'forgotPwdStep2',
      component: () => import('@/views/forgetPassword/Step2.vue'),
      meta: {
        title: 'login.forget',
        description: 'about.description1',
        image: metaBg,
      },
    },
    {
      path: 'step3/:id',
      name: 'forgotPwdStep3',
      component: () => import('@/views/forgetPassword/Step3.vue'),
      meta: {
        title: 'login.forget',
        description: 'about.description1',
        image: metaBg,
      },
    },
  ],
};
export default forgetPwdRouter;
