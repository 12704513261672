import service from '@/plugins/axios';

// 交易管理
const root = '/b/b109';

// 新增信用卡
export function cardCreate(data) {
  return service({
    url: `${root}/card_create`,
    method: 'post',
    data,
  });
}

// 刪除信用卡
export function cardDelete(params) {
  return service({
    url: `${root}/card_delete/${params}`,
    method: 'delete',
  });
}

// 取得信用卡列表
export function cardList(params) {
  return service({
    url: `${root}/card_list`,
    method: 'get',
    params,
  });
}

// 取得方案資訊
export function planInfo() {
  return service({
    url: `${root}/plan_info`,
    method: 'get',
  });
}

// 取得方案列表
export function planList(params) {
  return service({
    url: `${root}/plan_list`,
    method: 'get',
    params,
  });
}

// 取得方案詳細資訊
export function PlanDetail(params) {
  return service({
    url: `${root}/plan_detail`,
    method: 'get',
    params,
  });
}

// 取得點數資訊
export function pointInfo() {
  return service({
    url: `${root}/point_info`,
    method: 'get',
  });
}

// 取得點數交易列表
export function pointList(params) {
  return service({
    url: `${root}/point_list`,
    method: 'get',
    params,
  });
}

// 取得訂單列表
export function ordList(params) {
  return service({
    url: `${root}/ord_list`,
    method: 'get',
    params,
  });
}

// 匯款
export function ordRemitted(data) {
  return service({
    url: `${root}/ord_remitted`,
    method: 'put',
    data,
  });
}

// 集團方案資訊
export function groupInfo(params) {
  return service({
    url: `${root}/group_info`,
    method: 'get',
    params,
  });
}
// 集團方案列表
export function groupList(params) {
  return service({
    url: `${root}/group_list`,
    method: 'get',
    params,
  });
}
// 集團方案自動續訂
export function updateAutoSubscribe(data) {
  return service({
    url: `${root}/update_auto_subscribe`,
    method: 'put',
    data,
  });
}
