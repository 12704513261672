import guestRouter from './guest';
import hostRouter from './host';
import mediumRouter from './medium';
import companyRouter from './company';
import subRouter from './sub';

const memberRouter = {
  path: 'member-center',
  component: () => import('@/views/memberCenter/LayoutView.vue'),
  children: [
    guestRouter,
    hostRouter,
    mediumRouter,
    companyRouter,
    subRouter,
  ],
};
export default memberRouter;
