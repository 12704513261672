const landSaleDetailRouter = {
  path: 'land/sale/:id',
  name: 'landSaleDetail',
  component: () => import('@/views/detail/land/DetailView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.land',
        pathName: 'land',
      },
      {
        name: 'breadcrumbList.sale',
        pathName: 'land',
      },
      {
        name: ':id',
        pathName: 'landSaleDetail',
      },
    ],
    title: '',
    description: '',
    image: '',
  },
};
export default landSaleDetailRouter;
