import service from '@/plugins/axios';

// 權限
const root = '/auth';

// 登入
export function login(data) {
  return service({
    url: `${root}/login`,
    method: 'post',
    data,
  });
}

// 登出
export function logout() {
  return service({
    url: `${root}/logout`,
    method: 'post',
  });
}

// 註冊
export function register(data) {
  return service({
    url: `${root}/register`,
    method: 'post',
    data,
  });
}

// 驗證帳號驗證碼
export function verifyAccountCode(data) {
  return service({
    url: `${root}/verify_account_code`,
    method: 'post',
    data,
  });
}

// 忘記密碼
export function forgetPassword(data) {
  return service({
    url: `${root}/forget_password`,
    method: 'post',
    data,
  });
}

// 驗證忘記密碼驗證碼
export function verifyForgetPasswordCode(data) {
  return service({
    url: `${root}/verify_forget_password_code`,
    method: 'post',
    data,
  });
}

// 重設密碼
export function resetPassword(data) {
  return service({
    url: `${root}/reset_password`,
    method: 'post',
    data,
  });
}

// 發送驗證碼
export function sendVerifyCode(data) {
  return service({
    url: `${root}/send_verify_code`,
    method: 'post',
    data,
  });
}
