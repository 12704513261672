<template>
  <router-link
      :to="{ name: object.type === 5 ? 'landRentDetail' : 'landSaleDetail', params: { id: object.id } }"
      class="home_card" :class="{ 'skeleton_loading': isLoad }"
  >
    <div class="home_card--header">
      <img v-if="object.image?.length !== 0" v-show="!isLoad" :src="object.image" :alt="object.title">
      <div class="home_card--header--tagSection">
        <div v-if="object.bonus_best && !isLoad" class="home_card--header--tagChoice">
          {{ $t('home.choice') }}
        </div>
        <div v-if="object.bonus_urgent && !isLoad" class="home_card--header--tagUrgent">
          <SvgIcon icon-class="fire" class-name="home_card--header--icon" />
          {{ $t('home.urgent') }}
        </div>
      </div>
      <div class="home_card--header-views">
        <SvgIcon icon-class="eye" />
        <span>{{ !isLoad ? transferNum(object.count + 60) : '' }}</span>
      </div>
    </div>
    <div class="home_card--content">
      <div class="home_card--titleSection">
        <div v-if="object.is_new && !isLoad" class="home_card--new">
          <span>New</span>
        </div>
        <div v-if="object.bonus_top && !isLoad" class="home_card--top">
          <span>Top</span>
        </div>
        <div class="home_card--titleView" :style="{ 'width': titleWidth  }">
          <span class="home_card--title">
            {{ isLoad ? '' : $props.object.title  }}
          </span>
        </div>
      </div>
      <span class="home_card--text">
        {{ isLoad ? '' : $props.object.city }}
      </span>
      <span class="home_card--text">
        {{
          isLoad ? '' :
          $t(`land.type[${$props.object.land_type}]`)
        }} {{ isLoad ? '' : '|' }} <span class="notice">{{ isLoad ? '' : `${$props.object.square_area || $props.object.square} / ${$t("detail.squareMeter")}` }}</span>
      </span>
      <div class="home_card--footer">
        <div class="home_card--footerTextView">
          <span class="home_card--text price"> {{ isLoad ? '' : price() }} </span>
        </div>
        <button
            type="button"
            class="avatar_button--md"
            :class="$props.object.favorite ? 'like' : ''"
            @click.prevent.stop="favorite"
            v-if="favoriteShow()">
          <SvgIcon v-if="!isLoad" icon-class="favourite" class-name="icon"/>
        </button>
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';
import convertPrice from '@/lib/convert';
import convertUnit from '@/lib/convertUnit';

export default {
  name: 'LandCard',
  props: {
    object: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'land_1',
    },
    isLoad: {
      type: Boolean,
    },
  },
  emits: ['favorite'],
  computed: {
    ...mapGetters(['isLogin', 'role', 'identity']),
    titleWidth() {
      if (this.object.bonus_top) {
        return 'calc(100% - 29px)';
      }
      return '100%';
    },
  },
  methods: {
    price() {
      if (this.$props.object.type === 5) {
        if (this.$props.object.price) {
          return `₫ ${convertUnit(convertPrice(this.$props.object.price))} / ${this.$t('detail.month')}`;
        }
        return `₫ ${convertUnit(convertPrice(this.$props.object.total_price))} / ${this.$t('detail.month')}`;
      }
      return this.object.total_price ? `₫ ${convertUnit(convertPrice(this.$props.object.total_price))}` : `₫ ${convertUnit(convertPrice(this.$props.object.price))}`;
    },
    transferNum(num) {
      return convertPrice(num);
    },
    favoriteShow() {
      return this.identity !== 'sub';
    },
    favorite() {
      if (!this.isLogin) {
        this.$router.push({ name: 'login' });
      } else {
        this.$emit('favorite');
      }
    },
  },
};
</script>
