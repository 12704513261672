export default function registerSW() {
  // 註冊sw
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js', {
        updateViaCache: 'none',
      })
      .then((registration) => {
        registration.update();
      })
      .catch((err) => {
        console.log('Service Worker registration failed', err);
      });
  }
}

// function isRunningAsPWA() {
//   return window.matchMedia('(display-mode: standalone)').matches;
// }

// if (!isRunningAsPWA()) {
//   // 取消訂閱
//   navigator.serviceWorker.ready.then((registration) => {
//     registration.pushManager.getSubscription()
//       .then((subscription) => {
//         if (subscription) {
//           subscription.unsubscribe();
//         }
//       })
//       .then(() => {
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   });
// }
