<template>
  <ul v-if="!isLogin" class="dropdown" :class="{ 'visible': open }" style="width: 200px; right: 60px">
    <li
        v-for="(item, index) in notLoginList"
        :key="index"
        class="dropdown_option"
        @click="chooseOption(item)"
    >
      {{ item }}
    </li>
  </ul>
  <ul v-else class="dropdown" :class="{ 'visible': open }" style="width: 200px; right: 60px">
    <li class="dropdown_user">
      <div class="flex">
        <SvgIcon icon-class="user" class-name="dropdown_user--icon"/>
        <div class="flex_column">
          <span class="dropdown_user--identity">{{ $t(`account_role.type[${Number(role)}]`) }}</span>
          <span class="dropdown_user--text">{{ $t('navbar.hello') }} {{ name?.length > 0 ? name : $t('navbar.member') }}</span>
        </div>
      </div>
<!--      <div v-if="identity === 'company' && companyInfo.company_type === 'none'" class="dropdown_user&#45;&#45;upgrade">-->
<!--        <button class="w_100 fill_button" type="button" @click="redirectToUpgrade">-->
<!--          <SvgIcon icon-class="premium" />-->
<!--          {{ $t('memberCenter.upgradeAccount') }}-->
<!--        </button>-->
<!--      </div>-->
    </li>
    <li
        v-for="(item, index) in loginList"
        :key="index"
        class="dropdown_option"
        @click="chooseLoginOption(item)"
    >
      {{ item }}
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import { logout } from '@/plugins/api/auth/auth';
import SwitchIdentity from '@/views/memberCenter/page/common/home/SwitchIdentity.vue';

export default {
  name: 'UserDropdown',
  components: {
    SwitchIdentity,
  },
  props: {
    open: {
      type: Boolean,
    },
  },
  emits: ['close'],
  data() {
    return {
      notLoginList: [this.$t('navbar.register'), this.$t('navbar.login')],
      loginList: [this.$t('navbar.memberCenter'), this.$t('memberCenter.switchIdentity'), this.$t('navbar.signOut')],
      openSwitch: false,
    };
  },
  watch: {
    lang(value, oldValue) {
      if (value !== oldValue) {
        if (this.isLogin) {
          if (this.isSub) {
            this.loginList = [this.$t('navbar.memberCenter'), this.$t('navbar.signOut')];
          } else {
            this.loginList = [this.$t('navbar.memberCenter'), this.$t('memberCenter.switchIdentity'), this.$t('navbar.signOut')];
          }
        } else {
          this.notLoginList = [this.$t('navbar.register'), this.$t('navbar.login')];
        }
      }
    },
    isLogin(value, oldValue) {
      if (value !== oldValue) {
        if (this.isSub) {
          this.loginList = [this.$t('navbar.memberCenter'), this.$t('navbar.signOut')];
        } else {
          this.loginList = [this.$t('navbar.memberCenter'), this.$t('memberCenter.switchIdentity'), this.$t('navbar.signOut')];
        }
        this.notLoginList = [this.$t('navbar.register'), this.$t('navbar.login')];
      }
    },
  },
  computed: {
    ...mapGetters(['lang', 'isLogin', 'identity', 'name', 'role', 'isSub', 'companyInfo', 'switchIdentity']),
  },
  mounted() {
    if (this.isSub) {
      this.loginList = [this.$t('navbar.memberCenter'), this.$t('navbar.signOut')];
    } else {
      this.loginList = [this.$t('navbar.memberCenter'), this.$t('memberCenter.switchIdentity'), this.$t('navbar.signOut')];
    }
    document.addEventListener('click', this.clickOtherElement);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickOtherElement);
  },
  methods: {
    redirectToUpgrade() {
      this.$router.push({ name: 'upgradeAccount' });
    },
    chooseOption(value) {
      switch (value) {
        case this.$t('navbar.register'):
          this.$router.push({ name: 'identity' });
          break;
        case this.$t('navbar.login'):
          this.$router.push({ name: 'login' });
          break;
        default:
          break;
      }
      this.$emit('close');
    },
    chooseLoginOption(value) {
      switch (value) {
        case this.$t('navbar.memberCenter'):
          if (this.identity === undefined) {
            this.$router.push({ name: 'guestHome' });
          } else {
            this.$router.push({ name: `${this.identity}Home` });
          }
          break;
        case this.$t('memberCenter.switchIdentity'):
          this.$store.commit('UPDATE_SWITCH_IDENTITY', true);
          break;
        case this.$t('navbar.signOut'):
          logout().then((res) => {
            if (res.success) {
              this.$store.commit('UPDATE_COMPANY_INFO', {});
              this.$store.commit('UPDATE_SUB_INFO', {});
              this.$store.dispatch('logOut');
              this.$toast.success(this.$t('noticeText.logoutSuccess'));
              this.$emit('close');
              if (this.$route.meta.requiredAuth) {
                this.$router.push({ name: 'home' });
              }
            } else {
              this.$toast.error(this.$t('alertText.logoutFail'));
            }
          }).catch(() => {
            // this.$toast.error(this.$t('alertText.logoutFail'));
          });
          break;
        default:
          break;
      }
    },
    closeSwitchIdentity() {
      this.openSwitch = false;
    },
    clickOtherElement(e) {
      const userStatus = document.querySelector('#user');
      if (!userStatus.contains(e.target)) {
        this.$emit('close');
      }
    },
  },
};
</script>
