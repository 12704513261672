import metaBg from '@/assets/meta/meta_bg.png';

const planRouter = {
  path: 'plan',
  component: () => import('@/views/info/InfoView.vue'),
  children: [
    {
      path: '',
      name: 'plan',
      component: () => import('@/views/info/plan/PlanView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.ourPlan',
            pathName: 'plan',
          },
        ],
        title: 'breadcrumbList.ourPlan',
        description: 'plan.description1',
        image: metaBg,
        bannerTitle: 'info.outPlan',
        bannerEnTitle: 'info.outPlanEn',
      },
    },
  ],
};
export default planRouter;
