<template>
  <input
    :type="type ? type : 'text'"
    class="custom_input"
    :placeholder="placeholder"
    :id="id"
    aria-label="input"
    :class="{ 'disabled': disabled }"
    :disabled="disabled ? disabled : false"
    :readonly="readyonly ? readyonly : false"
    :value="value"
    @input="changeValue($event.target.value)"
  />
</template>

<script>
export default {
  name: 'DefaultInput',
  props: {
    placeholder: {
      type: String,
    },
    id: {
      type: String,
    },
    type: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    readyonly: {
      type: Boolean,
    },
    value: [String, Number],
  },
  emits: ['changeValue'],
  methods: {
    changeValue(value) {
      this.$emit('changeValue', value);
    },
  },
};
</script>
