import service from '@/plugins/axios';

// 權限 買家/房客(會員)
const root = '/b/b102';

// 房屋收藏
export function favorite(data) {
  return service({
    url: `${root}/favorite`,
    method: 'post',
    data,
  });
}

// 房屋取消收藏
export function unFavorite(id) {
  return service({
    url: `${root}/un_favorite/${id}`,
    method: 'delete',
  });
}

// 新建案收藏
export function favoriteNewHouse(data) {
  return service({
    url: `${root}/favorite_new_house`,
    method: 'post',
    data,
  });
}

// 新建案取消收藏
export function unFavoriteNewHouse(id) {
  return service({
    url: `${root}/un_favorite_new_house/${id}`,
    method: 'delete',
  });
}

// 評價業者
export function rating(data) {
  return service({
    url: `${root}/rating`,
    method: 'post',
    data,
  });
}

// 檢舉房屋
export function abuseHouse(data) {
  return service({
    url: `${root}/abuse_house`,
    method: 'post',
    data,
  });
}

// 房屋問答
export function qaHouse(data) {
  return service({
    url: `${root}/qa_house`,
    method: 'post',
    data,
  });
}

// 店鋪問答
export function qaStore(data) {
  return service({
    url: `${root}/qa_store`,
    method: 'post',
    data,
  });
}
