import service from '@/plugins/axios';

// 新聞
const root = '/a/a112';

export function filesList(params) {
  return service({
    url: `${root}/files_list`,
    method: 'get',
    params,
  });
}

export function newsList(params) {
  return service({
    url: `${root}/list`,
    method: 'get',
    params,
  });
}

export function rateList(params) {
  return service({
    url: `${root}/rate_list`,
    method: 'get',
    params,
  });
}
