import metaBg from '@/assets/meta/meta_bg.png';

const policyRouter = {
  path: 'policy',
  component: () => import('@/views/info/InfoView.vue'),
  children: [
    {
      path: '',
      name: 'policy',
      component: () => import('@/views/info/policy/PolicyView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.policy',
            pathName: 'policy',
          },
        ],
        title: 'breadcrumbList.policy',
        description: 'policy.subTitle1',
        image: metaBg,
        bannerTitle: 'info.policy',
        bannerEnTitle: 'info.policyEn',
      },
    },
  ],
};
export default policyRouter;
