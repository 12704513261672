<template>
  <div class="footer">
    <div class="footer_row">
      <div class="footer_left">
        <router-link :to="`/${lang}`">
          <img src="@/assets/footer/footer_logo.png" alt="">
        </router-link>
        <p class="footer_left--company">CÔNG TY TNHH CÔNG NGHỆ ĐỔI MỚI VIỆT NAM</p>
        <p class="footer_left--text">TM11, Florita, P.Tân Hưng, Quận 7, Tp.Hồ Chí Minh</p>
        <p class="footer_left--text">02862731118</p>
        <div class="footer_left--btn">
          <a href="https://zalo.me/899425309558164147" class="footer_left--contact">
            <div class="flex align_center">
              <SvgIcon icon-class="zalo_white" />
              {{ $t('about.contactUs') }}
            </div>
          </a>
        </div>
      </div>
      <div class="footer_right">
        <div class="footer_row">
          <div class="footer_right--dropdown" :class="{ 'choose': open1 }">
            <div
                class="footer_right--dropdown--header w_100 flex align_center"
                @click="() => open1 = !open1"
            >
              <div class="footer_right--dropdown--subTitle">
                <h4 class="footer_left--company">{{ $t('footer.aboutUs') }}</h4>
              </div>
              <SvgIcon icon-class="arrow_down" />
            </div>
            <ul class="footer_right--dropdown--list">
              <li>
                <router-link :to="`/${lang}/about`" class="footer_link">{{ $t('footer.whyChoose') }}</router-link>
              </li>
              <li>
                <router-link :to="`/${lang}/plan`" class="footer_link">{{ $t('footer.ourPlan') }}</router-link>
              </li>
              <li>
                <router-link :to="`/${lang}/disclaimer`" class="footer_link">{{ $t('footer.disclaimer') }}</router-link>
              </li>
              <li>
                <router-link :to="`/${lang}/policy`" class="footer_link">
                  {{ $t('footer.termsOfMembershipAndPrivacyStatement') }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="footer_right--dropdown" :class="{ 'choose': open2 }">
            <div
                class="footer_right--dropdown--header w_100 flex align_center"
                @click="() => open2 = !open2"
            >
              <div class="footer_right--dropdown--subTitle">
                <h4 class="footer_left--company">{{ $t('footer.resource') }}</h4>
              </div>
              <SvgIcon icon-class="arrow_down" />
            </div>
            <ul class="footer_right--dropdown--list">
              <li>
                <router-link :to="`/${lang}/mortgage-calculator`" class="footer_link">{{ $t('footer.mortgageCalculator') }}</router-link>
              </li>
              <li>
                <router-link :to="`/${lang}/files`" class="footer_link">{{ $t('files.download') }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FooterView',
  computed: {
    ...mapGetters(['lang']),
  },
  data() {
    return {
      open1: false,
      open2: false,
    };
  },
};
</script>
