import metaBg from '@/assets/meta/meta_bg.png';

const videoRouter = {
  path: 'video',
  component: () => import('@/views/video/VideoPage.vue'),
  children: [
    {
      path: '',
      name: 'video',
      component: () => import('@/views/video/VideoLayout.vue'),
      children: [
        {
          path: ':id',
          name: 'videoDetail',
          component: () => import('@/views/video/components/dialog/VideoDialog.vue'),
          meta: {
            title: 'video.title',
            enTitle: 'video.enTitle',
            description: 'about.description1',
            image: '',
          },
        },
      ],
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.video',
            pathName: 'video',
          },
        ],
        title: 'video.title',
        enTitle: 'video.enTitle',
        description: 'about.description1',
        image: metaBg,
      },
    },
    {
      path: 'popular',
      name: 'popularVideo',
      component: () => import('@/views/video/VideoLayout.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.video',
            pathName: 'popularVideo',
          },
        ],
        title: 'video.title',
        enTitle: 'video.enTitle',
        description: 'about.description1',
        image: metaBg,
      },
    },
    {
      path: 'favorite',
      name: 'favoriteVideo',
      component: () => import('@/views/video/VideoLayout.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.video',
            pathName: 'favoriteVideo',
          },
        ],
        title: 'video.title',
        enTitle: 'video.enTitle',
        description: 'about.description1',
        image: metaBg,
      },
    },
  ],
};
export default videoRouter;
