const detailRouter = {
  path: 'sale/:id',
  name: 'saleDetail',
  component: () => import('@/views/detail/rent/DetailView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.sale',
        pathName: 'sale',
      },
      {
        name: ':id',
        pathName: 'saleDetail',
      },
    ],
    title: '',
    description: '',
    image: '',
  },
};
export default detailRouter;
