<template>
  <div v-show="show" class="home_sectionView--notice">
    <SvgIcon icon-class="no_result" />
    <div class="home_sectionView--notice--textView">
      <p>{{ $t('home.noResult') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoResult',
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
