<template>
    <DialogTemplate :show="open">
      <div class="dialog_ad" :class="{ 'skeleton_loading': isLoad }">
        <div class="dialog_ad--imgView">
            <img v-if="!isLoad" :src="ad.image?.length > 0 ? ad?.image : ''" :alt="ad.id" @click.stop="clickLink">
            <div class="dialog_ad--closeView">
              <button
                type="button"
                class="dialog_ad--closeBtn"
                :class="{ 'diabled': disabled }"
                @click.stop="closeDialog"
              >
                <SvgIcon icon-class="close" />
              </button>
              <div class="dialog_ad--countView">
                <span class="dialog_ad--countView--count">{{ sec }}s</span>
              </div>
            </div>
        </div>
      </div>
    </DialogTemplate>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogTemplate from './DialogTemplate.vue';

export default {
  name: 'FloatAdDialog',
  components: {
    DialogTemplate,
  },
  props: {
    open: {
      type: Boolean,
    },
    ad: {
      type: Object,
    },
    isLoad: {
      type: Boolean,
    },
  },
  emits: ['close'],
  data() {
    return {
      loading: false,
      disabled: true,
      timer: null,
      sec: this.ad.sec,
    };
  },
  watch: {
    $route(value, oldValue) {
      if (value !== oldValue) {
        this.closeDialog();
      }
    },
    sec(value) {
      if (value < 1) {
        clearInterval(this.timer);
        this.timer = null;
        this.disabled = false;
      }
    },
  },
  computed: {
    ...mapGetters(['lang']),
    commonLink() {
      return `https://88gohouse.com/${this.lang}/identity`;
    },
  },
  mounted() {
    if (this.sec !== 0) {
      this.timer = setInterval(() => {
        this.sec -= 1;
      }, 1000);
    } else {
      this.disabled = false;
    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    closeDialog() {
      if (this.disabled) {
        return;
      }
      this.$emit('close');
    },
    clickLink() {
      window.location.href = this.commonLink;
    },
  },
};
</script>
