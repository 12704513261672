import { createStore } from 'vuex';
import { useStorage } from 'vue3-storage';
import moment from 'moment';

const storage = useStorage();

export default createStore({
  state: {
    // cachedViews: [],
    isMobile: false,
    lang: storage.getStorageSync('lang') || 'vn',
    isLogin: storage.getStorageSync('token') || false,
    isSub: storage.getStorageSync('sub'),
    identity: storage.getStorageSync('identity') || '',
    role: storage.getStorageSync('role'),
    name: storage.getStorageSync('name'),
    bestRentHouse: [],
    bestSaleHouse: [],
    bestPreSaleHouse: [],
    bestFactory: [],
    bestLand: [],
    bestOffice: [],
    bestShop: [],
    suitableRentHouse: [],
    suitableSaleHouse: [],
    suitablePreSaleHouse: [],
    suitableFactory: [],
    suitableLand: [],
    suitableOffice: [],
    suitableShop: [],
    carousel: [],
    viewTime: storage.getStorageSync('viewTime') || { house: {}, store: {} },
    verifyPhone: '',
    cityList: [],
    points: null,
    certification: {
      status: 0,
      real_name: '',
      id_number: '',
      cert_type: null,
      remark: '',
      identity: [],
      license: [],
    },
    mapCenter: {
      lat: 10.8230989,
      lng: 106.6296638,
    },
    facebook: null,
    closeFloatAd: true,
    forumUser: {},
    videoList: [],
    videosInDetail: [],
    videoComments: [],
    vh: null,
    businessDate: null,
    salesList: [],
    storeIsEnable: false,
    meta: {},
    companyInfo: {},
    subInfo: {},
    clientAuditNum: 0,
    storeFlyers: [],
    news: [],
    switchIdentity: false,
    switchToRedirect: {},
  },
  getters: {
    // cachedViews: (state) => state.cachedViews,
    isMobile: (state) => state.isMobile,
    lang: (state) => state.lang,
    isLogin: (state) => state.isLogin,
    isSub: (state) => state.isSub,
    identity: (state) => state.identity,
    role: (state) => state.role,
    name: (state) => state.name,
    bestRentHouse: (state) => state.bestRentHouse,
    bestSaleHouse: (state) => state.bestSaleHouse,
    bestPreSaleHouse: (state) => state.bestPreSaleHouse,
    bestFactory: (state) => state.bestFactory,
    bestLand: (state) => state.bestLand,
    bestOffice: (state) => state.bestOffice,
    bestShop: (state) => state.bestShop,
    suitableRentHouse: (state) => state.suitableRentHouse,
    suitableSaleHouse: (state) => state.suitableSaleHouse,
    suitablePreSaleHouse: (state) => state.suitablePreSaleHouse,
    suitableFactory: (state) => state.suitableFactory,
    suitableLand: (state) => state.suitableLand,
    suitableOffice: (state) => state.suitableOffice,
    suitableShop: (state) => state.suitableShop,
    carousel: (state) => state.carousel,
    viewTime: (state) => state.viewTime,
    verifyPhone: (state) => state.verifyPhone,
    cityList: (state) => state.cityList,
    points: (state) => state.points,
    certification: (state) => state.certification,
    mapCenter: (state) => state.mapCenter,
    facebook: (state) => state.facebook,
    closeFloatAd: (state) => state.closeFloatAd,
    forumUser: (state) => state.forumUser,
    videoList: (state) => state.videoList,
    videosInDetail: (state) => state.videosInDetail,
    videoComments: (state) => state.videoComments,
    vh: (state) => state.vh,
    businessDate: (state) => state.businessDate,
    salesList: (state) => state.salesList,
    storeIsEnable: (state) => state.storeIsEnable,
    meta: (state) => state.meta,
    companyInfo: (state) => state.companyInfo,
    subInfo: (state) => state.subInfo,
    clientAuditNum: (state) => state.clientAuditNum,
    storeFlyers: (state) => state.storeFlyers,
    news: (state) => state.news,
    switchIdentity: (state) => state.switchIdentity,
    switchToRedirect: (state) => state.switchToRedirect,
  },
  mutations: {
    // ADD_CACHED_VIEW: (state, value) => {
    //   if (state.cachedViews.includes(value.name)) return;
    //   if (value.meta.keepAlive) {
    //     state.cachedViews.push(value.name);
    //   }
    // },
    UPDATE_DEVICE(state, value) {
      state.isMobile = value;
    },
    LOGIN_STATUS(state, value) {
      state.isLogin = value;
    },
    CHANGE_LAN(state, value) {
      state.lang = value;
      storage.setStorageSync('lang', value);
    },
    SUB_ACCOUNT(state, value) {
      state.isSub = value;
    },
    UPDATE_NAME(state, value) {
      state.name = value;
      storage.setStorageSync('name', value);
    },
    UPDATE_BEST_RENT_HOUSE(state, value) {
      state.bestRentHouse = value;
    },
    UPDATE_BEST_SALE_HOUSE(state, value) {
      state.bestSaleHouse = value;
    },
    UPDATE_BEST_PRESALE_HOUSE(state, value) {
      state.bestPreSaleHouse = value;
    },
    UPDATE_BEST_FACTORY(state, value) {
      state.bestFactory = value;
    },
    UPDATE_BEST_LAND(state, value) {
      state.bestLand = value;
    },
    UPDATE_BEST_OFFICE(state, value) {
      state.bestOffice = value;
    },
    UPDATE_BEST_SHOP(state, value) {
      state.bestShop = value;
    },
    UPDATE_SUITABLE_RENT_HOUSE(state, value) {
      state.suitableRentHouse = value;
    },
    UPDATE_SUITABLE_SALE_HOUSE(state, value) {
      state.suitableSaleHouse = value;
    },
    UPDATE_SUITABLE_PRESALE_HOUSE(state, value) {
      state.suitablePreSaleHouse = value;
    },
    UPDATE_SUITABLE_FACTORY(state, value) {
      state.suitableFactory = value;
    },
    UPDATE_SUITABLE_LAND(state, value) {
      state.suitableLand = value;
    },
    UPDATE_SUITABLE_OFFICE(state, value) {
      state.suitableOffice = value;
    },
    UPDATE_SUITABLE_SHOP(state, value) {
      state.suitableShop = value;
    },
    UPDATE_CAROUSEL(state, value) {
      state.carousel = value;
    },
    UPDATE_VIEW_TIME(state, value) {
      const temp = JSON.parse(JSON.stringify(state.viewTime));
      const last = temp[value.type][value.id];
      if (last && last !== '') {
        const l = moment(last, 'YYYY-MM-DD HH:mm:ss');
        const n = moment();
        if (n.isAfter(l.add(1, 'days'))) {
          temp[value.type][value.id] = moment().format('YYYY-MM-DD HH:mm:ss');
        }
      } else {
        temp[value.type][value.id] = moment().format('YYYY-MM-DD HH:mm:ss');
      }
      state.viewTime = temp;
      storage.setStorageSync('viewTime', temp);
    },
    CHANGE_ROLE(state, value) {
      state.role = value;
    },
    CHANGEiDENTITY(state, value) {
      storage.setStorageSync('token', value.token);
      storage.setStorageSync('identity', value.identity);
      state.identity = value.identity;
      switch (value.identity) {
        case 'guest':
          state.role = 1;
          break;
        case 'host':
          state.role = 2;
          break;
        case 'medium':
          state.role = 3;
          break;
        case 'company':
          state.role = 4;
          break;
        default:
          storage.removeStorageSync('role');
          state.role = '';
          return;
      }
      storage.setStorageSync('role', state.role);
    },
    CHANGE_IDENTITY(state, value) {
      state.identity = value;
    },
    VERIFY_PHONE(state, value) {
      state.verifyPhone = value;
    },
    ADD_CITY(state, value) {
      state.cityList = value;
    },
    GET_POINTS(state, value) {
      state.points = value;
    },
    UPDATE_CERTIFICATION(state, value) {
      state.certification = value;
    },
    UPDATE_CERTIFICATION_NAME(state, value) {
      state.certification.real_name = value;
    },
    UPDATE_CERTIFICATION_ID(state, value) {
      state.certification.id_number = value;
    },
    UPDATE_CERTIFICATION_CERTTYPE(state, value) {
      state.certification.cert_type = value;
    },
    UPDATE_CERTIFICATION_APPROVAL(state, value) {
      state.certification.id_approval_date = value;
    },
    UPDATE_CERTIFICATION_INTRODUCER(state, value) {
      state.certification.introducer = value;
    },
    UPDATE_CERTIFICATION_IDENTITY(state, value) {
      state.certification.identity.push(value);
    },
    UPDATE_CERTIFICATION_LICENSE(state, value) {
      state.certification.license.push(value);
    },
    UPDATE_CERTIFICATION_STATUS(state, value) {
      state.certification.status = value;
    },
    UPDATE_MAP_CENTER(state, value) {
      state.mapCenter = value;
    },
    SET_FB(state, value) {
      state.facebook = value;
    },
    UPDATE_FLOAT_AD(state, value) {
      state.closeFloatAd = value;
    },
    UPDATE_FORUM_USER(state, value) {
      state.forumUser = value;
    },
    UPDATE_VIDEO_LIST(state, value) {
      state.videoList = value;
    },
    UPDATE_VIDEO_LIST_DETAIL(state, value) {
      state.videosInDetail = value;
    },
    UPDATE_VIDEO_COMMENT(state, value) {
      state.videoComments = value;
    },
    UPDATE_VH(state, value) {
      state.vh = value;
    },
    UPDATE_BUSINESS_DATE(state, value) {
      state.businessDate = value;
    },
    UPDATE_SALES_LIST(state, value) {
      state.salesList = value;
    },
    UPDATE_STORE_STATUS(state, value) {
      state.storeIsEnable = value;
    },
    UPDATE_META(state, value) {
      state.meta = value;
    },
    UPDATE_COMPANY_INFO(state, value) {
      state.companyInfo = value;
    },
    UPDATE_SUB_INFO(state, value) {
      state.subInfo = value;
    },
    UPDATE_CLIENT_AUDIT_NUM(state, value) {
      state.clientAuditNum = value;
    },
    UPDATE_STORE_FLYERS(state, value) {
      state.storeFlyers = value;
    },
    UPDATE_NEWS(state, value) {
      state.news = value;
    },
    UPDATE_SWITCH_IDENTITY(state, value) {
      state.switchIdentity = value;
    },
    UPDATE_SWITCH_TO_REDIRECT(state, value) {
      state.switchToRedirect = value;
    },
  },
  actions: {
    async logIn({ commit }, value) {
      commit('LOGIN_STATUS', value.token);
      commit('CHANGE_ROLE', value.role);
      commit('SUB_ACCOUNT', value.sub_id);
      storage.setStorageSync('sub', value.sub_id);
      if (value.sub_id === null || value.sub_id === undefined) {
        switch (value.role) {
          case 1:
            commit('CHANGE_IDENTITY', 'guest');
            storage.setStorageSync('identity', 'guest');
            break;
          case 2:
            commit('CHANGE_IDENTITY', 'host');
            storage.setStorageSync('identity', 'host');
            break;
          case 3:
            commit('CHANGE_IDENTITY', 'medium');
            storage.setStorageSync('identity', 'medium');
            break;
          case 4:
            commit('CHANGE_IDENTITY', 'company');
            storage.setStorageSync('identity', 'company');
            break;
          default:
            commit('CHANGE_IDENTITY', 'guest');
        }
      } else {
        commit('CHANGE_IDENTITY', 'sub');
        storage.setStorageSync('identity', 'sub');
      }
    },
    logOut({ commit }) {
      storage.removeStorageSync('token');
      storage.removeStorageSync('role');
      storage.removeStorageSync('name');
      storage.removeStorageSync('login');
      storage.removeStorageSync('sub');
      storage.removeStorageSync('identity');
      commit('LOGIN_STATUS', false);
      commit('SUB_ACCOUNT', false);
      commit('CHANGE_ROLE', null);
      commit('CHANGE_IDENTITY', '');
    },
    isAddView({ state, commit }, value) {
      const last = state.viewTime[value.type][value.id];
      if (last && last !== '') {
        const l = moment(last, 'YYYY-MM-DD HH:mm:ss');
        const n = moment();
        if (l.add(1, 'days').isAfter(n)) {
          return false;
        }
      }
      commit('UPDATE_VIEW_TIME', value);
      return true;
    },
    // addCachedView({ commit }, value) {
    //   commit('ADD_CACHED_VIEW', value);
    // },
  },
  modules: {
  },
});
