const convertPrice = (price) => {
  let string = '';
  if (isNaN(price) || price === undefined) {
    return '';
  }
  const priceString = price.toString();
  if (priceString.indexOf('~') === -1) {
    const str = priceString.split('.');
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    str.join('.');
    const newStr = str[0];
    string = newStr;
  }
  // else {
  //   const priceArr = price.split('~');
  //   const str1 = priceArr[0].split('.');
  //   const str2 = priceArr[1].split('.');
  //   str1[0] = str1[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  //   str2[0] = str2[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  //   str1.join('.');
  //   str2.join('.');

  //   string = `${str1}-${str2}`;
  // }
  return string;
};

export default convertPrice;
