const officeRentDetailRouter = {
  path: 'office/rent/:id',
  name: 'officeRentDetail',
  component: () => import('@/views/detail/office/DetailView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'navbar.office',
        pathName: 'office',
      },
      {
        name: 'breadcrumbList.rent',
        pathName: 'office',
      },
      {
        name: ':id',
        pathName: 'officeRentDetail',
      },
    ],
    title: '',
    description: '',
    image: '',
  },
};
export default officeRentDetailRouter;
