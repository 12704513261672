import metaBg from '@/assets/meta/meta_bg.png';

const aboutRouter = {
  path: 'about',
  component: () => import('@/views/info/InfoView.vue'),
  children: [
    {
      path: '',
      name: 'about',
      component: () => import('@/views/info/about/AboutView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.choose88go',
            pathName: 'about',
          },
        ],
        title: 'info.choose88go',
        description: 'about.description1',
        image: metaBg,
        bannerTitle: 'info.choose88go',
        bannerEnTitle: 'info.choose88goEn',
      },
    },
  ],
};
export default aboutRouter;
