<template>
    <DialogTemplate :show="open">
      <div class="dialog_container">
          <div class="dialog_card">
              <div class="dialog_card--header">
                  <h4 class="dialog_card--title">{{ $t('dialog.noLoginYet') }}</h4>
              </div>
              <div class="dialog_card--content dialog_card--alertModal">
                  <span class="dialog_card--content--text">{{ $t('dialog.noLogin') }}</span>
              </div>
              <div class="dialog_card--bottom">
                  <div class="dialog_card--bottom--row">
                      <div class="dialog_card--btn">
                          <button type="button" class="w_100 more_button" @click="goToLogin">{{ $t('dialog.goLogin') }}</button>
                      </div>
                      <div class="dialog_card--btn" @click="closeDialog">
                          <button type="button" class="more_button--outline">{{ $t('dialog.cancel') }}</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </DialogTemplate>
</template>

<script>
import DialogTemplate from './DialogTemplate.vue';

export default {
  name: 'NoLoginDialog',
  components: {
    DialogTemplate,
  },
  props: {
    open: {
      type: Boolean,
    },
  },
  emits: ['close'],
  methods: {
    goToLogin() {
      this.closeDialog();
      this.$router.push({ name: 'login' });
    },
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>
