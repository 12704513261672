import metaBg from '@/assets/meta/meta_bg.png';

const disclaimerRouter = {
  path: 'disclaimer',
  component: () => import('@/views/info/InfoView.vue'),
  children: [
    {
      path: '',
      name: 'disclaimer',
      component: () => import('@/views/info/disclaimer/DisclaimerView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.disclaimer',
            pathName: 'disclaimer',
          },
        ],
        title: 'breadcrumbList.disclaimer',
        description: 'disclaimer.list.description1',
        image: metaBg,
        bannerTitle: 'info.disclaimer',
        bannerEnTitle: 'info.disclaimerEn',
      },
    },
  ],
};
export default disclaimerRouter;
