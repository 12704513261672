import metaBg from '@/assets/meta/meta_bg.png';

const landRouter = {
  path: 'land',
  name: 'land',
  component: () => import('@/views/rent/RentView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.land',
        pathName: 'land',
      },
    ],
    title: 'landList.title',
    enTitle: 'landList.enTitle',
    description: 'about.description1',
    image: metaBg,
    recommendedTitle: 'landList.recommended',
    allTitle: 'landList.forLand',
  },
};
export default landRouter;
