import metaBg from '@/assets/meta/meta_bg.png';

const upgradeAccountRouter = {
  path: 'upgrade-account',
  name: 'upgradeAccount',
  component: () => import('@/views/info/upgrade_account/PlanView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.upgradeAccount',
        pathName: 'upgradeAccount',
      },
    ],
    title: 'breadcrumbList.upgradeAccount',
    description: 'plan.description1',
    image: metaBg,
    requiredAuth: true,
    match: 'company',
    scrollAnchor: true,
  },
};
export default upgradeAccountRouter;
