const officeSaleDetailRouter = {
  path: 'office/sale/:id',
  name: 'officeSaleDetail',
  component: () => import('@/views/detail/office/DetailView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'navbar.office',
        pathName: 'office',
      },
      {
        name: 'breadcrumbList.sale',
        pathName: 'office',
      },
      {
        name: ':id',
        pathName: 'officeSaleDetail',
      },
    ],
    title: '',
    description: '',
    image: '',
  },
};
export default officeSaleDetailRouter;
