import service, { uploadService } from '@/plugins/axios';

// 帳號管理
const root = '/b/b105';

// 取得帳號資訊
export function info(params) {
  return service({
    url: `${root}/info`,
    method: 'get',
    params,
  });
}

// 更新帳號資訊
export function updateInfo(data) {
  return service({
    url: `${root}/update_info`,
    method: 'put',
    data,
  });
}

// 更新頭像
export function updateAvatar(data) {
  return uploadService({
    url: `${root}/update_avatar`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
}

// 連結帳號
export function connect(data) {
  return service({
    url: `${root}/connect`,
    method: 'post',
    data,
  });
}

// 解除連結帳號
export function disconnect(data) {
  return service({
    url: `${root}/disconnect`,
    method: 'delete',
    data,
  });
}

// 建立密碼
export function createPwd(data) {
  return service({
    url: `${root}/create_pwd`,
    method: 'post',
    data,
  });
}

// 更新密碼
export function updatePwd(data) {
  return service({
    url: `${root}/update_pwd`,
    method: 'put',
    data,
  });
}

// 發送驗證碼
export function sendVerifyCode(data) {
  return service({
    url: `${root}/send_verify_code`,
    method: 'put',
    data,
  });
}

// 驗證驗證碼
export function verifyCode(data) {
  return service({
    url: `${root}/verify_code`,
    method: 'put',
    data,
  });
}

// 更新評分隱藏
export function rating(data) {
  return service({
    url: `${root}/rating`,
    method: 'put',
    data,
  });
}

// 查詢資格認證
export function certQuery() {
  return service({
    url: `${root}/cert_query`,
    method: 'get',
  });
}

// 更新資格認證
export function certUpdate(data) {
  return service({
    url: `${root}/cert_update`,
    method: 'put',
    data,
  });
}

// 查詢工作資料
export function workQuery(params) {
  return service({
    url: `${root}/work_query`,
    method: 'get',
    params,
  });
}

// 更新工作資料
export function workUpdate(data) {
  return service({
    url: `${root}/work_update`,
    method: 'put',
    data,
  });
}

// 查詢集團工作資料
export function groupWorkQuery(params) {
  return service({
    url: `${root}/group_work_query`,
    method: 'get',
    params,
  });
}

// 更新集團工作資料
export function groupWorkUpdate(data) {
  return service({
    url: `${root}/group_work_update`,
    method: 'put',
    data,
  });
}

// 查詢分店工作資料
export function branchWorkQuery(params) {
  return service({
    url: `${root}/branch_work_query`,
    method: 'get',
    params,
  });
}

// 更新分店工作資料
export function branchWorkUpdate(data) {
  return service({
    url: `${root}/branch_work_update`,
    method: 'put',
    data,
  });
}

// 取得子帳號列表
export function subList(params) {
  return service({
    url: `${root}/sub_list`,
    method: 'get',
    params,
  });
}

// 查詢子帳號
export function subQuery(id) {
  return service({
    url: `${root}/sub_query/${id}`,
    method: 'get',
  });
}

// 新增子帳號
export function subCreate(data) {
  return service({
    url: `${root}/sub_create`,
    method: 'post',
    data,
  });
}

// 更新子帳號
export function subUpdate(data) {
  return service({
    url: `${root}/sub_update`,
    method: 'put',
    data,
  });
}

// 更新子帳號狀態
export function subUpdateStatus(data) {
  return service({
    url: `${root}/sub_update_status`,
    method: 'put',
    data,
  });
}

// 更新子帳號密碼
export function subUpdatePwd(data) {
  return service({
    url: `${root}/sub_update_pwd`,
    method: 'put',
    data,
  });
}

// 取得會員偏好
export function pref(params) {
  return service({
    url: `${root}/pref`,
    method: 'get',
    params,
  });
}

// 更新會員偏好
export function updatePref(data) {
  return service({
    url: `${root}/update_pref`,
    method: 'put',
    data,
  });
}

// 分店回覆
export function branchReply(data) {
  return service({
    url: `${root}/branch_reply`,
    method: 'put',
    data,
  });
}
