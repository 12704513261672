import metaBg from '@/assets/meta/meta_bg.png';

const mapOfficeRouter = {
  path: 'office/map',
  name: 'officeMap',
  component: () => import('@/views/map/MapView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'navbar.office',
        pathName: 'office',
      },
      {
        name: 'breadcrumbList.mapSearch',
        pathName: 'officeMap',
      },
    ],
    title: 'breadcrumbList.mapSearch',
    description: 'about.description1',
    bannerTitle: 'map.officeMapSearch',
    bannerEnTitle: 'map.officeMapSearchEn',
    image: metaBg,
  },
};
export default mapOfficeRouter;
