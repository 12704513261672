<template>
  <div class="navbar">
    <div class="navbar_container">
      <div class="navbar_items">
        <router-link :to="{ name: 'home' }" class="navbar_home" @click.prevent="onHome">
          <div
            class="navbar_home--logo"
            :style="{ backgroundImage: switchLogo }"
          ></div>
          <h1>88go</h1>
        </router-link>
        <div v-for="(item, key) in menuOption" :key="key" class="navbar_items--item navbar_items--mobile_hide">
          <router-link
            :to="{ name: item.route }"
            :class="{ activate: $route.name === item.route || $route.name === item.match || $route.name === item.match2  }"
            @click.prevent="directPath(item.route)"
          >
            {{ item.name }}
          </router-link>
        </div>
      </div>
      <div class="navbar_items navbar_items--mobile_hide">
        <button
          type="button"
          class="fill_button navbar_items--item"
          @click="directPath('firstPublish')"
        >
          <SvgIcon icon-class="upload" class-name="icon" />
          {{ $t("navbar.publish") }}
        </button>
        <button
          type="button"
          class="clear_button navbar_items--item"
          @click="chooseUser"
          id="user"
        >
          <SvgIcon icon-class="user" class-name="navbar_items--icon" />
          <span>{{ $t('navbar.join') }}</span>
        </button>
        <div class="navbar_items--block">
          <button
            type="button"
            class="avatar_button navbar_items--item"
            @click="openLanguageDialog"
          >
            <SvgIcon icon-class="global" class-name="navbar_items--icon" />
          </button>
          <div class="too_tips" :style="{ 'width': calcToolTipWidth, 'right': calcToolTipRight }">
            <p>{{ $t('language') }}</p>
          </div>
        </div>
        <button
          type="button"
          class="avatar_button navbar_items--item"
          @click="chooseMore"
          id="more"
        >
          <SvgIcon icon-class="more" class-name="navbar_items--icon" />
        </button>
        <UserDropdown :open="openUser" @close="openUser = false" />
        <MoreDropdown :open="openMore" @close="openMore = false" />
      </div>
    </div>
  </div>
  <MenuDialog
    :open="openMenu"
    @close="closeMenuDialog"
    @toggle="toggleMenuDialog"
  />
  <NoLoginDialog :open="openNoLogin" @close="closeNotLoginDialog" />
  <LanguageDialog :open="openLanguage" @close="closeLanguageDialog" />
  <GuestIdentityDialog :open="openGuest" @close="closeGuestDialog" />
</template>

<script>
import logVn from '@/assets/logo/logo.png';
import logCn from '@/assets/logo/logo_cn.png';
import logEn from '@/assets/logo/logo_en.png';
import { mapGetters } from 'vuex';
import UserDropdown from './UserDropdown.vue';
import MoreDropdown from './MoreDropdown.vue';
import LanguageDialog from '../dialog/LanguageDialog.vue';
import NoLoginDialog from '../dialog/NoLoginDialog.vue';
import GuestIdentityDialog from '../dialog/GuestIdentityDialog.vue';
import MenuDialog from '../dialog/MenuDialog.vue';

export default {
  name: 'NavbarView',
  components: {
    UserDropdown,
    MoreDropdown,
    LanguageDialog,
    NoLoginDialog,
    GuestIdentityDialog,
    MenuDialog,
  },
  data() {
    return {
      menuOption: [
        {
          name: this.$t('navbar.rent'),
          route: 'rent',
          match: 'rentDetail',
        },
        {
          name: this.$t('navbar.sale'),
          route: 'sale',
          match: 'saleDetail',
        },
        {
          name: this.$t('navbar.preSale'),
          route: 'preSale',
          match: 'preSaleDetail',
        },
        {
          name: this.$t('navbar.factory'),
          route: 'factory',
          match: 'factoryRentDetail',
          match2: 'factorySaleDetail',
        },
        {
          name: this.$t('navbar.land'),
          route: 'land',
          match: 'landRentDetail',
          match2: 'landSaleDetail',
        },
        {
          name: this.$t('navbar.office'),
          route: 'office',
          match: 'officeRentDetail',
          match2: 'officeSaleDetail',
        },
        {
          name: this.$t('navbar.shop'),
          route: 'shop',
          match: 'shopRentDetail',
          match2: 'shopSaleDetail',
        },
      ],
      openUser: false,
      openLanguage: false,
      openNoLogin: false,
      openGuest: false,
      openMenu: false,
      openMore: false,
    };
  },
  watch: {
    lang(value, oldValue) {
      if (value !== oldValue) {
        this.menuOption = [
          {
            name: this.$t('navbar.rent'),
            route: 'rent',
            match: 'rentDetail',
          },
          {
            name: this.$t('navbar.sale'),
            route: 'sale',
            match: 'saleDetail',
          },
          {
            name: this.$t('navbar.preSale'),
            route: 'preSale',
            match: 'preSaleDetail',
          },
          {
            name: this.$t('navbar.factory'),
            route: 'factory',
            match: 'factoryRentDetail',
          },
          {
            name: this.$t('navbar.land'),
            route: 'land',
            match: 'landRentDetail',
          },
          {
            name: this.$t('navbar.office'),
            route: 'office',
            match: 'officeRentDetail',
            match2: 'officeSaleDetail',
          },
          {
            name: this.$t('navbar.shop'),
            route: 'shop',
            match: 'shopRentDetail',
            match2: 'shopSaleDetail',
          },
        ];
      }
    },
  },
  computed: {
    ...mapGetters(['lang', 'isLogin', 'role']),
    switchLogo() {
      let style = '';
      switch (this.lang) {
        case 'vn':
          style = `url(${logVn})`;
          break;
        case 'cn':
          style = `url(${logCn})`;
          break;
        case 'en':
          style = `url(${logEn})`;
          break;
        default:
          break;
      }
      return style;
    },
    calcToolTipWidth() {
      switch (this.lang) {
        case 'vn':
          return '100px';
        case 'en':
          return '100px';
        case 'cn':
          return '44px';
        default:
          return '44px';
      }
    },
    calcToolTipRight() {
      switch (this.lang) {
        case 'vn':
          return '-24px';
        case 'en':
          return '-24px';
        case 'cn':
          return '8px';
        default:
          return '8px';
      }
    },
  },
  methods: {
    onHome() {
      this.closeMenuDialog();
      this.$router.push({ name: 'home' });
    },
    directPath(name) {
      if (name === 'firstPublish') {
        if (!this.isLogin) {
          this.openNoLogin = true;
          return;
        } else if (this.role === 1) {
          this.openGuest = true;
          this.$store.commit('UPDATE_SWITCH_TO_REDIRECT', { name: 'firstPublish' });
          return;
        }
      }
      if (name === 'rent' || name === 'sale' || name === 'preSale' || name === 'firstPublish') {
        this.$router.push({ name });
      } else if (name === 'factory') {
        this.$router.push({
          name,
          query: {
            type: 4,
          },
        });
      } else if (name === 'land') {
        this.$router.push({
          name,
          query: {
            type: 6,
          },
        });
      } else if (name === 'office') {
        this.$router.push({
          name,
          query: {
            type: 8,
          },
        });
      } else if (name === 'shop') {
        this.$router.push({
          name,
          query: {
            type: 10,
          },
        });
      }
    },
    chooseUser() {
      this.openUser = !this.openUser;
    },
    chooseMore() {
      this.openMore = !this.openMore;
    },
    openLanguageDialog() {
      this.openLanguage = true;
    },
    closeLanguageDialog() {
      this.openLanguage = false;
    },
    closeNotLoginDialog() {
      this.openNoLogin = false;
    },
    closeGuestDialog() {
      this.openGuest = false;
    },
    closeMenuDialog() {
      this.openMenu = false;
    },
    toggleMenuDialog() {
      this.openMenu = !this.openMenu;
    },
  },
};
</script>
