import service from '@/plugins/axios';

// 土地
const root = '/a/a109';

// 精選土地
export function bestLand(params) {
  return service({
    url: `${root}/best_land`,
    method: 'get',
    params,
  });
}

// 適合土地
export function suitableLand(params) {
  return service({
    url: `${root}/suitable_land`,
    method: 'get',
    params,
  });
}

// 推薦土地
export function recommendLand(params) {
  return service({
    url: `${root}/recommend_land`,
    method: 'get',
    params,
  });
}

// 條件土地
export function conditionLand(params) {
  return service({
    url: `${root}/condition_land`,
    method: 'get',
    params,
  });
}

// 相似土地
export function similarLand(params) {
  return service({
    url: `${root}/similar_land`,
    method: 'get',
    params,
  });
}

// 土地詳情
export function landDetail(params) {
  return service({
    url: `${root}/land_detail`,
    method: 'get',
    params,
  });
}

// 今日新增土地數
export function landOfTodayCount(params) {
  return service({
    url: `${root}/today_count`,
    method: 'get',
    params,
  });
}
