import metaBg from '@/assets/meta/meta_bg.png';

const mortgageCalculatorRouter = {
  path: 'mortgage-calculator',
  name: 'mortgageCalculator',
  component: () => import('@/views/mortgageCalculator/MortgageCalculator.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'footer.mortgageCalculator',
        pathName: 'mortgageCalculator',
      },
    ],
    title: 'footer.mortgageCalculator',
    description: 'about.description1',
    image: metaBg,
    bannerTitle: 'footer.mortgageCalculator',
    bannerEnTitle: 'mortgageCalculator.enTitle',
  },
};
export default mortgageCalculatorRouter;
