<template>
  <div class="home_container">
    <div class="home_contentView">
      <div class="home_contentView--left">
        <!-- /* 優選 */-->
        <div class="home_sectionView">
          <div class="home_contentView--row">
            <div class="home_contentView--titleView">
              <h3 class="home_contentView--title preferred">
                {{ preferred }}
              </h3>
            </div>
            <div v-show="type !== 1 && type !== 2 && type !== 3" class="home_contentView--selectView">
              <CategoryTab :list="tabsList" :choose="chooseTab" @update="switchTab" />
            </div>
          </div>
          <BestRent v-if="type === 1" />
          <BestSale v-else-if="type === 2" />
          <BestPreSale v-else-if="type === 3" />
          <BestFactory v-else-if="type === 4" :choose="chooseTab" />
          <BestLand v-else-if="type === 5" :choose="chooseTab" />
          <BestOffice v-else-if="type === 6" :choose="chooseTab"/>
          <BestShop v-else-if="type === 7" :choose="chooseTab" />
        </div>
        <div class="home_sectionView">
          <div
              class="home_searchItems"
              :class="{
                'sale': type === 2,
                'new_project': type === 3,
                'factory': type === 4,
                'land': type === 5,
                'office': type === 6,
                'shop': type === 7,
              }"
          >
            <div class="home_searchItems--left">
              <div class="home_bg" @click="searchType">
                <div class="home_bg--textView">
                  <span class="home_bg--text">{{ $t("home.listSearch") }}</span>
                  <span class="home_bg--enText">List Search</span>
                </div>
                <div class="home_bg--card home_bg--left">
                </div>
              </div>
            </div>
            <div class="home_searchItems--right">
              <div class="home_bg" @click="searchMap">
                <div class="home_bg--textView">
                  <span class="home_bg--text">{{ $t("home.mapSearch") }}</span>
                  <span class="home_bg--enText">Map Search</span>
                </div>
                <div class="home_bg--card home_bg--right">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home_sectionView">
          <MatchingBanner :type="type" />
        </div>
        <div class="home_sectionView">
          <div class="home_contentView--row">
            <div class="home_contentView--titleView">
              <h3 class="home_contentView--title">
                {{ suitable }}
              </h3>
            </div>
            <div v-show="type !== 1 && type !== 2 && type !== 3" class="home_contentView--selectView">
              <CategoryTab :list="tabsList" :choose="suitableTab" @update="switchSuitableTab" />
            </div>
          </div>
          <SuitableRent v-if="type === 1" />
          <SuitableSale v-else-if="type === 2" />
          <SuitablePreSale v-else-if="type === 3" />
          <SuitableFactory v-else-if="type === 4" :choose="suitableTab" />
          <SuitableLand v-else-if="type === 5" :choose="suitableTab"  />
          <SuitableOffice v-else-if="type === 6" :choose="suitableTab" />
          <SuitableShop v-else-if="type === 7" :choose="suitableTab" />
        </div>
        <div class="home_sectionView">
          <h3 class="home_contentView--title">
            {{ popularText }}
          </h3>
          <div class="home_cardView">
            <div class="home_cardView--col4">
              <div class="home_popular" @click="redirectCity('79')">
                <div class="home_popular--img" :data-webp="flag"></div>
                <div class="home_popular--cover">
                  <div class="home_bg--textView">
                    <span class="home_bg--smText">{{
                      $t("popularArea[0].name")
                    }}</span>
                    <span class="home_bg--smText">{{
                      $t("popularArea[0].en")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="home_cardView--col6">
              <div class="home_cardView">
                <div
                  v-for="(img, index) in popularList"
                  :key="index"
                  class="home_cardView--col3"
                >
                  <div class="home_popularSm" @click="redirectCity(img.id)">
                    <div
                        class="home_popularSm--img"
                        :style="{ 'backgroundImage': this.flag === '1' ? `url(${img.webp})` : `url(${img.img})` }"
                    ></div>
                    <div class="home_popularSm--cover">
                      <div class="home_bg--textView">
                        <span class="home_bg--smText">{{ img.name }}</span>
                        <span class="home_bg--smText">{{ img.enName }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewsViews />
        <AboutViews />
        <div class="home_sectionView">
          <div class="home_sectionView--items">
            <div class="home_searchItems">
              <div class="home_searchItems--left w_100">
                <router-link :to="{ name: 'about' }" class="home_bg bottom">
                  <div class="home_bg--textView">
                    <span class="home_bg--text">{{ $t("home.whyChoose") }}</span>
                    <span class="home_bg--enText">About 88go</span>
                  </div>
                  <div class="home_bg--card home_bg--top"></div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="home_sectionView--items">
            <div class="home_searchItems">
              <div class="home_searchItems--left w_100">
                <router-link :to="{ name: 'plan' }" class="home_bg bottom">
                  <div class="home_bg--textView">
                    <span class="home_bg--text">{{ $t("home.outPlan") }}</span>
                    <span class="home_bg--enText">Our Membership Plans</span>
                  </div>
                  <div class="home_bg--card home_bg--bottom"></div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home_contentView--right">
        <div class="home_add" :class="{ skeleton_loading: adLoading }">
          <img
            v-if="!adLoading"
            :src="!adLoading ? ad.image : ''"
            alt=""
            @click="checkLink"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HaNoi from '@/assets/city/Ha_Noi_City.png';
import HaNoiWebp from '@/assets/city/Ha_Noi_City.webp';
import HaiPong from '@/assets/city/Hai_Phong_City.png';
import HaiPongWebp from '@/assets/city/Hai_Phong_City.webp';
import CanTho from '@/assets/city/Can_Tho_City.png';
import CanThoWebp from '@/assets/city/Can_Tho_City.webp';
import DaNang from '@/assets/city/Da_Nang_City.png';
import DaNangWebp from '@/assets/city/Da_Nang_City.webp';
import DongNai from '@/assets/city/Dong_Nai_Province.png';
import DongNaiWebp from '@/assets/city/Dong_Nai_Province.webp';
import BinhDuong from '@/assets/city/Binh_Duong_Province.png';
import BinhDuongWebp from '@/assets/city/Binh_Duong_Province.webp';
import BestRent from '@/views/home/components/house/BestRent.vue';
import BestSale from '@/views/home/components/house/BestSale.vue';
import BestPreSale from '@/views/home/components/preSale/BestPreSale.vue';
import BestFactory from '@/views/home/components/factory/BestFactory.vue';
import BestLand from '@/views/home/components/land/BestLand.vue';
import BestOffice from '@/views/home/components/office/BestOffice.vue';
import BestShop from '@/views/home/components/shop/BestShop.vue';
import SuitableRent from '@/views/home/components/house/SuitableRent.vue';
import SuitableSale from '@/views/home/components/house/SuitableSale.vue';
import SuitablePreSale from '@/views/home/components/preSale/SuitablePreSale.vue';
import SuitableFactory from '@/views/home/components/factory/SuitableFactory.vue';
import SuitableLand from '@/views/home/components/land/SuitableLand.vue';
import SuitableOffice from '@/views/home/components/office/SuitableOffice.vue';
import SuitableShop from '@/views/home/components/shop/SuitableShop.vue';
import NewsViews from '@/views/home/components/news/NewsViews.vue';
import AboutViews from '@/views/home/components/about/AboutViews.vue';
import MatchingBanner from '@/views/home/components/match/MatchingBanner.vue';
import { mapGetters } from 'vuex';
import { ad } from '@/plugins/api/a/a107';
import CategoryTab from '@/views/memberCenter/page/common/publish/components/CategoryTab.vue.vue';

export default {
  name: 'HomeContent',
  computed: {
    ...mapGetters(['isMobile', 'lang', 'bestRentHouse', 'identity', 'isLogin']),
    tabsList() {
      switch (this.type) {
        case 4:
          return [
            {
              id: 3,
              label: this.$t('breadcrumbList.rent'),
            },
            {
              id: 4,
              label: this.$t('breadcrumbList.sale'),
            },
          ];
        case 5:
          return [
            {
              id: 5,
              label: this.$t('breadcrumbList.rent'),
            },
            {
              id: 6,
              label: this.$t('breadcrumbList.sale'),
            },
          ];
        case 6:
          return [
            {
              id: 7,
              label: this.$t('breadcrumbList.rent'),
            },
            {
              id: 8,
              label: this.$t('breadcrumbList.sale'),
            },
          ];
        case 7:
          return [
            {
              id: 9,
              label: this.$t('breadcrumbList.rent'),
            },
            {
              id: 10,
              label: this.$t('breadcrumbList.sale'),
            },
          ];
        default:
          return [];
      }
    },
    popularText() {
      switch (this.type) {
        case 1:
          return this.$t('home.rentInPopular');
        case 2:
          return this.$t('home.buyingPopular');
        case 3:
          return this.$t('home.newHousePopular');
        case 4:
          return this.$t('home.factoryPopular');
        case 5:
          return this.$t('home.landPopular');
        case 6:
          return this.$t('home.officePopular');
        case 7:
          return this.$t('home.shopPopular');
        default:
          return '';
      }
    },
    preferred() {
      switch (this.type) {
        case 1:
          return this.$t('home.preferredHouse');
        case 2:
          return this.$t('home.preferredHouse');
        case 3:
          return this.$t('home.preferredConstruction');
        case 4:
          return this.$t('home.preferredFactory');
        case 5:
          return this.$t('home.preferredLand');
        case 6:
          return this.$t('home.preferredOffice');
        case 7:
          return this.$t('home.preferredShop');
        default:
          return '';
      }
    },
    suitable() {
      switch (this.type) {
        case 1:
          return this.$t('home.suitable');
        case 2:
          return this.$t('home.suitable');
        case 3:
          return this.$t('home.suitableNewHouse');
        case 4:
          return this.$t('home.suitableFactory');
        case 5:
          return this.$t('home.suitableLand');
        case 6:
          return this.$t('home.suitableOffice');
        case 7:
          return this.$t('home.suitableShop');
        default:
          return '';
      }
    },
    commonLink() {
      return `https://88gohouse.com/${this.lang}/identity`;
    },
  },
  components: {
    CategoryTab,
    BestRent,
    BestSale,
    SuitableRent,
    SuitableSale,
    BestPreSale,
    SuitablePreSale,
    BestFactory,
    SuitableFactory,
    BestLand,
    SuitableLand,
    BestOffice,
    SuitableOffice,
    BestShop,
    SuitableShop,
    NewsViews,
    AboutViews,
    MatchingBanner,
  },
  props: {
    type: {
      type: Number,
    },
  },
  data() {
    return {
      // eslint-disable-next-line max-len
      defaultImage: 'https://cdn.discordapp.com/attachments/821207464622948414/1169825506313638038/Frame_971_1.png?ex=6556cfe7&is=65445ae7&hm=a7c4c5d70ca97c398dd3b89e71ab7703c0fc0c05f7f4ca256da84bae3399fe1d&',
      defaultUrl: 'https://www.google.com.tw/webhp?hl=zh-TW',
      ad: {
        image: this.defaultImage,
        url: this.defaultUrl,
        existed: false,
      },
      popularList: [
        {
          id: '01',
          name: this.$t('popularArea[1].name'),
          enName: this.$t('popularArea[1].en'),
          img: HaNoi,
          webp: HaNoiWebp,
        },
        {
          id: '31',
          name: this.$t('popularArea[2].name'),
          enName: this.$t('popularArea[2].en'),
          img: HaiPong,
          webp: HaiPongWebp,
        },
        {
          id: '92',
          name: this.$t('popularArea[3].name'),
          enName: this.$t('popularArea[3].en'),
          img: CanTho,
          webp: CanThoWebp,
        },
        {
          id: '48',
          name: this.$t('popularArea[4].name'),
          enName: this.$t('popularArea[4].en'),
          img: DaNang,
          webp: DaNangWebp,
        },
        {
          id: '75',
          name: this.$t('popularArea[5].name'),
          enName: this.$t('popularArea[5].en'),
          img: DongNai,
          webp: DongNaiWebp,
        },
        {
          id: '74',
          name: this.$t('popularArea[6].name'),
          enName: this.$t('popularArea[6].en'),
          img: BinhDuong,
          webp: BinhDuongWebp,
        },
      ],
      adLoading: false,
      flag: '0',
      chooseTab: 3,
      suitableTab: 3,
    };
  },
  watch: {
    type(value) {
      switch (value) {
        case 1:
          this.chooseTab = 3;
          this.suitableTab = 3;
          break;
        case 2:
          this.chooseTab = 3;
          this.suitableTab = 3;
          break;
        case 3:
          this.chooseTab = 3;
          this.suitableTab = 3;
          break;
        case 4:
          this.chooseTab = 3;
          this.suitableTab = 3;
          break;
        case 5:
          this.chooseTab = 5;
          this.suitableTab = 5;
          break;
        case 6:
          this.chooseTab = 7;
          this.suitableTab = 7;
          break;
        case 7:
          this.chooseTab = 9;
          this.suitableTab = 9;
          break;
        default:
          break;
      }
    },
  },
  created() {
    this.isSupportWebp();
    this.getAd();
  },
  methods: {
    getAd() {
      this.adLoading = true;
      ad({ type: 1, block: 1 }).then((res) => {
        const { success, data } = res;
        if (success) {
          this.ad = data;
          if (!this.ad.existed) {
            this.ad.image = this.defaultImage;
            this.ad.url = this.defaultUrl;
          }
        }
        setTimeout(() => {
          this.adLoading = false;
        }, 500);
      }).catch(() => {
        this.adLoading = false;
      });
    },
    searchType() {
      switch (this.type) {
        case 1:
          this.$router.push({
            name: 'rent',
          });
          break;
        case 2:
          this.$router.push({
            name: 'sale',
          });
          break;
        case 3:
          this.$router.push({
            name: 'preSale',
          });
          break;
        case 4:
          this.$router.push({
            name: 'factory',
            query: {
              type: 4,
            },
          });
          break;
        case 5:
          this.$router.push({
            name: 'land',
            query: {
              type: 6,
            },
          });
          break;
        case 6:
          this.$router.push({
            name: 'office',
            query: {
              type: 8,
            },
          });
          break;
        case 7:
          this.$router.push({
            name: 'shop',
            query: {
              type: 10,
            },
          });
          break;
        default:
          break;
      }
    },
    searchMap() {
      switch (this.type) {
        case 1:
          this.$router.push({
            name: 'rentMap',
          });
          break;
        case 2:
          this.$router.push({
            name: 'saleMap',
          });
          break;
        case 3:
          this.$router.push({
            name: 'preSaleMap',
          });
          break;
        case 4:
          this.$router.push({
            name: 'factoryMap',
            query: {
              type: 3,
            },
          });
          break;
        case 5:
          this.$router.push({
            name: 'landMap',
            query: {
              type: 5,
            },
          });
          break;
        case 6:
          this.$router.push({
            name: 'officeMap',
            query: {
              type: 7,
            },
          });
          break;
        case 7:
          this.$router.push({
            name: 'shopMap',
            query: {
              type: 9,
            },
          });
          break;
        default:
          break;
      }
    },
    redirectCity(id) {
      switch (this.type) {
        case 1:
          this.$router.push(
            {
              name: 'rent',
              query: {
                city: id,
              },
            },
          );
          break;
        case 2:
          this.$router.push(
            {
              name: 'sale',
              query: {
                city: id,
              },
            },
          );
          break;
        case 3:
          this.$router.push(
            {
              name: 'preSale',
              query: {
                city: id,
              },
            },
          );
          break;
        case 4:
          this.$router.push(
            {
              name: 'factory',
              query: {
                type: 4,
                city: id,
              },
            },
          );
          break;
        case 5:
          this.$router.push(
            {
              name: 'land',
              query: {
                type: 6,
                city: id,
              },
            },
          );
          break;
        case 6:
          this.$router.push(
            {
              name: 'office',
              query: {
                type: 8,
                city: id,
              },
            },
          );
          break;
        case 7:
          this.$router.push(
            {
              name: 'shop',
              query: {
                type: 10,
                city: id,
              },
            },
          );
          break;
        default:
          break;
      }
    },
    checkLink() {
      window.location.href = this.commonLink;
    },
    isSupportWebp() {
      const canvasEl = document.createElement('canvas');
      if (canvasEl.getContext && canvasEl.getContext('2d')) {
        this.flag = canvasEl.toDataURL('image/webp').indexOf('image/webp') > -1 ? '1' : '0';
      }
      return this.flag;
    },
    switchTab(value) {
      this.chooseTab = value.id;
    },
    switchSuitableTab(value) {
      this.suitableTab = value.id;
    },
    // goToMatch() {
    //   if (this.isLogin) {
    //     if (this.identity !== 'guest') {
    //       this.notGuest = true;
    //     } else {
    //       this.$router.push({ name: 'guestRequirements' });
    //     }
    //   } else {
    //     this.$router.push({ name: 'login' });
    //   }
    // },
  },
};
</script>
