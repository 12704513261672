import store from '../store';

const convertUnit = (number) => {
  let cleanedNumber = number.replace(/,/g, '');
  let formattedNumber;
  let unit = '';

  cleanedNumber = parseFloat(cleanedNumber);

  // 如果轉換後不是有效數字，則直接返回原始輸入
  if (isNaN(cleanedNumber)) {
    return number;
  }

  if (cleanedNumber >= 1e9 && store.getters.lang === 'vn') {
    formattedNumber = (cleanedNumber / 1e9).toFixed(2);
    unit = '  tỷ';
  } else if (cleanedNumber >= 1e6 && store.getters.lang === 'vn') {
    formattedNumber = (cleanedNumber / 1e6).toFixed(2);
    unit = '  triệu';
  } else if (cleanedNumber >= 1e3 && store.getters.lang === 'vn') {
    formattedNumber = (cleanedNumber / 1e3).toFixed(2);
    unit = '  ngàn';
  } else if (cleanedNumber >= 1e9 && store.getters.lang === 'en') {
    formattedNumber = (cleanedNumber / 1e9).toFixed(2);
    unit = '  Trillion';
  } else if (cleanedNumber >= 1e6 && store.getters.lang === 'en') {
    formattedNumber = (cleanedNumber / 1e6).toFixed(2);
    unit = '  Million';
  } else if (cleanedNumber >= 1e3 && store.getters.lang === 'en') {
    formattedNumber = (cleanedNumber / 1e3).toFixed(2);
    unit = '  Thousand';
  } else if (cleanedNumber >= 1e12 && store.getters.lang === 'cn') {
    formattedNumber = (cleanedNumber / 1e12).toFixed(2);
    unit = '  兆';
  } else if (cleanedNumber >= 1e8 && store.getters.lang === 'cn') {
    formattedNumber = (cleanedNumber / 1e8).toFixed(2);
    unit = '  亿';
  } else if (cleanedNumber >= 1e4 && store.getters.lang === 'cn') {
    formattedNumber = (cleanedNumber / 1e4).toFixed(2);
    unit = '  万';
  } else {
    formattedNumber = cleanedNumber.toFixed(2);
  }

  if (formattedNumber.endsWith('00')) {
    formattedNumber = parseInt(formattedNumber, 10).toString();
  } else if (formattedNumber.endsWith('0')) {
    formattedNumber = parseFloat(formattedNumber).toFixed(1);
  }

  return formattedNumber + unit;
};

export default convertUnit;
