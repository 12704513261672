<template>
  <a :href="object.link" class="home_newsCard" :class="{ 'skeleton_loading': isLoad }">
    <div class="home_newsCard--header">
      <img v-if="object.cover_url?.length !== 0" v-show="!isLoad" :src="object.cover_url" :alt="object.title">
    </div>
    <div class="home_newsCard--newsContent">
      <div class="home_newsCard--titleSection">
        <div class="home_newsCard--imgSection">
          <img v-show="!isLoad" :src="object.logo_url" alt="">
        </div>
        <div class="home_newsCard--newsTitleSection">
          <span class="home_card--title">
            {{ isLoad ? '' : object.title  }}
          </span>
        </div>
      </div>
    </div>
  </a>
</template>

<script>

export default {
  name: 'NewsCard',
  props: {
    object: {
      type: Object,
      required: true,
    },
    isLoad: {
      type: Boolean,
    },
  },
  computed: {
  },
  methods: {
  },
};
</script>
