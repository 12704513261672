import metaBg from '@/assets/meta/meta_bg.png';

const mapRentRouter = {
  path: 'rent/map',
  name: 'rentMap',
  component: () => import('@/views/map/MapView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.rent',
        pathName: 'rent',
      },
      {
        name: 'breadcrumbList.mapSearch',
        pathName: 'rentMap',
      },
    ],
    title: 'breadcrumbList.mapSearch',
    description: 'about.description1',
    bannerTitle: 'map.RentalMapSearch',
    bannerEnTitle: 'map.RentalMapSearchEn',
    image: metaBg,
  },
};
export default mapRentRouter;
