<template>
  <div class="home_sectionView">
    <h3 class="home_contentView--title">{{ $t('home.learnMoreAbout88go') }}</h3>
    <p class="home_about--text">{{ $t('home.aboutDes') }}</p>
    <div class="home_about--view">
      <div class="home_about--row">
        <div class="home_about--col">
          <div class="home_about--section">
            <div class="home_about--row">
              <div class="w_100 flex" style="padding: 0 16px">
                <div class="home_about--img">
                  <img class="home_about--img" src="@/assets/home/about_1.png" alt="">
                </div>
                <div class="home_about--textSection">
                  <h4 class="home_about--textSection--title">{{ $t('home.multipleListing') }}</h4>
                  <p class="home_about--textSection--text">{{ $t('home.listingDes') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home_about--col">
          <div class="home_about--section">
            <div class="home_about--row">
              <div class="w_100 flex" style="padding: 0 16px">
                <div class="home_about--img">
                  <img class="home_about--img" src="@/assets/home/about_2.png" alt="">
                </div>
                <div class="home_about--textSection">
                  <h4 class="home_about--textSection--title">{{ $t('home.noFee') }}</h4>
                  <p class="home_about--textSection--text">{{ $t('home.noFeeDes') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home_about--col">
          <div class="home_about--section">
            <div class="home_about--row">
              <div class="w_100 flex" style="padding: 0 16px">
                <div class="home_about--img">
                  <img class="home_about--img" src="@/assets/home/about_3.png" alt="">
                </div>
                <div class="home_about--textSection">
                  <h4 class="home_about--textSection--title">{{ $t('home.convenientTransactions') }}</h4>
                  <p class="home_about--textSection--text">{{ $t('home.convenientTransactionsDes') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AboutView',
  computed: {
    ...mapGetters(['isMobile', 'lang']),
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
};
</script>
