import service from '@/plugins/axios';

// 房屋
const root = '/a/a101';

// 精選房屋
export function bestHouse(params) {
  return service({
    url: `${root}/best_house`,
    method: 'get',
    params,
  });
}

// 適合房屋
export function suitableHouse(params) {
  return service({
    url: `${root}/suitable_house`,
    method: 'get',
    params,
  });
}

// 推薦房屋
export function recommendHouse(params) {
  return service({
    url: `${root}/recommend_house`,
    method: 'get',
    params,
  });
}

// 條件房屋
export function conditionHouse(params) {
  return service({
    url: `${root}/condition_house`,
    method: 'get',
    params,
  });
}

// 相似房屋
export function similarHouse(params) {
  return service({
    url: `${root}/similar_house`,
    method: 'get',
    params,
  });
}

// 房屋詳情
export function houseDetail(params) {
  return service({
    url: `${root}/house_detail`,
    method: 'get',
    params,
  });
}

// 今日新增房屋數
export function todayCount(params) {
  return service({
    url: `${root}/today_count`,
    method: 'get',
    params,
  });
}
