import metaBg from '@/assets/meta/meta_bg.png';

const filesRouter = {
  path: 'files',
  name: 'files',
  component: () => import('@/views/files/Files.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'files.download',
        pathName: 'files',
      },
    ],
    title: 'files.download',
    description: 'about.description1',
    image: metaBg,
    enTitle: 'files.downloadEn',
  },
};
export default filesRouter;
