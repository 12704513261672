import metaBg from '@/assets/meta/meta_bg.png';

const publishRouter = {
  path: 'publish',
  children: [
    {
      path: '',
      name: 'firstPublish',
      component: () => import('@/views/publish/FirstStep.vue'),
      meta: {
        title: 'publish.description',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
        restricted: 'guest',
      },
    },
    {
      path: 'rental/house',
      component: () => import('@/views/publish/PublishLayout.vue'),
      children: [
        {
          path: 'info',
          name: 'publishHouseRentalInfo',
          component: () => import('@/views/publish/house/SecondStep.vue'),
          props: true,
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishHouseRentalInfo',
              },
            ],
            title: 'publish.rental',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'plan/:id',
          name: 'publishHouseRentalPlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishHouseRentalPlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'again/plan/:id',
          name: 'publishHouseAgainRentalPlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishHouseAgainRentalPlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
      ],
    },
    {
      path: 'sale/house',
      component: () => import('@/views/publish/PublishLayout.vue'),
      props: true,
      children: [
        {
          path: 'info',
          name: 'publishHouseSaleInfo',
          component: () => import('@/views/publish/house/SecondStep.vue'),
          props: true,
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishHouseSaleInfo',
              },
            ],
            title: 'publish.sale',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'plan/:id',
          name: 'publishHouseSalePlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishHouseSalePlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'again/plan/:id',
          name: 'publishHouseAgainSalePlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishHouseAgainSalePlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
      ],
    },
    {
      path: 'rental/factory',
      component: () => import('@/views/publish/PublishLayout.vue'),
      children: [
        {
          path: 'info',
          name: 'publishFactoryRentalInfo',
          component: () => import('@/views/publish/factory/SecondStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishFactoryRentalInfo',
              },
            ],
            title: 'publish.factoryRental',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'plan/:id',
          name: 'publishFactoryRentalPlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishFactoryRentalPlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'again/plan/:id',
          name: 'publishFactoryAgainRentalPlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishFactoryAgainRentalPlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
      ],
    },
    {
      path: 'sale/factory',
      component: () => import('@/views/publish/PublishLayout.vue'),
      children: [
        {
          path: 'info',
          name: 'publishFactorySaleInfo',
          component: () => import('@/views/publish/factory/SecondStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishFactorySaleInfo',
              },
            ],
            title: 'publish.factorySale',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'plan/:id',
          name: 'publishFactorySalePlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishFactorySalePlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'again/plan/:id',
          name: 'publishFactoryAgainSalePlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishFactoryAgainSalePlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
      ],
    },
    {
      path: 'rental/land',
      component: () => import('@/views/publish/PublishLayout.vue'),
      children: [
        {
          path: 'info',
          name: 'publishLandRentalInfo',
          component: () => import('@/views/publish/land/SecondStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishLandRentalInfo',
              },
            ],
            title: 'publish.landRental',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'plan/:id',
          name: 'publishLandRentalPlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishLandRentalPlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'again/plan/:id',
          name: 'publishLandAgainRentalPlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishLandAgainRentalPlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
      ],
    },
    {
      path: 'sale/land',
      component: () => import('@/views/publish/PublishLayout.vue'),
      children: [
        {
          path: 'info',
          name: 'publishLandSaleInfo',
          component: () => import('@/views/publish/land/SecondStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishLandSaleInfo',
              },
            ],
            title: 'publish.landSale',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'plan/:id',
          name: 'publishLandSalePlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishLandSalePlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'again/plan/:id',
          name: 'publishLandAgainSalePlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishLandAgainSalePlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
      ],
    },
    {
      path: 'rental/office',
      component: () => import('@/views/publish/PublishLayout.vue'),
      children: [
        {
          path: 'info',
          name: 'publishOfficeRentalInfo',
          component: () => import('@/views/publish/office/SecondStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishOfficeRentalInfo',
              },
            ],
            title: 'publish.officeRental',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'plan/:id',
          name: 'publishOfficeRentalPlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishOfficeRentalPlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'again/plan/:id',
          name: 'publishOfficeAgainRentalPlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishOfficeAgainRentalPlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
      ],
    },
    {
      path: 'sale/office',
      component: () => import('@/views/publish/PublishLayout.vue'),
      children: [
        {
          path: 'info',
          name: 'publishOfficeSaleInfo',
          component: () => import('@/views/publish/office/SecondStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishOfficeSaleInfo',
              },
            ],
            title: 'publish.officeSale',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'plan/:id',
          name: 'publishOfficeSalePlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishOfficeSalePlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'again/plan/:id',
          name: 'publishOfficeAgainSalePlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishOfficeAgainSalePlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
      ],
    },
    {
      path: 'rental/shop',
      component: () => import('@/views/publish/PublishLayout.vue'),
      children: [
        {
          path: 'info',
          name: 'publishShopRentalInfo',
          component: () => import('@/views/publish/shop/SecondStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishShopRentalInfo',
              },
            ],
            title: 'publish.storeRental',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'plan/:id',
          name: 'publishShopRentalPlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishShopRentalPlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'again/plan/:id',
          name: 'publishShopAgainRentalPlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.rent',
                pathName: 'publishShopAgainRentalPlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
      ],
    },
    {
      path: 'sale/shop',
      component: () => import('@/views/publish/PublishLayout.vue'),
      children: [
        {
          path: 'info',
          name: 'publishShopSaleInfo',
          component: () => import('@/views/publish/shop/SecondStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishShopSaleInfo',
              },
            ],
            title: 'publish.storeSale',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'plan/:id',
          name: 'publishShopSalePlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishShopSalePlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
        {
          path: 'again/plan/:id',
          name: 'publishShopAgainSalePlan',
          component: () => import('@/views/publish/ThirdStep.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.publish',
                pathName: 'firstPublish',
              },
              {
                name: 'breadcrumbList.sale',
                pathName: 'publishShopAgainSalePlan',
              },
            ],
            title: 'publish.plan',
            description: 'about.description1',
            image: metaBg,
            scrollAnchor: true,
            requiredAuth: true,
            restricted: 'guest',
          },
        },
      ],
    },
    {
      path: 'rental/:category/success/:id',
      name: 'publishRentalSuccess',
      component: () => import('@/views/publish/FourthStep.vue'),
      meta: {
        title: 'publish.complete',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
        restricted: 'guest',
      },
    },
    {
      path: 'sale/:category/success/:id',
      name: 'publishSaleSuccess',
      component: () => import('@/views/publish/FourthStep.vue'),
      meta: {
        title: 'publish.complete',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
        restricted: 'guest',
      },
    },
  ],
};
export default publishRouter;
