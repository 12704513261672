import metaBg from '@/assets/meta/meta_bg.png';

const mapPreSaleRouter = {
  path: 'new-house/map',
  name: 'preSaleMap',
  component: () => import('@/views/map/MapView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.preSale',
        pathName: 'preSale',
      },
      {
        name: 'breadcrumbList.mapSearch',
        pathName: 'preSaleMap',
      },
    ],
    title: 'breadcrumbList.mapSearch',
    description: 'about.description1',
    bannerTitle: 'map.preSaleMapSearch',
    bannerEnTitle: 'map.prsSaleMapSearchEn',
    image: metaBg,
  },
};
export default mapPreSaleRouter;
