<template>
  <div class="be_rental">
    <Navbar />
    <router-view v-slot="{ Component, route }">
      <keep-alive :include="['RentView', 'MapView']">
        <component :is="Component" :key="route.name" />
      </keep-alive>
    </router-view>
    <Footer />
    <SideBar />
    <FloatAdDialog
      v-if="openFloat"
      :open="openFloat"
      :ad="windowAd"
      :is-load="windowLoading"
      @close="closeFloat"
    />
    <FloatAd
      v-if="open"
      :open="open"
      :ad="floatAd"
      :is-load="floatLoading"
      @close="close"
    />
    <SwitchIdentity
        :show="switchIdentity"
        :go="switchToRedirect"
        @close="closeSwitchIdentity"
        @redirect="redirectToPath"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { ad } from '@/plugins/api/a/a107';
import Navbar from '@/components/navbar/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';
import SideBar from '@/components/sideBar/SideBar.vue';
import FloatAdDialog from '@/components/dialog/FloatAdDialog.vue';
import FloatAd from '@/components/float/FloatAd.vue';
import { cityList } from '@/plugins/api/a/a105';
import { pointInfo } from '@/plugins/api/b/b109';
import { mapGetters } from 'vuex';
import SwitchIdentity from '@/views/memberCenter/page/common/home/SwitchIdentity.vue';

export default {
  name: 'LayoutView',
  components: {
    SwitchIdentity,
    Navbar,
    Footer,
    SideBar,
    FloatAdDialog,
    FloatAd,
  },
  metaInfo() {
    return {
      title: this.meta.title !== undefined ? `${this.meta.title} | 88go Mạng giao dịch` : '88go house | 88go Mạng giao dịch',
      meta: [
        { name: 'description', content: this.meta.description !== undefined ? this.meta.description : this.$t('description') },
        { property: 'og:url', content: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` },
        { property: 'og:title', content: this.meta.title !== undefined ? `${this.meta.title} | 88go Mạng giao dịch` : '88go house | 88go Mạng giao dịch' },
        { property: 'og:description', content: this.meta.description !== undefined ? this.meta.description : this.$t('description') },
        { property: 'og:image', content: this.meta.image !== undefined ? this.meta.image : '' },
      ],
    };
  },
  // provide() {
  //   return {
  //     reload: this.reload,
  //   };
  // },
  data() {
    return {
      isClosedWindowAd: false,
      isRouterAlive: true,
      openFloat: false,
      open: false,
      windowLoading: false,
      floatLoading: false,
      floatAd: {},
      windowAd: {},
      wrapperMap: new Map(),
    };
  },
  created() {
    this.$store.commit('UPDATE_META', {
      title: this.$t(this.$route.meta.title),
      description: this.$t(this.$route.meta.description),
      image: this.$route.meta.image,
    });
    this.load();
    if (this.$route.name === 'home') {
      this.getWindowAd();
    }
    this.getFloatAd();
  },
  watch: {
    $route(value) {
      if (value.name === 'home') {
        this.getWindowAd();
      }
      this.getFloatAd();
      this.$store.commit('UPDATE_META', {
        title: this.$t(value.meta.title),
        description: this.$t(value.meta.description),
        image: value.meta.image,
      });
    },
    lang(value, oldValue) {
      if (value !== oldValue) {
        this.load();
      }
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'lang',
      'isLogin',
      'meta',
      'identity',
      'closeFloatAd',
      'switchIdentity',
      'switchToRedirect',
    ]),
  },
  mounted() {
    const fullWidth = document.documentElement.clientWidth;
    if (fullWidth > 991) {
      this.$store.commit('UPDATE_DEVICE', false);
    } else {
      this.$store.commit('UPDATE_DEVICE', true);
    }
    window.onresize = () => (() => {
      const width = document.documentElement.clientWidth;
      if (width > 991) {
        this.$store.commit('UPDATE_DEVICE', false);
      } else {
        this.$store.commit('UPDATE_DEVICE', true);
      }
    })();
  },
  methods: {
    // reload() {
    //   this.isRouterAlive = false;
    //   this.$nextTick(() => {
    //     this.isRouterAlive = true;
    //   });
    // },
    async load() {
      await cityList({ lang: this.lang }).then((res) => {
        const { success, data } = res;
        if (success) {
          this.$store.commit('ADD_CITY', data.list);
          data.list.forEach((city) => {
            if (this.$route.name.indexOf('Map') !== -1) {
              if (city.value === this.$route.query.city) {
                const obj = {
                  lat: city.lat,
                  lng: city.lng,
                };
                this.$store.commit('UPDATE_MAP_CENTER', obj);
              }
            } else {
              const obj = {
                lat: 10.8230989,
                lng: 106.6296638,
              };
              this.$store.commit('UPDATE_MAP_CENTER', obj);
            }
          });
        }
      });
      if (!this.isLogin) {
        return;
      }
      await pointInfo().then((res) => {
        const { success, data } = res;
        if (success) {
          this.$store.commit('GET_POINTS', data.point);
        }
      });
    },
    closeFloat() {
      this.openFloat = false;
      this.isClosedWindowAd = true;
    },
    close() {
      this.open = false;
      this.floatAd = {};
      this.$store.commit('UPDATE_FLOAT_AD', false);
    },
    checkRouteName(name) {
      switch (name) {
        case 'home':
          if (this.windowAd.existed) {
            this.openFloat = true;
          }
          break;
        default:
          break;
      }
      if (this.floatAd.existed) {
        this.open = true;
      }
    },
    getFloatAd() {
      if (!this.closeFloatAd) return;
      if (Object.keys(this.floatAd).length > 0) return;
      // this.floatAd = {};
      this.floatLoading = true;
      // TODO: type (1: 網頁版, 2: 手機版)  RWD 判斷
      const query = {
        type: 1,
        block: 3,
      };
      ad(query).then((res) => {
        const { success, data } = res;
        if (success) {
          this.floatAd = data;
        }
        this.checkRouteName(this.$route.name);
        this.floatLoading = false;
      }).catch(() => {
        this.floatLoading = false;
      });
    },
    getWindowAd() {
      if (this.isClosedWindowAd) return;
      this.windowAd = {};
      this.windowLoading = true;
      // TODO: type (1: 網頁版, 2: 手機版)  RWD 判斷
      const query = {
        type: 1,
        block: 2,
      };
      ad(query).then((res) => {
        const { success, data } = res;
        if (success) {
          this.windowAd = data;
          this.checkRouteName(this.$route.name);
        }
        this.windowLoading = false;
      }).catch(() => {
        this.windowLoading = false;
      });
    },
    redirectToPath(obj) {
      if (obj.name === 'firstPublish') {
        this.$router.push(obj);
      } else {
        this.$router.push({
          name: `${this.identity}${obj.name}`,
          params: obj.params,
          query: obj.query,
        });
      }
    },
    closeSwitchIdentity() {
      this.$store.commit('UPDATE_SWITCH_IDENTITY', false);
      this.$store.commit('UPDATE_SWITCH_TO_REDIRECT', {});
    },
  },
};
</script>
