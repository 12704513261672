const landRentDetailRouter = {
  path: 'land/rent/:id',
  name: 'landRentDetail',
  component: () => import('@/views/detail/land/DetailView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'breadcrumbList.land',
        pathName: 'land',
      },
      {
        name: 'breadcrumbList.rent',
        pathName: 'land',
      },
      {
        name: ':id',
        pathName: 'landRentDetail',
      },
    ],
    title: '',
    description: '',
    image: '',
  },
};
export default landRentDetailRouter;
