<template>
  <div class="home_sectionView news">
    <h3 class="home_contentView--title">{{ $t('home.news') }}</h3>
    <div class="home_contentView--swiperView">
      <button
          class="avatar_button--md swiper-button-prev"
          id="news-prev"
          @click.stop="prevEl"
      >
        <SvgIcon
            icon-class="arrow_left"
            class-name="form_imgView--icon"
        />
      </button>
      <div class="home_contentView--swiper">
        <swiper
            v-if="news.length > 0"
            :navigation="{
              nextEl: '#news-next',
              prevEl: '#news-prev',
            }"
            :pagination="{
              clickable: true,
            }"
            :modules="modules"
            :slides-per-view="isMobile ? 1 : 4"
            :space-between="12"
        >
          <swiper-slide v-for="item in news" :key="item.id">
            <NewsCard :object="item" :is-load="loading" />
          </swiper-slide>
        </swiper>
        <span
            v-else
            class="home_contentView--nodata"
        >{{ nodataText }}</span>
      </div>
      <button
          class="avatar_button--md swiper-button-next"
          id="news-next"
          @click="nextEl"
      >
        <SvgIcon
            icon-class="arrow_right"
            class-name="form_imgView--icon"
        />
      </button>
    </div>
  </div>
</template>

<script>
import NewsCard from '@/components/card/NewsCard.vue';
import { newsList } from '@/plugins/api/a/a112';
import { mapGetters } from 'vuex';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  name: 'NewsView',
  computed: {
    ...mapGetters(['isMobile', 'lang', 'news']),
  },
  components: {
    Swiper,
    SwiperSlide,
    NewsCard,
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
  data() {
    return {
      loading: false,
      nodataText: '',
      swiperOption: {
        pagination: '.swiper-pagination',
        clickable: true,
      },
    };
  },
  created() {
    this.getNewsList();
  },
  methods: {
    getNewsList() {
      if (this.news.length > 0) return;
      this.loading = true;
      newsList().then((res) => {
        const { success, data } = res;
        if (success) {
          this.$store.commit('UPDATE_NEWS', data);
          if (data.length === 0) {
            this.nodataText = this.$t('noDataText.noData');
          }
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
  },
};
</script>
