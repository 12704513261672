<template>
  <div class="custom_checkbox" @click="chooseOption(option)">
    <div class="custom_checkbox--square" :class="{ 'choose': option.choose, 'disabled': disabled }"></div>
    <div class="custom_checkbox--labelView">
      <span class="custom_checkbox--label" :style="{ 'color': labelColor }">{{ option.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultCheckBox',
  props: {
    option: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    labelColor: {
      type: String,
      default: () => '',
    },
  },
  emits: ['change'],
  data() {
    return {
    };
  },
  methods: {
    chooseOption(item) {
      if (this.disabled) return;
      item.choose = !item.choose;
      this.$emit('change', item);
    },
  },
};
</script>
