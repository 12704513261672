import axios from 'axios';
import { useToast } from 'vue-toast-notification';
import { useStorage } from 'vue3-storage';
import store from '../store';
import router from '../router';
import i18n from '@/language';

const storage = useStorage();

const uploadService = axios.create({
  baseURL: `${process.env.VUE_APP_UPLOAD_API}`, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 40000, // request timeout
});

// request interceptor
uploadService.interceptors.request.use(
  (config) => {
    const accessToken = storage.getStorageSync('token');
    if (accessToken) {
      config.headers.Authorization = accessToken;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);

// response interceptor
uploadService.interceptors.response.use(
  (response) => {
    const { data } = response;
    const { code } = data;
    if (code) {
      const $toast = useToast();
      if (code === 1001) {
        $toast.error(i18n.global.t('noticeText.loginAgain'));
        store.dispatch('logOut');
        router.push({ name: 'login' });
        return data;
      }
      if (code === 1002) {
        $toast.error(i18n.global.t('noticeText.loginAgain'));
        store.dispatch('logOut');
        router.push({ name: 'login' });
        return data;
      }
      if (code !== 3015 && code !== 3016) {
        $toast.error(i18n.global.t(`code.${code}`));
      }
    }
    return data;
  },
  (error) => Promise.reject(error),
);

export { uploadService };

// create an axios instance
const service = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API}`, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 40000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    const accessToken = storage.getStorageSync('token');
    if (accessToken) {
      config.headers.Authorization = accessToken;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const { data } = response;
    const { code } = data;
    if (code) {
      const $toast = useToast();
      if (code === 1001) {
        $toast.error(i18n.global.t('noticeText.loginAgain'));
        store.dispatch('logOut');
        router.push({ name: 'login' });
        return data;
      }
      if (code === 1002) {
        $toast.error(i18n.global.t('noticeText.loginAgain'));
        store.dispatch('logOut');
        router.push({ name: 'login' });
        return data;
      }
      if (code !== 3015 && code !== 3016) {
        $toast.error(i18n.global.t(`code.${code}`));
      }
    }
    return data;
  },
  (error) => Promise.reject(error),
);

export default service;
