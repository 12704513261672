<template>
  <div class="home_sectionView--match">
    <div class="home_searchItems">
      <div class="col-12">
        <div class="home_match">
          <div class="home_match--header">
            <div class="home_match--leftView"></div>
            <div class="home_match--top">
              <div class="home_match--titleView">
                <h4 class="home_match--title">{{ $t('home.searchObject') }}</h4>
                <h5 class="home_match--subTitle">{{ $t('home.explainYourNeed') }}</h5>
              </div>
            </div>
            <div class="home_match--img">
              <img v-show="!isMobile" src="@/assets/home/match.png" alt="match">
              <img v-show="isMobile" src="@/assets/home/match_sm.png" alt="match">
            </div>
          </div>
          <div class="home_match--body">
            <div class="home_match--leftView"></div>
            <div class="home_match--rightView">
              <div class="home_match--form">
                <div class="home_match--formView">
                  <ul v-show="showTab()" class="home_match--formView--header">
                    <li
                        v-for="tab in tabs"
                        :key="tab.id"
                        class="home_match--formView--header--tab"
                        :class="{ 'choose': tab.id === form.type }"
                        @click="chooseTab(tab.id)"
                    >
                      {{ $t(tab.label) }}
                      <div class="home_match--formView--header--tab--line"></div>
                    </li>
                  </ul>
                  <div class="home_match--formView--content">
                    <LoaderView :visible="loading" />
                    <template v-if="!loading">
                      <div v-if="step !== 6" class="home_match--formView--titleSection">
                        <h6 class="home_match--formView--title">
                          <span class="focus">Q{{ step }}</span>
                          <span class="textColor"> / {{ allStep }}</span>
                          {{ questionTitle }}
                        </h6>
                        <p class="home_match--formView--text">{{ questionDescription }}</p>
                      </div>
                      <div class="home_match--formView--form">
                        <template v-if="step === 1">
                          <div class="home_match--formView--form--step1">
                            <div class="home_match--formView--form--step1--input">
                              <DefaultInput
                                  :placeholder="`${$t('home.minimumBudget')} (VND)`"
                                  :value="form.min_price"
                                  id="min_price"
                                  @change-value="(value) => form.min_price = Number(value)"
                              />
                            </div>
                            <div style="width: 24px; text-align: center">
                              <p class="home_match--formView--text">-</p>
                            </div>
                            <div class="home_match--formView--form--step1--input">
                              <DefaultInput
                                  :placeholder="`${$t('home.maximumBudget')} (VND)`"
                                  :value="form.max_price"
                                  id="max_price"
                                  @change-value="(value) => form.max_price = Number(value)"
                              />
                            </div>
                          </div>
                        </template>
                        <template v-else-if="step === 2">
                          <div class="home_match--formView--form--step2">
                            <div class="home_match--formView--row">
                              <template v-if="type === 1">
                                <div v-for="option in rentHouseCategory" :key="option.id" class="home_match--formView--form--step2--col3">
                                  <button
                                      type="button"
                                      class="home_match--formView--form--step2--btn"
                                      :class="{ 'choose': form.category_list.indexOf(option.id) !== -1 }"
                                      @click="chooseCategory(option.id)"
                                  >
                                    <SvgIcon v-show="form.category_list.indexOf(option.id) !== -1" icon-class="check" />
                                    {{ $t(option.name) }}
                                  </button>
                                </div>
                              </template>
                              <template v-else-if="type === 2">
                                <div v-for="option in saleHouseCategory" :key="option.id" class="home_match--formView--form--step2--col3">
                                  <button
                                      type="button"
                                      class="home_match--formView--form--step2--btn"
                                      :class="{ 'choose': form.category_list.indexOf(option.id) !== -1 }"
                                      @click="chooseCategory(option.id)"
                                  >
                                    <SvgIcon v-show="form.category_list.indexOf(option.id) !== -1" icon-class="check" />
                                    {{ $t(option.name) }}
                                  </button>
                                </div>
                              </template>
                              <template v-else-if="type === 3">
                                <div v-for="option in caseType" :key="option.id" class="home_match--formView--form--step2--col3">
                                  <button
                                      type="button"
                                      class="home_match--formView--form--step2--btn"
                                      :class="{ 'choose': form.category_list.indexOf(option.id) !== -1 }"
                                      @click="chooseCategory(option.id)"
                                  >
                                    <SvgIcon v-show="form.category_list.indexOf(option.id) !== -1" icon-class="check" />
                                    {{ $t(option.name) }}
                                  </button>
                                </div>
                              </template>
                              <template v-else-if="type === 4">
                                <div v-for="option in factoryType" :key="option.id" class="home_match--formView--form--step2--col3">
                                  <button
                                      type="button"
                                      class="home_match--formView--form--step2--btn"
                                      :class="{ 'choose': form.type_list.indexOf(option.id) !== -1 }"
                                      @click="chooseType(option.id)"
                                  >
                                    <SvgIcon v-show="form.type_list.indexOf(option.id) !== -1" icon-class="check" />
                                    {{ $t(option.name) }}
                                  </button>
                                </div>
                              </template>
                              <template v-else-if="type === 5">
                                <div v-for="option in landType" :key="option.id" class="home_match--formView--form--step2--col3">
                                  <button
                                      type="button"
                                      class="home_match--formView--form--step2--btn"
                                      :class="{ 'choose': form.type_list.indexOf(option.id) !== -1 }"
                                      @click="chooseType(option.id)"
                                  >
                                    <SvgIcon v-show="form.type_list.indexOf(option.id) !== -1" icon-class="check" />
                                    {{ $t(option.name) }}
                                  </button>
                                </div>
                              </template>
                              <template v-else-if="type === 6">
                                <div v-for="option in officeCategory" :key="option.id" class="home_match--formView--form--step2--col3">
                                  <button
                                      type="button"
                                      class="home_match--formView--form--step2--btn"
                                      :class="{ 'choose': form.category_list.indexOf(option.id) !== -1 }"
                                      @click="chooseCategory(option.id)"
                                  >
                                    <SvgIcon v-show="form.category_list.indexOf(option.id) !== -1" icon-class="check" />
                                    {{ $t(option.name) }}
                                  </button>
                                </div>
                              </template>
                              <template v-else-if="type === 7">
                                <div v-for="option in shopCategory" :key="option.id" class="home_match--formView--form--step2--col3">
                                  <button
                                      type="button"
                                      class="home_match--formView--form--step2--btn"
                                      :class="{ 'choose': form.category_list.indexOf(option.id) !== -1 }"
                                      @click="chooseCategory(option.id)"
                                  >
                                    <SvgIcon v-show="form.category_list.indexOf(option.id) !== -1" icon-class="check" />
                                    {{ $t(option.name) }}
                                  </button>
                                </div>
                              </template>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="step === 3">
                          <div v-if="type === 1 || type === 2 || type === 3" class="home_match--formView--form--step2">
                            <div class="home_match--formView--row">
                              <div v-for="option in rentHouseType" :key="option.id" class="home_match--formView--form--step2--col3">
                                <button type="button" class="home_match--formView--form--step2--btn" :class="{ 'choose': form.type_list.indexOf(option.id) !== -1 }" @click="chooseType(option.id)">
                                  <SvgIcon v-show="form.type_list.indexOf(option.id) !== -1" icon-class="check" />
                                  {{ $t(option.name) }}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="type === 6 || type === 7" class="home_match--formView--form--step2">
                            <div class="home_match--formView--row">
                              <div v-for="option in officeType" :key="option.id" class="home_match--formView--form--step2--col3">
                                <button type="button" class="home_match--formView--form--step2--btn" :class="{ 'choose': form.type_list.indexOf(option.id) !== -1 }" @click="chooseType(option.id)">
                                  <SvgIcon v-show="form.type_list.indexOf(option.id) !== -1" icon-class="check" />
                                  {{ $t(option.name) }}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div v-else class="home_match--formView--form--step1">
                            <div class="home_match--formView--form--step1--input">
                              <DefaultInput
                                  :placeholder="`${$t('home.minimumArae')} (m2)`"
                                  :value="form.min_square"
                                  id="min_square"
                                  @change-value="(value) => form.min_square = Number(value)"
                              />
                            </div>
                            <div style="width: 24px; text-align: center">
                              <p class="home_match--formView--text">-</p>
                            </div>
                            <div class="home_match--formView--form--step1--input">
                              <DefaultInput
                                  :placeholder="`${$t('home.maximumArae')} (m2)`"
                                  :value="form.max_square"
                                  id="max_square"
                                  @change-value="(value) => form.max_square = Number(value)"
                              />
                            </div>
                          </div>
                        </template>
                        <template v-else-if="step === 4">
                          <div v-if="type === 1 || type === 2 || type === 3" class="home_match--formView--form--step1">
                            <div class="home_match--formView--form--step1--input">
                              <DefaultInput
                                  :placeholder="`${$t('home.minRoom')}`"
                                  :value="form.min_room"
                                  id="min_room"
                                  @change-value="(value) => form.min_room = Number(value)"
                              />
                            </div>
                            <div style="width: 24px; text-align: center">
                              <p class="home_match--formView--text">-</p>
                            </div>
                            <div class="home_match--formView--form--step1--input">
                              <DefaultInput
                                  :placeholder="`${$t('home.maxRoom')}`"
                                  :value="form.max_room"
                                  id="max_room"
                                  @change-value="(value) => form.max_room = Number(value)"
                              />
                            </div>
                          </div>
                          <div v-else-if="type === 6 || type === 7" class="home_match--formView--form--step1">
                            <div class="home_match--formView--form--step1--input">
                              <DefaultInput
                                  :placeholder="`${$t('home.minimumArae')} (m2)`"
                                  :value="form.min_square"
                                  id="min_square"
                                  @change-value="(value) => form.min_square = Number(value)"
                              />
                            </div>
                            <div style="width: 24px; text-align: center">
                              <p class="home_match--formView--text">-</p>
                            </div>
                            <div class="home_match--formView--form--step1--input">
                              <DefaultInput
                                  :placeholder="`${$t('home.maximumArae')} (m2)`"
                                  :value="form.max_square"
                                  id="max_square"
                                  @change-value="(value) => form.max_square = Number(value)"
                              />
                            </div>
                          </div>
                          <div v-else class="home_match--formView--form--step1">
                            <div class="home_match--formView--row">
                              <div class="home_match--formView--form--step1--city" v-for="(num, key) in 3" :key="key">
                                <div style="width: 32px; padding: 0 8px;">
                                  <p class="home_match--formView--text bold">{{ key + 1 }}.</p>
                                </div>
                                <div class="home_match--formView--form--step1--selectView">
                                  <div class="home_match--formView--form--step1--select">
                                    <DefaultSelect
                                        :list="cityList"
                                        :value="form.city_list[key]?.city"
                                        :placeholder="$t('search.city')"
                                        :sort="key"
                                        @choose="chooseCity"
                                    />
                                  </div>
                                  <div class="home_match--formView--form--step1--select">
                                    <DefaultSelect
                                        :list="getArea(key)"
                                        :placeholder="$t('search.area')"
                                        :value="form.city_list[key]?.arr"
                                        multiple
                                        :sort="key"
                                        @choose="chooseArea"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="step === 5">
                          <div class="home_match--formView--form--step1">
                            <div class="home_match--formView--row">
                              <div class="home_match--formView--form--step1--city" v-for="(num, key) in 3" :key="num">
                                <div style="width: 32px; padding: 0 8px;">
                                  <p class="home_match--formView--text bold">{{ key + 1 }}.</p>
                                </div>
                                <div class="home_match--formView--form--step1--selectView">
                                  <div class="home_match--formView--form--step1--select">
                                    <DefaultSelect
                                        :list="cityList"
                                        :value="form.city_list[key]?.city"
                                        :placeholder="$t('search.city')"
                                        :sort="key"
                                        @choose="chooseCity"
                                    />
                                  </div>
                                  <div class="home_match--formView--form--step1--select">
                                    <DefaultSelect
                                        :list="getArea(key)"
                                        :placeholder="$t('search.area')"
                                        :value="form.city_list[key]?.arr"
                                        multiple
                                        :sort="key"
                                        @choose="chooseArea"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="home_match--formView--form--final">
                            <h6 class="home_match--formView--title neutral-700" style="margin-bottom: 4px">{{ resultTitle }}</h6>
                            <div class="home_match--formView--form--final--row">
                              <ul class="w_100 flex_column">
                                <li v-for="(city, index) in form.city_list" :key="index" class="home_match--formView--form--final--col">
                                  <div class="w_100 flex_column">
                                    <p class="home_match--cover--body--text bold focus">{{ city.city_name }}</p>
                                    <div class="home_match--formView--form--final--areaView">
                                      <p class="home_match--cover--body--text focus">{{ transferCityText(city.name_arr) }}</p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div class="home_match--formView--form--final--row">
                              <div class="home_match--formView--form--final--col">
                                <p class="home_match--formView--text neutral-500">
                                  {{ form.min_price !== null ? `${convert(form.min_price)}` : '0' }} - {{ form.max_price !== null ? `${convert(form.max_price)}` : '' }}
                                </p>
                              </div>
                            </div>
                            <div class="home_match--formView--form--final--row">
                              <ul class="w_100 flex align_center">
                                <li v-for="(category, index) in form.category_list" :key="index" class="home_match--formView--form--final--col">
                                  <p class="home_match--formView--text neutral-600">
                                    {{ convertCategory(category) }}
                                  </p>
                                </li>
                              </ul>
                            </div>
                            <div class="home_match--formView--form--final--row">
                              <ul class="w_100 flex align_center">
                                <li v-for="(type, index) in form.type_list" :key="index" class="home_match--formView--form--final--col">
                                  <p class="home_match--formView--text neutral-600">
                                    {{ convertType(type) }}
                                  </p>
                                </li>
                              </ul>
                            </div>
                            <div class="home_match--formView--form--final--row">
                              <div class="home_match--formView--form--final--col">
                                <p class="home_match--formView--text neutral-500">
                                  {{ convertSquareOrRoom() }}
                                </p>
                              </div>
                            </div>
                            <div class="home_match--formView--form--final--row">
                              <div class="home_match--formView--form--final--col w_100 flex justify_between align_center">
                                <p class="home_match--formView--text">{{ form.updated_at }}</p>
                                <a href="#" class="flex align_center" @click.prevent="refresh">
                                  <SvgIcon icon-class="refresh" />
                                  <span class="home_match--cover--body--text" style="padding-left: 4px">{{ $t('home.reset') }}</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                      <div v-if="step !== 6" class="home_match--formView--btnGroup">
                        <div class="home_match--formView--btn">
                          <button
                              class="w_100 more_button"
                              :class="{ 'disabled_button' : btnDisabled }"
                              type="button"
                              @click="next"
                          >
                            {{ step === allStep ? $t('dialog.submit') : $t('publish.form.next') }}
                          </button>
                        </div>
                        <div class="home_match--formView--btn text_center">
                          <a
                              v-show="step !== 1 && step !== 6"
                              href="#"
                              class="home_match--formView--text neutral-500"
                              :class="{ 'neutral-300': btnDisabled }"
                              @click.prevent="prev"
                          >
                            {{ $t('home.prev') }}
                          </a>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="home_match--form--bottom">
                  <p v-show="step === 6">{{ $t('home.idealSlogan') }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-show="!isLogin || identity !== 'guest'" class="home_match--cover">
            <div class="home_match--cover--container">
              <div class="w_100 h_100 flex flex_column justify_center">
                <div class="home_match--cover--body">
                  <p class="home_match--cover--body--text">{{ !isLogin ? $t('home.loginRequire') : $t('home.feature') }}</p>
                  <div v-show="identity !== 'sub'" class="home_match--formView--btnGroup">
                    <div class="home_match--formView--btn">
                      <button
                          class="w_100 more_button"
                          type="button"
                          @click.stop="loginOrSwitch"
                      >
                        {{ !isLogin ? $t('login.login') : $t('memberCenter.switchIdentity') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { pref, updatePref } from '@/plugins/api/b/b105';
import DefaultInput from '@/components/input/DefaultInput.vue';
import DefaultSelect from '@/components/select/DefaultSelect.vue';
import LoaderView from '@/components/loader/LoaderView.vue';
import convertPrice from '@/lib/convert';
import convertUnit from '@/lib/convertUnit';
import { mapGetters } from 'vuex';
import { areaList } from '@/plugins/api/a/a105';

export default {
  name: 'MatchingBanner',
  components: {
    DefaultInput,
    DefaultSelect,
    LoaderView,
  },
  props: {
    type: {
      type: Number,
    },
  },
  data() {
    return {
      tabs: [
        {
          id: 3,
          label: 'home.rentTab',
        },
        {
          id: 4,
          label: 'home.saleTab',
        },
      ],
      allStep: 5,
      step: 1,
      form: {
        min_price: 0,
        max_price: 0,
        category_list: [],
        type_list: [],
        max_room: 0,
        min_room: 0,
        city_list: [
          {
            city: null,
            arr: [],
          },
        ],
        max_square: 0,
        min_square: 0,
        type: 1,
      },
      loading: false,
      rentHouseCategory: [
        {
          id: 2,
          name: 'house_info.house_category.2',
        },
        {
          id: 3,
          name: 'house_info.house_category.3',
        },
        {
          id: 4,
          name: 'house_info.house_category.4',
        },
      ],
      saleHouseCategory: [
        {
          id: 5,
          name: 'house_info.house_category.5',
        },
        {
          id: 1,
          name: 'house_info.house_category.1',
        },
        {
          id: 6,
          name: 'house_info.house_category.6',
        },
        {
          id: 7,
          name: 'house_info.house_category.7',
        },
      ],
      rentHouseType: [
        {
          id: 1,
          name: 'house_info.house_type.1',
        },
        {
          id: 2,
          name: 'house_info.house_type.2',
        },
        {
          id: 3,
          name: 'house_info.house_type.3',
        },
        {
          id: 4,
          name: 'house_info.house_type.4',
        },
      ],
      caseType: [
        {
          id: 1,
          name: 'new_house.case_type.1',
        },
        {
          id: 2,
          name: 'new_house.case_type.2',
        },
        {
          id: 3,
          name: 'new_house.case_type.3',
        },
      ],
      factoryType: [
        {
          id: 1,
          name: 'factory.type.1',
        },
        {
          id: 2,
          name: 'factory.type.2',
        },
        {
          id: 3,
          name: 'factory.type.3',
        },
        {
          id: 4,
          name: 'factory.type.4',
        },
      ],
      landType: [
        {
          id: 1,
          name: 'land.type.1',
        },
        {
          id: 2,
          name: 'land.type.2',
        },
        {
          id: 3,
          name: 'land.type.3',
        },
        {
          id: 4,
          name: 'land.type.4',
        },
        {
          id: 5,
          name: 'land.type.5',
        },
        {
          id: 6,
          name: 'land.type.6',
        },
      ],
      officeCategory: [
        {
          id: 1,
          name: 'office.category.1',
        },
        {
          id: 2,
          name: 'office.category.2',
        },
      ],
      officeType: [
        {
          id: 1,
          name: 'office.type.1',
        },
        {
          id: 2,
          name: 'office.type.2',
        },
        {
          id: 3,
          name: 'office.type.3',
        },
        {
          id: 4,
          name: 'office.type.4',
        },
        {
          id: 5,
          name: 'office.type.5',
        },
      ],
      shopCategory: [
        {
          id: 1,
          name: 'shop.category.1',
        },
        {
          id: 2,
          name: 'shop.category.2',
        },
        {
          id: 3,
          name: 'shop.category.3',
        },
        {
          id: 4,
          name: 'shop.category.4',
        },
        {
          id: 5,
          name: 'shop.category.5',
        },
        {
          id: 6,
          name: 'shop.category.6',
        },
        {
          id: 7,
          name: 'shop.category.7',
        },
        {
          id: 8,
          name: 'shop.category.8',
        },
      ],
      area1: [],
      area2: [],
      area3: [],
      btnDisabled: false,
    };
  },
  watch: {
    type(value, oldValue) {
      if (value !== oldValue) {
        this.step = 1;
        switch (this.type) {
          case 1:
            this.allStep = 5;
            this.form.type = 1;
            break;
          case 2:
            this.allStep = 5;
            this.form.type = 2;
            break;
          case 3:
            this.allStep = 5;
            this.form.type = 11;
            break;
          case 4:
            this.allStep = 4;
            this.form.type = 3;
            this.tabs = [
              {
                id: 3,
                label: 'home.rentTab',
              },
              {
                id: 4,
                label: 'home.saleTab',
              },
            ];
            break;
          case 5:
            this.allStep = 4;
            this.form.type = 5;
            this.tabs = [
              {
                id: 5,
                label: 'home.rentTab',
              },
              {
                id: 6,
                label: 'home.saleTab',
              },
            ];
            break;
          case 6:
            this.allStep = 5;
            this.form.type = 7;
            this.tabs = [
              {
                id: 7,
                label: 'home.rentTab',
              },
              {
                id: 8,
                label: 'home.saleTab',
              },
            ];
            break;
          case 7:
            this.allStep = 5;
            this.form.type = 9;
            this.tabs = [
              {
                id: 9,
                label: 'home.rentTab',
              },
              {
                id: 10,
                label: 'home.saleTab',
              },
            ];
            break;
          default:
            this.allStep = 5;
            break;
        }
        if (this.identity !== 'guest') return;
        this.getData();
      }
    },
    isLogin() {
      this.form = {
        min_price: 0,
        max_price: 0,
        category_list: [],
        type_list: [],
        max_room: 0,
        min_room: 0,
        city_list: [
          {
            city: null,
            area: null,
          },
        ],
        max_square: 0,
        min_square: 0,
        type: this.form.type,
      };
    },
    identity(value) {
      if (value !== 'guest') {
        this.form = {
          min_price: 0,
          max_price: 0,
          category_list: [],
          type_list: [],
          max_room: 0,
          min_room: 0,
          city_list: [],
          max_square: 0,
          min_square: 0,
          type: this.form.type,
        };
        this.step = 1;
      } else {
        this.getData();
      }
    },
  },
  computed: {
    ...mapGetters(['isLogin', 'identity', 'cityList', 'lang', 'isMobile']),
    questionTitle() {
      switch (this.form.type) {
        case 1:
          if (this.step === 1) {
            return this.$t('home.rentHouseQuestion1');
          }
          if (this.step === 2) {
            return this.$t('home.rentHouseQuestion2');
          }
          if (this.step === 3) {
            return this.$t('home.rentHouseQuestion3');
          }
          if (this.step === 4) {
            return this.$t('home.rentHouseQuestion4');
          }
          if (this.step === 5) {
            return this.$t('home.rentHouseQuestion5');
          }
          return '';
        case 2:
          if (this.step === 1) {
            return this.$t('home.saleHouseQuestion1');
          }
          if (this.step === 2) {
            return this.$t('home.rentHouseQuestion2');
          }
          if (this.step === 3) {
            return this.$t('home.rentHouseQuestion3');
          }
          if (this.step === 4) {
            return this.$t('home.rentHouseQuestion4');
          }
          if (this.step === 5) {
            return this.$t('home.rentHouseQuestion5');
          }
          return '';
        case 3:
          if (this.step === 1) {
            return this.$t('home.factoryRentQuestion1');
          }
          if (this.step === 2) {
            return this.$t('home.factoryQuestion2');
          }
          if (this.step === 3) {
            return this.$t('home.squareQuestion');
          }
          if (this.step === 4) {
            return this.$t('home.rentHouseQuestion5');
          }
          return '';
        case 4:
          if (this.step === 1) {
            return this.$t('home.factorySaleQuestion1');
          }
          if (this.step === 2) {
            return this.$t('home.factoryQuestion2');
          }
          if (this.step === 3) {
            return this.$t('home.squareQuestion');
          }
          if (this.step === 4) {
            return this.$t('home.rentHouseQuestion5');
          }
          return '';
        case 5:
          if (this.step === 1) {
            return this.$t('home.landRentQuestion1');
          }
          if (this.step === 2) {
            return this.$t('home.landQuestion2');
          }
          if (this.step === 3) {
            return this.$t('home.squareQuestion');
          }
          if (this.step === 4) {
            return this.$t('home.rentHouseQuestion5');
          }
          return '';
        case 6:
          if (this.step === 1) {
            return this.$t('home.landSaleQuestion1');
          }
          if (this.step === 2) {
            return this.$t('home.landQuestion2');
          }
          if (this.step === 3) {
            return this.$t('home.squareQuestion');
          }
          if (this.step === 4) {
            return this.$t('home.rentHouseQuestion5');
          }
          return '';
        case 7:
          if (this.step === 1) {
            return this.$t('home.officeRentQuestion1');
          }
          if (this.step === 2) {
            return this.$t('home.officeQuestion2');
          }
          if (this.step === 3) {
            return this.$t('home.officeQuestion3');
          }
          if (this.step === 4) {
            return this.$t('home.squareQuestion');
          }
          if (this.step === 5) {
            return this.$t('home.rentHouseQuestion5');
          }
          return '';
        case 8:
          if (this.step === 1) {
            return this.$t('home.officeSaleQuestion1');
          }
          if (this.step === 2) {
            return this.$t('home.officeQuestion2');
          }
          if (this.step === 3) {
            return this.$t('home.officeQuestion3');
          }
          if (this.step === 4) {
            return this.$t('home.squareQuestion');
          }
          if (this.step === 5) {
            return this.$t('home.rentHouseQuestion5');
          }
          return '';
        case 9:
          if (this.step === 1) {
            return this.$t('home.shopRentQuestion1');
          }
          if (this.step === 2) {
            return this.$t('home.shopQuestion2');
          }
          if (this.step === 3) {
            return this.$t('home.shopQuestion3');
          }
          if (this.step === 4) {
            return this.$t('home.squareQuestion');
          }
          if (this.step === 5) {
            return this.$t('home.rentHouseQuestion5');
          }
          return '';
        case 10:
          if (this.step === 1) {
            return this.$t('home.shopSaleQuestion1');
          }
          if (this.step === 2) {
            return this.$t('home.shopQuestion2');
          }
          if (this.step === 3) {
            return this.$t('home.shopQuestion3');
          }
          if (this.step === 4) {
            return this.$t('home.squareQuestion');
          }
          if (this.step === 5) {
            return this.$t('home.rentHouseQuestion5');
          }
          return '';
        case 11:
          if (this.step === 1) {
            return this.$t('home.saleHouseQuestion1');
          }
          if (this.step === 2) {
            return this.$t('home.newHouseQuestion2');
          }
          if (this.step === 3) {
            return this.$t('home.rentHouseQuestion3');
          }
          if (this.step === 4) {
            return this.$t('home.rentHouseQuestion4');
          }
          if (this.step === 5) {
            return this.$t('home.rentHouseQuestion5');
          }
          return '';
        default:
          return '';
      }
    },
    questionDescription() {
      switch (this.type) {
        case 1:
          if (this.step === 1) {
            return this.$t('home.rentHouseQuestion1Des');
          }
          if (this.step === 2) {
            return this.$t('home.rentHouseQuestion2Des');
          }
          if (this.step === 3) {
            return this.$t('home.rentHouseQuestion2Des');
          }
          if (this.step === 4) {
            return this.$t('home.rentHouseQuestion4Des');
          }
          if (this.step === 5) {
            return this.$t('home.rentHouseQuestion5es');
          }
          return '';
        case 2:
          if (this.step === 1) {
            return this.$t('home.rentHouseQuestion1Des');
          }
          if (this.step === 2) {
            return this.$t('home.rentHouseQuestion2Des');
          }
          if (this.step === 3) {
            return this.$t('home.rentHouseQuestion2Des');
          }
          if (this.step === 4) {
            return this.$t('home.rentHouseQuestion4Des');
          }
          if (this.step === 5) {
            return this.$t('home.rentHouseQuestion5es');
          }
          return '';
        case 3:
          if (this.step === 1) {
            return this.$t('home.rentHouseQuestion1Des');
          }
          if (this.step === 2) {
            return this.$t('home.rentHouseQuestion2Des');
          }
          if (this.step === 3) {
            return this.$t('home.rentHouseQuestion2Des');
          }
          if (this.step === 4) {
            return this.$t('home.rentHouseQuestion4Des');
          }
          if (this.step === 5) {
            return this.$t('home.rentHouseQuestion5es');
          }
          return '';
        case 4:
          if (this.step === 1) {
            return this.$t('home.rentHouseQuestion1Des');
          }
          if (this.step === 2) {
            return this.$t('home.rentHouseQuestion2Des');
          }
          if (this.step === 3) {
            return this.$t('home.squareQuestionDes');
          }
          if (this.step === 4) {
            return this.$t('home.rentHouseQuestion5es');
          }
          return '';
        case 5:
          if (this.step === 1) {
            return this.$t('home.rentHouseQuestion1Des');
          }
          if (this.step === 2) {
            return this.$t('home.rentHouseQuestion2Des');
          }
          if (this.step === 3) {
            return this.$t('home.squareQuestionDes');
          }
          if (this.step === 4) {
            return this.$t('home.rentHouseQuestion5es');
          }
          return '';
        case 6:
          if (this.step === 1) {
            return this.$t('home.rentHouseQuestion1Des');
          }
          if (this.step === 2) {
            return this.$t('home.rentHouseQuestion2Des');
          }
          if (this.step === 3) {
            return this.$t('home.rentHouseQuestion2Des');
          }
          if (this.step === 4) {
            return this.$t('home.squareQuestionDes');
          }
          if (this.step === 5) {
            return this.$t('home.rentHouseQuestion5es');
          }
          return '';
        case 7:
          if (this.step === 1) {
            return this.$t('home.rentHouseQuestion1Des');
          }
          if (this.step === 2) {
            return this.$t('home.rentHouseQuestion2Des');
          }
          if (this.step === 3) {
            return this.$t('home.rentHouseQuestion2Des');
          }
          if (this.step === 4) {
            return this.$t('home.squareQuestionDes');
          }
          if (this.step === 5) {
            return this.$t('home.rentHouseQuestion5es');
          }
          return '';
        default:
          return '';
      }
    },
    resultTitle() {
      switch (this.form.type) {
        case 1:
          return this.$t('home.rentalHouseConditions');
        case 2:
          return this.$t('home.purchaseHouseConditions');
        case 11:
          return this.$t('home.newHouseConditions');
        case 3:
          return this.$t('home.factoryConditions');
        case 4:
          return this.$t('home.factoryConditions');
        case 5:
          return this.$t('home.landConditions');
        case 6:
          return this.$t('home.landConditions');
        case 7:
          return this.$t('home.officeConditions');
        case 8:
          return this.$t('home.officeConditions');
        case 9:
          return this.$t('home.shopConditions');
        case 10:
          return this.$t('home.shopConditions');
        default:
          return this.$t('home.rentalHouseConditions');
      }
    },
  },
  created() {
    if (this.identity !== 'guest') return;
    this.getData();
  },
  methods: {
    transferCityText(area) {
      let str = '';
      if (area) {
        if (area.length > 0) {
          area.forEach((item) => {
            str += item;
          });
        }
      }
      return str;
    },
    showTab() {
      if (this.type === 1 || this.type === 2 || this.type === 3) return false;
      return true;
    },
    getArea(index) {
      switch (index) {
        case 0:
          return this.area1;
        case 1:
          return this.area2;
        case 2:
          return this.area3;
        default:
          return this.area1;
      }
    },
    getAreaList(city, index) {
      areaList({
        lang: this.lang,
        id: city,
      }).then((res) => {
        const { success } = res;
        const { data } = res;
        if (success) {
          switch (index) {
            case 0:
              this.area1.push({
                value: '0',
                name: this.$t('publish.form.noLimit'),
              });
              this.area1 = this.area1.concat(data.list);
              break;
            case 1:
              this.area2.push({
                value: '0',
                name: this.$t('publish.form.noLimit'),
              });
              this.area2 = this.area2.concat(data.list);
              break;
            case 2:
              this.area3.push({
                value: '0',
                name: this.$t('publish.form.noLimit'),
              });
              this.area3 = this.area3.concat(data.list);
              break;
            default:
              break;
          }
        }
      }).catch((e) => {
        console.log(e);
      });
    },
    convertCategory(category) {
      switch (this.form.type) {
        case 1:
          return this.$t(`house_info.house_category[${category.house_category}]`);
        case 2:
          return this.$t(`house_info.house_category[${category.house_category}]`);
        case 11:
          return this.$t(`new_house.case_type[${category.house_category}]`);
        case 7:
          return this.$t(`office.category[${category.house_category}]`);
        case 8:
          return this.$t(`office.category[${category.house_category}]`);
        case 9:
          return this.$t(`shop.category[${category.house_category}]`);
        case 10:
          return this.$t(`shop.category[${category.house_category}]`);
        default:
          return this.$t(`house_info.house_category[${category.house_category}]`);
      }
    },
    convertType(type) {
      switch (this.form.type) {
        case 1:
          return this.$t(`house_info.house_type[${type.house_type}]`);
        case 2:
          return this.$t(`house_info.house_type[${type.house_type}]`);
        case 11:
          return this.$t(`house_info.house_type[${type.house_type}]`);
        case 3:
          return this.$t(`factory.type[${type.house_type}]`);
        case 4:
          return this.$t(`factory.type[${type.house_type}]`);
        case 5:
          return this.$t(`land.type[${type.house_type}]`);
        case 6:
          return this.$t(`land.type[${type.house_type}]`);
        case 7:
          return this.$t(`office.type[${type.house_type}]`);
        case 8:
          return this.$t(`office.type[${type.house_type}]`);
        case 9:
          return this.$t(`shop.type[${type.house_type}]`);
        case 10:
          return this.$t(`shop.type[${type.house_type}]`);
        default:
          return this.$t(`house_info.house_type[${type.house_type}]`);
      }
    },
    convertSquareOrRoom() {
      switch (this.form.type) {
        case 1:
          return `${this.form.min_room !== null ? this.form.min_room : '0'} - ${this.form.max_room !== null ? this.form.max_room : '0'} ${this.$t('search.room')}`;
        case 2:
          return `${this.form.min_room !== null ? this.form.min_room : '0'} - ${this.form.max_room !== null ? this.form.max_room : '0'} ${this.$t('search.room')}`;
        case 11:
          return `${this.form.min_room !== null ? this.form.min_room : '0'} - ${this.form.max_room !== null ? this.form.max_room : '0'} ${this.$t('search.room')}`;
        case 3:
          return `${this.form.min_square !== null ? this.form.min_square : '0'} - ${this.form.max_square !== null ? this.form.max_square : '0'} / m2`;
        case 4:
          return `${this.form.min_square !== null ? this.form.min_square : '0'} - ${this.form.max_square !== null ? this.form.max_square : '0'} / m2`;
        case 5:
          return `${this.form.min_square !== null ? this.form.min_square : '0'} - ${this.form.max_square !== null ? this.form.max_square : '0'} / m2`;
        case 6:
          return `${this.form.min_square !== null ? this.form.min_square : '0'} - ${this.form.max_square !== null ? this.form.max_square : '0'} / m2`;
        case 7:
          return `${this.form.min_square !== null ? this.form.min_square : '0'} - ${this.form.max_square !== null ? this.form.max_square : '0'} / m2`;
        case 8:
          return `${this.form.min_square !== null ? this.form.min_square : '0'} - ${this.form.max_square !== null ? this.form.max_square : '0'} / m2`;
        case 9:
          return `${this.form.min_square !== null ? this.form.min_square : '0'} - ${this.form.max_square !== null ? this.form.max_square : '0'} / m2`;
        case 10:
          return `${this.form.min_square !== null ? this.form.min_square : '0'} - ${this.form.max_square !== null ? this.form.max_square : '0'} / m2`;
        default:
          return `${this.form.min_room !== null ? this.form.min_room : '0'} - ${this.form.max_room !== null ? this.form.max_room : '0'} ${this.$t('search.room')}`;
      }
    },
    next() {
      if (this.btnDisabled) return;
      switch (this.step) {
        case 1:
          this.step = 2;
          break;
        case 2:
          this.step = 3;
          break;
        case 3:
          this.step = 4;
          break;
        case 4:
          if (this.type === 4 || this.type === 5) {
            this.submit();
          } else {
            this.step = 5;
          }
          break;
        case 5:
          if (this.type !== 4 || this.type !== 5) {
            this.submit();
          } else {
            this.step = 6;
          }
          break;
        default:
          this.step = 1;
      }
    },
    prev() {
      switch (this.step) {
        case 2:
          this.step = 1;
          break;
        case 3:
          this.step = 2;
          break;
        case 4:
          this.step = 3;
          break;
        case 5:
          this.step = 4;
          break;
        case 6:
          this.step = 5;
          break;
        default:
          this.step = 1;
      }
    },
    chooseCategory(id) {
      if (this.form.category_list.indexOf(id) === -1) {
        if (this.form.category_list.length < 3) {
          this.form.category_list.push(id);
        }
      } else {
        this.form.category_list = this.form.category_list.filter((item) => item !== id);
      }
    },
    chooseType(id) {
      if (this.form.type_list.indexOf(id) === -1) {
        if (this.form.type_list.length < 3) {
          this.form.type_list.push(id);
        }
      } else {
        this.form.type_list = this.form.type_list.filter((item) => item !== id);
      }
    },
    chooseCity(value, index) {
      this.form.city_list[index].city = value.value;
      this.getAreaList(value.value, index);
    },
    chooseArea(value, index) {
      if (this.form.city_list[index].arr.indexOf(value.value) === -1) {
        if (value.value === '0') {
          this.form.city_list[index].arr = [];
        } else {
          this.form.city_list[index].arr = this.form.city_list[index].arr.filter((item) => item !== '0');
        }
        this.form.city_list[index].arr.push(value.value);
      } else {
        this.form.city_list[index].arr = this.form.city_list[index].arr.filter((item) => item !== value.value);
      }
    },
    chooseTab(id) {
      this.form.type = id;
      this.step = 1;
      this.getData();
    },
    loginOrSwitch() {
      if (this.isLogin) {
        this.$store.commit('UPDATE_SWITCH_IDENTITY', true);
      } else {
        this.$router.push({ name: 'login' });
      }
    },
    convert(price) {
      return `đ ${convertUnit(convertPrice(price))}`;
    },
    refresh() {
      if (this.identity === 'guest') {
        if (this.form.type !== 1) {
          this.$router.push({ name: 'guestRequirements', query: { type: this.form.type } });
        } else {
          this.$router.push({ name: 'guestRequirements' });
        }
      }
    },
    submit() {
      if (this.form.city_list.length > 0) {
        this.form.city_list.forEach((city, key) => {
          if (city.arr.length === 1 && city.arr[0] === '0') {
            if (key === 0) {
              this.area1.forEach((item) => {
                this.form.city_list[key].arr.push(item.value);
                this.form.city_list[key].arr = this.form.city_list[key].arr.filter((ele) => ele !== '0');
              });
            } else if (key === 1) {
              this.area2.forEach((item) => {
                this.form.city_list[key].arr.push(item.value);
                this.form.city_list[key].arr = this.form.city_list[key].arr.filter((ele) => ele !== '0');
              });
            } else {
              this.area3.forEach((item) => {
                this.form.city_list[key].arr.push(item.value);
                this.form.city_list[key].arr = this.form.city_list[key].arr.filter((ele) => ele !== '0');
              });
            }
          }
        });
      }
      this.btnDisabled = true;
      updatePref(this.form).then((res) => {
        if (res.success) {
          this.getData();
        }
        this.btnDisabled = false;
      }).catch(() => {
        this.btnDisabled = false;
      });
    },
    getData() {
      if (!this.isLogin) return;
      this.loading = true;
      const form = {
        lang: this.lang,
        type: this.form.type,
      };
      pref(form).then((res) => {
        const { success, data } = res;
        if (success) {
          this.form = data;
          if (this.form.city_list.length === 0) {
            this.form.city_list = [
              {
                city: null,
                arr: [],
              },
              {
                city: null,
                arr: [],
              },
              {
                city: null,
                arr: [],
              },
            ];
          }
          if (data.updated_at.length > 0) {
            this.step = 6;
          }
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
  },
};
</script>
