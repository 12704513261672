import metaBg from '@/assets/meta/meta_bg.png';

const identity = 'sub';
const subRouter = {
  path: 'sub',
  component: () => import('@/views/memberCenter/page/HomeView.vue'),
  children: [
    // 首頁
    {
      path: 'home',
      name: 'subHome',
      component: () => import('@/views/memberCenter/page/common/home/HomeView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'subHome',
          },
        ],
        title: 'memberCenter.home',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    // 訊息
    {
      path: 'message',
      component: () => import('@/views/memberCenter/page/common/message/MessageLayout.vue'),
      children: [
        {
          path: '',
          name: 'subMessage',
          component: () => import('@/views/memberCenter/page/common/message/message/MessageView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'subHome',
              },
              {
                name: 'breadcrumbList.message',
                pathName: 'subMessage',
              },
            ],
            title: 'breadcrumbList.message',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'qa/house',
          name: 'subHouseQA',
          component: () => import('@/views/memberCenter/page/common/message/qa/HouseQA.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'subHome',
              },
              {
                name: 'breadcrumbList.message',
                pathName: 'subMessage',
              },
              {
                name: 'breadcrumbList.houseQA',
                pathName: 'subHouseQA',
              },
            ],
            title: 'breadcrumbList.houseQA',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
      ],
    },
    {
      path: 'message/content/:id',
      name: 'subMessageContent',
      component: () => import('@/views/memberCenter/page/common/message/message/MessageDetail.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'subHome',
          },
          {
            name: 'breadcrumbList.message',
            pathName: 'subMessage',
          },
          {
            name: 'breadcrumbList.messageContent',
            pathName: 'subMessageContent',
          },
        ],
        title: 'breadcrumbList.messageContent',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'message/qa/house/content/:id',
      name: 'subHouseQAContent',
      component: () => import('@/views/memberCenter/page/common/message/qa/HouseQADetail.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'subHome',
          },
          {
            name: 'breadcrumbList.message',
            pathName: 'subMessage',
          },
          {
            name: 'breadcrumbList.houseQA',
            pathName: 'subHouseQA',
          },
          {
            name: ':id',
            pathName: 'subHouseQAContent',
          },
        ],
        title: 'breadcrumbList.houseQA',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    // 刊登
    {
      path: 'publish/:category',
      component: () => import('@/views/memberCenter/page/common/publish/PublishLayout.vue'),
      children: [
        {
          path: '',
          name: `${identity}Publish`,
          component: () => import('@/views/memberCenter/page/common/publish/publish/PublishHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'hostHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.publishManagement',
            description: 'about.description1',
            image: metaBg,
            tabId: 1,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'single',
          name: `${identity}SinglePublish`,
          component: () => import('@/views/memberCenter/page/common/publish/publish/PublishHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'hostHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.singlePosting',
                pathName: `${identity}SinglePublish`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.publishManagement',
            description: 'about.description1',
            image: metaBg,
            tabId: 1,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'rental',
          name: `${identity}RentalPublish`,
          component: () => import('@/views/memberCenter/page/common/publish/publish/PublishHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'hostHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.rentalPlanPublication',
                pathName: `${identity}RentalPublish`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.publishManagement',
            description: 'about.description1',
            image: metaBg,
            tabId: 1,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'sale',
          name: `${identity}SalePublish`,
          component: () => import('@/views/memberCenter/page/common/publish/publish/PublishHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'hostHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.salePlanPublication',
                pathName: `${identity}SalePublish`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.publishManagement',
            description: 'about.description1',
            image: metaBg,
            tabId: 1,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'closed',
          name: `${identity}ClosedHouse`,
          component: () => import('@/views/memberCenter/page/common/publish/close/ClosedHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'hostHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.housesClosed',
                pathName: `${identity}ClosedHouse`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.housesClosed',
            description: 'about.description1',
            image: metaBg,
            tabId: 2,
            requiredAuth: true,
          },
        },
        {
          path: 'deal',
          name: `${identity}DealHouse`,
          component: () => import('@/views/memberCenter/page/common/publish/deal/DealHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'hostHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.removedHouses',
                pathName: `${identity}DealHouse`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.removedHouses',
            description: 'about.description1',
            image: metaBg,
            tabId: 3,
            requiredAuth: true,
          },
        },
        {
          path: 'edit',
          name: `${identity}EditHouse`,
          component: () => import('@/views/memberCenter/page/common/publish/edit/EditHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'hostHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.housesEdit',
                pathName: `${identity}EditHouse`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.housesEdit',
            description: 'about.description1',
            image: metaBg,
            tabId: 4,
            requiredAuth: true,
          },
        },
      ],
    },
    {
      path: 'publish/:category/rental/:id',
      name: `${identity}RentalPublishDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}RentalPublishDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/sale/:id',
      name: `${identity}SalePublishDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/rental/:id/edit',
      name: `${identity}HouseEditRentalPublication`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}PublishDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseEditRentalPublication`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/sale/:id/edit',
      name: `${identity}HouseEditSalePublication`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseEditSalePublication`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/rental/:id/edit',
      name: `${identity}FactoryEditRentalPublication`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}PublishDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryEditRentalPublication`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/sale/:id/edit',
      name: `${identity}FactoryEditSalePublication`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryEditSalePublication`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/rental/:id/edit',
      name: `${identity}LandEditRentalPublication`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}PublishDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandEditRentalPublication`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/sale/:id/edit',
      name: `${identity}LandEditSalePublication`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandEditSalePublication`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/rental/:id/improve',
      name: `${identity}ImprovetRentalPublication`,
      component: () => import('@/views/publish/ThirdStep.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.improveEffect',
            pathName: `${identity}ImprovetRentalPublication`,
            category: ':category',
          },
        ],
        title: 'breadcrumbList.improveEffect',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/bonus/:type/:id',
      name: `${identity}HouseBonus`,
      component: () => import('@/views/memberCenter/page/common/publish/exposure/ExposureView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.improve',
            pathName: `${identity}HouseBonus`,
            category: ':category',
          },
        ],
        title: 'breadcrumbList.improve',
        description: 'about.description1',
        image: metaBg,
        tabId: 1,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/deal/rental/:id',
      name: `${identity}DealRentalDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/deal/sale/:id',
      name: `${identity}DealSaleDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/closed/rental/:id',
      name: `${identity}ClosedRentalDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/closed/sale/:id',
      name: `${identity}ClosedSaleDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    // 編輯成交
    {
      path: 'publish/house/deal/rental/:id/edit',
      name: `${identity}HouseDealEditRental`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseDealEditRental`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/deal/sale/:id/edit',
      name: `${identity}HouseDealEditSale`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseDealEditSale`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/deal/rental/:id/edit',
      name: `${identity}FactoryDealEditRental`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryDealEditRental`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/deal/sale/:id/edit',
      name: `${identity}FactoryDealEditSale`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryDealEditSale`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/deal/rental/:id/edit',
      name: `${identity}LandDealEditRental`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandDealEditRental`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/deal/sale/:id/edit',
      name: `${identity}LandDealEditSale`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandDealEditSale`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    // 編輯關閉
    {
      path: 'publish/house/closed/rental/:id/edit',
      name: `${identity}HouseClosedEditRental`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseClosedEditRental`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/closed/sale/:id/edit',
      name: `${identity}HouseClosedEditSale`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseClosedEditSale`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/closed/rental/:id/edit',
      name: `${identity}FactoryClosedEditRental`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryClosedEditRental`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/closed/sale/:id/edit',
      name: `${identity}FactoryClosedEditSale`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryClosedEditSale`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/closed/rental/:id/edit',
      name: `${identity}LandClosedEditRental`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandClosedEditRental`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/closed/sale/:id/edit',
      name: `${identity}LandClosedEditSale`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandClosedEditSale`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    // 編輯未刊登
    {
      path: 'publish/house/edit-rental/:id',
      name: `${identity}EditRentalHouse`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}EditRentalHouse`,
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/edit-sale/:id',
      name: `${identity}EditSaleHouse`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}EditSaleHouse`,
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/edit-rental/:id',
      name: `${identity}EditRentalFactory`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}EditRentalFactory`,
            category: 'factory',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/edit-sale/:id',
      name: `${identity}EditSaleFactory`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}EditSaleFactory`,
            category: 'factory',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/edit-rental/:id',
      name: `${identity}EditRentalLand`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}EditRentalLand`,
            category: 'land',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/edit-sale/:id',
      name: `${identity}EditSaleLand`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'hostHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}EditSaleLand`,
            category: 'land',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'business',
      component: () => import('@/views/memberCenter/page/common/business/BusinessLayout.vue'),
      children: [
        {
          path: '',
          name: 'subBusinessHome',
          component: () => import('@/views/memberCenter/page/common/business/page/home/BusinessHome.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'subHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'subBusinessHome',
              },
            ],
            title: 'breadcrumbList.businessManagement',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
          },
        },
        {
          path: 'schedule',
          name: 'subBusinessSchedule',
          component: () => import('@/views/memberCenter/page/common/business/page/home/BusinessHome.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'subHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'subBusinessHome',
              },
              {
                name: 'memberCenter.schedule',
                pathName: 'subBusinessSchedule',
              },
            ],
            title: 'memberCenter.schedule',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
          },
        },
        {
          path: 'monthly',
          name: 'subBusinessMonthly',
          component: () => import('@/views/memberCenter/page/common/business/page/home/BusinessHome.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'subHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'subBusinessHome',
              },
              {
                name: 'memberCenter.monthly',
                pathName: 'subBusinessMonthly',
              },
            ],
            title: 'memberCenter.monthly',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
          },
        },
        {
          path: 'new-task',
          name: 'subBusinessTask',
          component: () => import('@/views/memberCenter/page/common/business/page/new_task/BusinessNewTask.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'subHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'subBusinessHome',
              },
              {
                name: 'memberCenter.newTask',
                pathName: 'subBusinessTask',
              },
            ],
            title: 'memberCenter.newTask',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
          },
          children: [
            {
              path: ':id',
              name: 'subBusinessTaskEdit',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'subHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'subBusinessHome',
                  },
                  {
                    name: 'memberCenter.editTask',
                    pathName: 'subBusinessTaskEdit',
                  },
                ],
                title: 'memberCenter.editTask',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: true,
              },
            },
          ],
        },
        {
          path: 'report/:id',
          name: 'subBusinessReport',
          component: () => import('@/views/memberCenter/page/common/business/page/report/BusinessReport.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'subHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'subBusinessHome',
              },
              {
                name: 'memberCenter.report',
                pathName: 'subBusinessReport',
              },
            ],
            title: 'memberCenter.report',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
          },
        },
        {
          path: 'client-management',
          component: () => import('@/views/memberCenter/page/common/business/page/client/BusinessClient.vue'),
          children: [
            {
              path: '',
              name: 'subBusinessClient',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'subHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'subBusinessHome',
                  },
                  {
                    name: 'memberCenter.clientManagement',
                    pathName: 'subBusinessClient',
                  },
                ],
                title: 'memberCenter.clientManagement',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: true,
                requiredAuth: true,
              },
            },
            {
              path: 'client',
              name: 'subBusinessClientClients',
              meta: {
                breadcrumbs: [
                  {
                    name: 'breadcrumbList.home',
                    pathName: 'home',
                  },
                  {
                    name: 'breadcrumbList.memberHome',
                    pathName: 'subHome',
                  },
                  {
                    name: 'breadcrumbList.businessManagement',
                    pathName: 'subBusinessHome',
                  },
                  {
                    name: 'memberCenter.clientManagement',
                    pathName: 'subBusinessClientClients',
                  },
                ],
                title: 'memberCenter.clientManagement',
                description: 'about.description1',
                image: metaBg,
                showEditBtn: true,
                requiredAuth: true,
              },
            },
          ],
        },
        {
          path: 'client-management/new-client',
          name: 'subBusinessClientNew',
          component: () => import('@/views/memberCenter/page/common/business/page/new_client/BusinessNewClient.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'subHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'subBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'subBusinessClientClients',
              },
              {
                name: 'memberCenter.addNewClient',
                pathName: 'subBusinessClientNew',
              },
            ],
            title: 'memberCenter.addNewClient',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'client-management/new-client/:type',
          name: 'subBusinessClientNewInfo',
          component: () => import('@/views/memberCenter/page/common/business/page/new_client/BusinessNewClientForm.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'subHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'subBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'subBusinessClientClients',
              },
              {
                name: 'memberCenter.addNewClient',
                pathName: 'subBusinessClientNewInfo',
              },
            ],
            title: 'memberCenter.addNewClient',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'client-management/:type/:id',
          name: 'subBusinessEditClient',
          component: () => import('@/views/memberCenter/page/common/business/page/new_client/BusinessNewClientForm.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'subHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'subBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'subBusinessClient',
              },
              {
                name: 'memberCenter.editClient',
                pathName: 'subBusinessEditClient',
              },
            ],
            showEditBtn: false,
            title: 'memberCenter.editClient',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'client-management/visit/:id',
          name: 'subBusinessVisitClient',
          component: () => import('@/views/memberCenter/page/common/business/page/visit/BusinessVisit.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'subHome',
              },
              {
                name: 'breadcrumbList.businessManagement',
                pathName: 'subBusinessHome',
              },
              {
                name: 'memberCenter.clientManagement',
                pathName: 'subBusinessClient',
              },
              {
                name: 'memberCenter.visitRecord',
                pathName: 'subBusinessVisitClient',
              },
            ],
            title: 'memberCenter.visitRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: false,
            requiredAuth: true,
          },
        },
      ],
    },
  ],
};
export default subRouter;
