<template>
    <DialogTemplate :show="open">
        <div class="dialog_container">
            <div class="dialog_card">
                <div class="dialog_card--header justify_center">
                    <h4 class="dialog_card--title">{{ $t('selectLanguage') }}</h4>
                    <SvgIcon
                      icon-class="close"
                      class-name="dialog_card--close"
                      @click="closeDialog"
                    />
                </div>
                <ul class="dialog_card--content justify_center">
                    <li
                      v-for="lan in languageList"
                      class="dialog_card--items"
                      :class="{ 'current': lang === lan.language_code }"
                      :key="lan.language_code"
                      @click="changeLan(lan.language_code)"
                    >
                        {{ lan.language_name }}
                    </li>
                </ul>
            </div>
        </div>
    </DialogTemplate>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogTemplate from './DialogTemplate.vue';

export default {
  name: 'LanguageDialog',
  components: {
    DialogTemplate,
  },
  props: {
    open: {
      type: Boolean,
    },
  },
  emits: ['close'],
  data() {
    return {
      languageList: [
        {
          language_code: 'cn',
          language_name: '简体中文',
        },
        {
          language_code: 'en',
          language_name: 'English',
        },
        {
          language_code: 'vn',
          language_name: 'Tiếng Việt',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['lang']),
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    changeLan(code) {
      const route = { ...this.$route };
      const newPath = route.path.replace(/\/(cn|en)\/?/, '/');
      if (code !== this.lang) {
        this.$store.commit('CHANGE_LAN', code);
        this.$i18n.locale = code;
        this.closeDialog();
        switch (code) {
          case 'cn':
            document.documentElement.lang = 'zh-Hans';
            this.$router.push(
              {
                name: this.$route.name,
                params: {
                  language: code,
                },
                query: this.$route.query,
              },
            );
            break;
          case 'en':
            document.documentElement.lang = 'en';
            this.$router.push(
              {
                name: this.$route.name,
                params: {
                  language: code,
                },
                query: this.$route.query,
              },
            );
            break;
          case 'vn':
            this.$router.push({ path: newPath, query: route.query });
            document.documentElement.lang = 'vi';
            break;
          default:
            this.$router.push({ path: newPath, query: route.query });
            document.documentElement.lang = 'vi';
            break;
        }
      }
    },
  },
};
</script>
