import service from '@/plugins/axios';

// 店面
const root = '/a/a111';

// 精選店面
export function bestStorefront(params) {
  return service({
    url: `${root}/best_storefront`,
    method: 'get',
    params,
  });
}

// 適合店面
export function suitableStorefront(params) {
  return service({
    url: `${root}/suitable_storefront`,
    method: 'get',
    params,
  });
}

// 推薦店面
export function recommendStorefront(params) {
  return service({
    url: `${root}/recommend_storefront`,
    method: 'get',
    params,
  });
}

// 條件店面
export function conditionStorefront(params) {
  return service({
    url: `${root}/condition_storefront`,
    method: 'get',
    params,
  });
}

// 相似店面
export function similarStorefront(params) {
  return service({
    url: `${root}/similar_storefront`,
    method: 'get',
    params,
  });
}

// 店面詳細
export function storefrontDetail(params) {
  return service({
    url: `${root}/storefront_detail`,
    method: 'get',
    params,
  });
}

// 今日新增店面數
export function storefrontOfTodayCount(params) {
  return service({
    url: `${root}/today_storefront_count`,
    method: 'get',
    params,
  });
}
