/* eslint-disable import/no-extraneous-dependencies */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './language/index';
import './style/all.scss';
import SvgIcon from './components/icons/SvgIcon.vue';
import Vue3Storage from 'vue3-storage';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import CountryFlag from 'vue-country-flag-next';
import QrcodeVue from 'qrcode.vue';
// video
import VueVideoPlayer from '@videojs-player/vue';
import 'video.js/dist/video-js.css';
// 日期
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
// 月份選擇
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
// 富文本
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// meta
import MetaInfo from 'vue-meta-info';
// import { createMetaManager, defaultConfig, plugin as vueMetaPlugin } from 'vue-meta';

// google 登入

import vue3GoogleLogin from 'vue3-google-login';

import initFacebook from '@/plugins/facebook/facebook';

const gAuthOptions = {
  clientId: process.env.VUE_APP_CLIENT_ID,
};

const requireAll = (requireContext) => requireContext.keys().map(requireContext);
const req = require.context('@/icons', false, /\.svg$/);
requireAll(req);

router.beforeEach((to, from, next) => {
  if (!to.params.language) {
    store.commit('CHANGE_LAN', 'vn');
    i18n.global.locale = 'vn';
  } else if (to.params.language) {
    store.commit('CHANGE_LAN', to.params.language);
    i18n.global.locale = to.params.language;
  }
  // 判斷登入
  if (store.getters.isLogin) {
    if (
      to.name === 'login'
      || to.name === 'identity'
      || to.name === 'registerStep1'
      || to.name === 'forgotPwdStep1'
    ) {
      if (to.params.language) {
        next({ path: `/${store.getters.lang}` });
      } else {
        next({ path: '/' });
      }
    }
    // 刊登頁面限制 guest 身份
    if (to.meta.restricted === store.getters.identity) {
      if (to.params.language) {
        next({ path: `/${store.getters.lang}` });
      } else {
        next({ path: '/' });
      }
    } else {
      next();
    }
    if (!to.name.includes('publisher')) {
      // 匹配會員中心現在身份
      if (
        to.path.includes('guest')
        || to.path.includes('host')
        || to.path.includes('medium')
        || to.path.includes('company')
        || to.path.includes('sub')) {
        if (!to.name.includes(store.getters.identity)) {
          if (to.params.language) {
            next({ path: `/${store.getters.lang}` });
          } else {
            next({ path: '/' });
          }
        }
      }
    }
  } else if (!store.getters.isLogin) {
    // 判斷 meta 有無 requiredAuth
    if (to.meta.requiredAuth) {
      if (to.params.language) {
        next({ path: `/${store.getters.lang}/login` });
      } else {
        next({ path: '/login' });
      }
    } else {
      next();
    }
  }
});

// const metaManager = createMetaManager(false, {
//   ...defaultConfig,
//   meta: { tag: 'meta', nameless: true },
// });

createApp(App)
  .component('SvgIcon', SvgIcon)
  .component('VDatePicker', DatePicker)
  .component('VueDatePicker', VueDatePicker)
  .component('QuillEditor', QuillEditor)
  .component('QrcodeVue', QrcodeVue)
  .use(i18n)
  .use(Vue3Storage, { namespace: 'pro_' })
  .use(ToastPlugin, {
    position: 'bottom-right',
  })
  .use(initFacebook)
  .use(vue3GoogleLogin, gAuthOptions)
  .use(CountryFlag)
  .use(VueVideoPlayer)
  .use(MetaInfo)
  .use(store)
  .use(router)
  .mount('#app');
