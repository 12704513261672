<template>
  <div class="menu_container">
    <Transition name="fade">
      <div class="menu" v-if="open" @click="closeDialog()"></div>
    </Transition>
    <Transition name="slide">
      <div
        class="menu_card flex flex_column justify_between"
        @click.stop
        v-if="open"
      >
        <div>
          <button
            type="button"
            class="fill_button"
            @click="directPath('firstPublish')"
          >
            <SvgIcon icon-class="upload" class-name="icon" />
            {{ $t("navbar.publish") }}
          </button>
          <MobileUserContent :open="true" @close="closeDialog()" />
        </div>
        <div>
          <div class="flex">
            <a href="https://www.facebook.com/88go.house" class="sidebar_icons">
              <SvgIcon
                icon-class="facebook"
                class-name="sidebar_icons--icon sidebar_icons--fb"
              />
            </a>
            <a
              href="https://www.instagram.com/88gohouse/"
              class="sidebar_icons"
            >
              <SvgIcon
                icon-class="instagram"
                class-name="sidebar_icons--icon"
              />
            </a>
          </div>
          <div ref="select" class="select">
            <div class="custom_select" @click="openLanguageDialog()">
              <input
                v-model="inputValue"
                class="custom_select--input"
                type="text"
                aria-label="select"
                :placeholder="currentLangText()"
                readonly
              />
              <SvgIcon
                icon-class="arrow_down"
                class-name="icon custom_select--icon"
                :id="id"
              />
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <div class="menu_bar" @click="toggleMenu()" :class="{ open: open }">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <LanguageDialog :open="openLanguage" @close="closeLanguageDialog" />
  <NoLoginDialog :open="openNoLogin" @close="closeNotLoginDialog" />
  <GuestIdentityDialog :open="openGuest" @close="closeGuestDialog" />
</template>

<script>
import DialogTemplate from './DialogTemplate.vue';
import MobileUserContent from '../navbar/MobileUserContent.vue';
import DefaultSelect from '../select/DefaultSelect.vue';
import LanguageDialog from './LanguageDialog.vue';
import NoLoginDialog from './NoLoginDialog.vue';
import GuestIdentityDialog from './GuestIdentityDialog.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MenuDialog',
  components: {
    DialogTemplate,
    MobileUserContent,
    DefaultSelect,
    LanguageDialog,
    NoLoginDialog,
    GuestIdentityDialog,
  },
  props: {
    open: Boolean,
  },
  emits: ['close', 'toggle'],
  data() {
    return {
      openLanguage: false,
      openNoLogin: false,
      openGuest: false,
      languageList: [
        {
          language_code: 'cn',
          language_name: '简体中文',
        },
        {
          language_code: 'en',
          language_name: 'English',
        },
        {
          language_code: 'vn',
          language_name: 'Tiếng Việt',
        },
      ],
    };
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    toggleMenu() {
      this.$emit('toggle');
    },
    openLanguageDialog() {
      this.openLanguage = true;
    },
    closeLanguageDialog() {
      this.openLanguage = false;
    },
    closeNotLoginDialog() {
      this.openNoLogin = false;
    },
    closeGuestDialog() {
      this.openGuest = false;
    },
    currentLangText() {
      return this.languageList.find((lan) => lan.language_code === this.lang).language_name;
    },
    directPath(name) {
      if (name === 'firstPublish') {
        if (!this.isLogin) {
          this.openNoLogin = true;
          return;
        } else if (this.role === 1) {
          this.openGuest = true;
          this.$store.commit('UPDATE_SWITCH_TO_REDIRECT', { name: 'firstPublish' });
          return;
        }
      }
      this.closeDialog();
    },
  },
  watch: {
    show(value) {
      const html = document.querySelector('html');
      if (value) {
        html.style.overflow = 'hidden';
      } else {
        html.removeAttribute('style');
      }
    },
  },
  computed: {
    ...mapGetters(['lang', 'isLogin', 'role']),
  },
};
</script>
