/* eslint-disable import/no-extraneous-dependencies */
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';

export default function initFB() {
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: 'IzaSyChkfDBnZA81DRVYGRpl7bsuqF351ZPL5g',
    authDomain: 'go-hosue-format.firebaseapp.com',
    projectId: 'go-hosue-format',
    storageBucket: 'go-hosue-format.appspot.com',
    messagingSenderId: '956209534654',
    appId: '1:956209534654:web:08320f7c4556ed7f0e30ef',
    measurementId: 'G-LJZT873ZPC',
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // eslint-disable-next-line no-unused-vars
  const analytics = getAnalytics(app);
  const messaging = getMessaging(app);
  const fbData = {
    analytics,
    messaging,
  };
  return fbData;
}
