import metaBg from '@/assets/meta/meta_bg.png';

const mapShopRouter = {
  path: 'shop/map',
  name: 'shopMap',
  component: () => import('@/views/map/MapView.vue'),
  meta: {
    breadcrumbs: [
      {
        name: 'breadcrumbList.home',
        pathName: 'home',
      },
      {
        name: 'navbar.shop',
        pathName: 'shop',
      },
      {
        name: 'breadcrumbList.mapSearch',
        pathName: 'shopMap',
      },
    ],
    title: 'breadcrumbList.mapSearch',
    description: 'about.description1',
    bannerTitle: 'map.shopMapSearch',
    bannerEnTitle: 'map.shopMapSearchEn',
    image: metaBg,
  },
};
export default mapShopRouter;
