<template>
  <div class="home">
    <HomeBanner
      :tab-type="type"
      :tabs="tabList"
      :cond="cond"
      @switch="switchTab"
      @enter="(value) => cond.key_word = value"
      @update-city="(value) => cond.city = value"
      @update-category="updateHouseCategory"
      @update-office-category="updateOfficeCategory"
      @update-shop-category="updateShopCategory"
      @update-case="updateCaseType"
      @update-factory-type="updateFactoryType"
      @update-land-type="updateLandType"
      @update-office-type="updateOfficeType"
      @update-shop-type="updateShopType"
      @update-publish-type="updatePublishType"
      @update-room="updateRoom"
      @update-type="updateType"
      @search="onSearch"
      @open-filter="openDialog = true"
    />
    <HomeContent :type="type" />
    <FilterDialog
      :open="openDialog"
      :type="type"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import HomeBanner from '@/views/home/components/Banner.vue';
import HomeContent from '@/views/home/components/Content.vue';
import FilterDialog from './components/FilterDailog.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'HomeView',
  components: {
    HomeBanner,
    HomeContent,
    FilterDialog,
  },
  data() {
    return {
      tabList: [
        {
          id: 1,
          label: this.$t('navbar.rent'),
        },
        {
          id: 2,
          label: this.$t('navbar.sale'),
        },
        {
          id: 3,
          label: this.$t('navbar.preSale'),
        },
        {
          id: 4,
          label: this.$t('navbar.factory'),
        },
        {
          id: 5,
          label: this.$t('navbar.land'),
        },
        {
          id: 6,
          label: this.$t('navbar.office'),
        },
        {
          id: 7,
          label: this.$t('navbar.shop'),
        },
      ],
      cond: {
        key_word: '',
        city: '',
        house_categories: [],
        room_num: [],
        house_types: [],
        case_type: [],
        factory_types: [],
        land_types: [],
        office_category: [],
        office_type: [],
        storefront_category: [],
        storefront_type: [],
        type: null,
      },
      type: 1,
      openDialog: false,
    };
  },
  computed: {
    ...mapGetters(['lang']),
  },
  watch: {
    lang(value, oldValue) {
      if (value !== oldValue) {
        this.tabList = [
          {
            id: 1,
            label: this.$t('navbar.rent'),
          },
          {
            id: 2,
            label: this.$t('navbar.sale'),
          },
          {
            id: 3,
            label: this.$t('navbar.preSale'),
          },
          {
            id: 4,
            label: this.$t('navbar.factory'),
          },
          {
            id: 5,
            label: this.$t('navbar.land'),
          },
          {
            id: 6,
            label: this.$t('navbar.office'),
          },
          {
            id: 7,
            label: this.$t('navbar.shop'),
          },
        ];
      }
    },
  },
  methods: {
    switchTab(id) {
      this.type = id;
      this.cond = {
        key_word: '',
        city: '',
        house_categories: [],
        house_types: [],
        room_num: [],
        case_type: [],
        factory_types: [],
        land_types: [],
        office_category: [],
        office_type: [],
        storefront_category: [],
        storefront_type: [],
      };
    },
    closeDialog() {
      this.openDialog = false;
    },
    updateHouseCategory(value) {
      const index = this.cond.house_categories.indexOf(value);
      if (index === -1) {
        this.cond.house_categories.push(value);
      } else {
        this.cond.house_categories = this.cond.house_categories.filter((ele) => ele !== value);
      }
      this.cond.house_categories.sort((a, b) => a - b);
    },
    updateOfficeCategory(value) {
      const index = this.cond.office_category.indexOf(value);
      if (index === -1) {
        this.cond.office_category.push(value);
      } else {
        this.cond.office_category = this.cond.office_category.filter((ele) => ele !== value);
      }
      this.cond.office_category.sort((a, b) => a - b);
    },
    updateShopCategory(value) {
      const index = this.cond.storefront_category.indexOf(value);
      if (index === -1) {
        this.cond.storefront_category.push(value);
      } else {
        this.cond.storefront_category = this.cond.storefront_category.filter((ele) => ele !== value);
      }
      this.cond.storefront_category.sort((a, b) => a - b);
    },
    updateType(value) {
      const index = this.cond.house_types.indexOf(value);
      if (index === -1) {
        this.cond.house_types.push(value);
      } else {
        this.cond.house_types = this.cond.house_types.filter((ele) => ele !== value);
      }
      this.cond.house_types.sort((a, b) => a - b);
    },
    updateCaseType(value) {
      const index = this.cond.case_type.indexOf(value);
      if (index === -1) {
        this.cond.case_type.push(value);
      } else {
        this.cond.case_type = this.cond.case_type.filter((ele) => ele !== value);
      }
      this.cond.case_type.sort((a, b) => a - b);
    },
    updateFactoryType(value) {
      const index = this.cond.factory_types.indexOf(value);
      if (index === -1) {
        this.cond.factory_types.push(value);
      } else {
        this.cond.factory_types = this.cond.factory_types.filter((ele) => ele !== value);
      }
      this.cond.factory_types.sort((a, b) => a - b);
    },
    updateLandType(value) {
      const index = this.cond.land_types.indexOf(value);
      if (index === -1) {
        this.cond.land_types.push(value);
      } else {
        this.cond.land_types = this.cond.land_types.filter((ele) => ele !== value);
      }
      this.cond.land_types.sort((a, b) => a - b);
    },
    updateOfficeType(value) {
      const index = this.cond.office_type.indexOf(value);
      if (index === -1) {
        this.cond.office_type.push(value);
      } else {
        this.cond.office_type = this.cond.office_type.filter((ele) => ele !== value);
      }
      this.cond.office_type.sort((a, b) => a - b);
    },
    updateShopType(value) {
      const index = this.cond.storefront_type.indexOf(value);
      if (index === -1) {
        this.cond.storefront_type.push(value);
      } else {
        this.cond.storefront_type = this.cond.storefront_type.filter((ele) => ele !== value);
      }
      this.cond.storefront_type.sort((a, b) => a - b);
    },
    updatePublishType(value) {
      this.cond.type = value;
    },
    updateRoom(room) {
      if (this.type === 1 || this.type === 2 || this.type === 3) {
        const index = this.cond.room_num.indexOf(room);
        if (index === -1) {
          this.cond.room_num.push(room);
        } else {
          this.cond.room_num = this.cond.room_num.filter((ele) => ele !== room);
        }
        this.cond.room_num.sort((a, b) => a - b);
      }
    },
    onSearch() {
      const cond = JSON.parse(JSON.stringify(this.cond));
      Object.keys(cond).forEach((key) => {
        if (cond[key] === null || cond[key]?.length === 0) {
          delete cond[key];
        }
      });
      if (cond.house_categories) {
        cond.house_categories = cond.house_categories.toString();
      }
      if (cond.house_types) {
        cond.house_types = cond.house_types.toString();
      }
      if (cond.room_num) {
        cond.room_num = cond.room_num.toString();
      }
      if (cond.case_type) {
        cond.case_type = cond.case_type.toString();
      }
      if (cond.factory_types) {
        cond.factory_types = cond.factory_types.toString();
      }
      if (cond.land_types) {
        cond.land_types = cond.land_types.toString();
      }
      if (cond.office_category) {
        cond.office_category = cond.office_category.toString();
      }
      if (cond.office_type) {
        cond.office_type = cond.office_type.toString();
      }
      if (cond.storefront_category) {
        cond.storefront_category = cond.storefront_category.toString();
      }
      if (cond.storefront_type) {
        cond.storefront_type = cond.storefront_type.toString();
      }
      switch (this.type) {
        case 1:
          this.$router.push({
            name: 'rent',
            query: cond,
          });
          break;
        case 2:
          this.$router.push({
            name: 'sale',
            query: cond,
          });
          break;
        case 3:
          this.$router.push({
            name: 'preSale',
            query: cond,
          });
          break;
        case 4:
          if (!cond.type) cond.type = 4;
          this.$router.push({
            name: 'factory',
            query: cond,
          });
          break;
        case 5:
          if (!cond.type) cond.type = 6;
          this.$router.push({
            name: 'land',
            query: cond,
          });
          break;
        case 6:
          if (!cond.type) cond.type = 8;
          this.$router.push({
            name: 'office',
            query: cond,
          });
          break;
        case 7:
          if (!cond.type) cond.type = 10;
          this.$router.push({
            name: 'shop',
            query: cond,
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
