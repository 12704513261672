import metaBg from '@/assets/meta/meta_bg.png';

const loginRouter = {
  path: 'login',
  name: 'login',
  component: () => import('@/views/login/LoginView.vue'),
  meta: {
    title: 'navbar.login',
    description: 'about.description1',
    image: metaBg,
  },
};
export default loginRouter;
