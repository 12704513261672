<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex';
import initFB from '@/plugins/firebase/firebaseInit';
import { onMessage } from 'firebase/messaging';
import registerSW from '@/plugins/firebase/firebaseUI';
// eslint-disable-next-line import/no-extraneous-dependencies

export default {
  name: 'AppView',
  computed: {
    ...mapGetters(['lang', 'isLogin', 'vh']),
  },
  watch: {
    $route(to) {
      this.getLinkHref(to);
      const html = document.querySelector('html');
      if (html.style.overflow === 'hidden') {
        html.removeAttribute('style');
      }
    },
  },
  created() {
    this.changeHtmlLang();
    this.addFB();
  },
  mounted() {
    document.addEventListener('DOMContentLoaded', () => {
      this.getLinkHref(this.$route);
    });
    this.$nextTick(() => {
      this.$store.commit('UPDATE_VH', window.innerHeight * 0.01);
      document.documentElement.style.setProperty('--vh', `${this.vh}px`);
    });
    window.addEventListener('resize', () => {
      this.$store.commit('UPDATE_VH', window.innerHeight * 0.01);
      document.documentElement.style.setProperty('--vh', `${this.vh}px`);
    });
  },
  methods: {
    getLinkHref(route) {
      const alternateLinks = document.querySelectorAll("link[rel='alternate']");
      alternateLinks.forEach((link) => {
        const hreflang = link.getAttribute('hreflang');
        const href = link.getAttribute('href');
        let newHref = '';
        if (route.params.language) {
          newHref = route.fullPath.substr(3, route.fullPath.length);
        } else {
          newHref = route.fullPath;
        }
        switch (hreflang) {
          case 'zh-Hant':
            link.setAttribute('href', href.replace(href, `https://88gohouse.com/cn${newHref}`));
            break;
          case 'en':
            link.setAttribute('href', href.replace(href, `https://88gohouse.com/en${newHref}`));
            break;
          case 'vi':
            link.setAttribute('href', href.replace(href, `https://88gohouse.com${newHref}`));
            break;
          default:
            break;
        }
      });
    },
    changeHtmlLang() {
      switch (this.lang) {
        case 'cn':
          document.documentElement.lang = 'zh-Hant';
          break;
        case 'en':
          document.documentElement.lang = 'en';
          break;
        case 'vn':
          document.documentElement.lang = 'vi';
          break;
        default:
          document.documentElement.lang = 'vi';
          break;
      }
    },
    addFB() {
      const messaging = initFB()?.messaging;
      if (!('serviceWorker' in navigator)) return;
      if (Notification.permission !== 'granted') return;
      // 強制重新註冊sw
      registerSW();
      // 訂閱消息
      onMessage(messaging, (payload) => {
        navigator.serviceWorker.ready.then((registration) => {
          registration.active.postMessage(payload);
        });
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          window.location.reload();
        });
      });
    },
  },
};
</script>
