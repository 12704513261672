import service from '@/plugins/axios';

// 代碼
const root = '/a/a105';

// 取得省、縣市列表
export function cityList(params) {
  return service({
    url: `${root}/city_list`,
    method: 'get',
    params,
  });
}

// 取得區域 (行政區)列表
export function areaList(params) {
  return service({
    url: `${root}/area_list`,
    method: 'get',
    params,
  });
}

// 取得鄉、鎮、社、坊 (里)列表
export function wardList(params) {
  return service({
    url: `${root}/ward_list`,
    method: 'get',
    params,
  });
}
