import metaBg from '@/assets/meta/meta_bg.png';

const identity = 'medium';
const mediumRouter = {
  path: 'medium',
  component: () => import('@/views/memberCenter/page/HomeView.vue'),
  children: [
    // 首頁
    {
      path: 'home',
      name: 'mediumHome',
      component: () => import('@/views/memberCenter/page/common/home/HomeView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
        ],
        title: 'memberCenter.home',
        description: 'about.description1',
        image: metaBg,
        scrollAnchor: true,
        requiredAuth: true,
      },
    },
    // 帳號
    {
      path: 'account',
      component: () => import('@/views/memberCenter/page/common/account/AccountView.vue'),
      children: [
        {
          path: '',
          name: 'mediumAccount',
          component: () => import('@/views/memberCenter/page/common/account/InfoView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.account',
                pathName: 'mediumAccount',
              },
            ],
            title: 'memberCenter.account',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'certification',
          name: 'mediumCertification',
          component: () => import('@/views/memberCenter/page/common/account/certification/AccountCertification.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.account',
                pathName: 'mediumAccount',
              },
              {
                name: 'breadcrumbList.certification',
                pathName: 'mediumCertification',
              },
            ],
            title: 'breadcrumbList.certification',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'work-info',
          name: 'mediumWorkInfo',
          component: () => import('@/views/memberCenter/page/common/account/workData/AccountWorkData.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.account',
                pathName: 'mediumAccount',
              },
              {
                name: 'breadcrumbList.workData',
                pathName: 'mediumWorkInfo',
              },
            ],
            title: 'breadcrumbList.workData',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
      ],
    },
    {
      path: 'account/change-phone',
      name: 'mediumChangePhone',
      component: () => import('@/views/memberCenter/page/common/account/ChangePhone.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.account',
            pathName: 'mediumAccount',
          },
          {
            name: 'breadcrumbList.changePhone',
            pathName: 'mediumChangePhone',
          },
        ],
        title: 'breadcrumbList.changePhone',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'account/change-password',
      name: 'mediumChangePwd',
      component: () => import('@/views/memberCenter/page/common/account/ChangePwd.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.account',
            pathName: 'mediumAccount',
          },
          {
            name: 'breadcrumbList.changePwd',
            pathName: 'mediumChangePwd',
          },
        ],
        title: 'breadcrumbList.changePwd',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    // 訊息
    {
      path: 'message',
      component: () => import('@/views/memberCenter/page/common/message/MessageLayout.vue'),
      children: [
        {
          path: '',
          name: 'mediumMessage',
          component: () => import('@/views/memberCenter/page/common/message/message/MessageView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.message',
                pathName: 'mediumMessage',
              },
            ],
            title: 'breadcrumbList.message',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'qa/house',
          name: 'mediumHouseQA',
          component: () => import('@/views/memberCenter/page/common/message/qa/HouseQA.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.message',
                pathName: 'mediumMessage',
              },
              {
                name: 'breadcrumbList.houseQA',
                pathName: 'mediumHouseQA',
              },
            ],
            title: 'breadcrumbList.houseQA',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'qa/shop',
          name: 'mediumStoreQA',
          component: () => import('@/views/memberCenter/page/common/message/qa/StoreQA.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.message',
                pathName: 'mediumMessage',
              },
              {
                name: 'breadcrumbList.storeQA',
                pathName: 'mediumStoreQA',
              },
            ],
            title: 'breadcrumbList.storeQA',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
      ],
    },
    {
      path: 'message/content/:id',
      name: 'mediumMessageContent',
      component: () => import('@/views/memberCenter/page/common/message/message/MessageDetail.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.message',
            pathName: 'mediumMessage',
          },
          {
            name: 'breadcrumbList.messageContent',
            pathName: 'mediumMessageContent',
          },
        ],
        title: 'breadcrumbList.messageContent',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'message/qa/house/content/:id',
      name: 'mediumHouseQAContent',
      component: () => import('@/views/memberCenter/page/common/message/qa/HouseQADetail.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.message',
            pathName: 'mediumMessage',
          },
          {
            name: 'breadcrumbList.houseQA',
            pathName: 'mediumHouseQA',
          },
          {
            name: ':id',
            pathName: 'mediumHouseQAContent',
          },
        ],
        title: 'breadcrumbList.houseQA',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'message/qa/shop/content/:id',
      name: 'mediumStoreQAContent',
      component: () => import('@/views/memberCenter/page/common/message/qa/StoreQADetail.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.message',
            pathName: 'mediumMessage',
          },
          {
            name: 'breadcrumbList.storeQA',
            pathName: 'mediumStoreQA',
          },
          {
            name: ':id',
            pathName: 'mediumStoreQAContent',
          },
        ],
        title: 'breadcrumbList.storeQA',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    // 刊登
    {
      path: 'publish/:category',
      component: () => import('@/views/memberCenter/page/common/publish/PublishLayout.vue'),
      children: [
        {
          path: '',
          name: `${identity}Publish`,
          component: () => import('@/views/memberCenter/page/common/publish/publish/PublishHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.publishManagement',
            description: 'about.description1',
            image: metaBg,
            tabId: 1,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'single',
          name: `${identity}SinglePublish`,
          component: () => import('@/views/memberCenter/page/common/publish/publish/PublishHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.singlePosting',
                pathName: `${identity}SinglePublish`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.publishManagement',
            description: 'about.description1',
            image: metaBg,
            tabId: 1,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'rental',
          name: `${identity}RentalPublish`,
          component: () => import('@/views/memberCenter/page/common/publish/publish/PublishHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.rentalPlanPublication',
                pathName: `${identity}RentalPublish`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.publishManagement',
            description: 'about.description1',
            image: metaBg,
            tabId: 1,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'sale',
          name: `${identity}SalePublish`,
          component: () => import('@/views/memberCenter/page/common/publish/publish/PublishHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.salePlanPublication',
                pathName: `${identity}SalePublish`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.publishManagement',
            description: 'about.description1',
            image: metaBg,
            tabId: 1,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'closed',
          name: `${identity}ClosedHouse`,
          component: () => import('@/views/memberCenter/page/common/publish/close/ClosedHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.housesClosed',
                pathName: `${identity}ClosedHouse`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.housesClosed',
            description: 'about.description1',
            image: metaBg,
            tabId: 2,
            requiredAuth: true,
          },
        },
        {
          path: 'deal',
          name: `${identity}DealHouse`,
          component: () => import('@/views/memberCenter/page/common/publish/deal/DealHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.removedHouses',
                pathName: `${identity}DealHouse`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.removedHouses',
            description: 'about.description1',
            image: metaBg,
            tabId: 3,
            requiredAuth: true,
          },
        },
        {
          path: 'edit',
          name: `${identity}EditHouse`,
          component: () => import('@/views/memberCenter/page/common/publish/edit/EditHouse.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.publishManagement',
                pathName: `${identity}Publish`,
                category: ':category',
              },
              {
                name: 'breadcrumbList.housesEdit',
                pathName: `${identity}EditHouse`,
                category: ':category',
              },
            ],
            title: 'breadcrumbList.housesEdit',
            description: 'about.description1',
            image: metaBg,
            tabId: 4,
            requiredAuth: true,
          },
        },
      ],
    },
    {
      path: 'publish/:category/rental/:id',
      name: `${identity}RentalPublishDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}RentalPublishDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/sale/:id',
      name: `${identity}SalePublishDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/rental/:id/edit',
      name: `${identity}HouseEditRentalPublication`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}PublishDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseEditRentalPublication`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/sale/:id/edit',
      name: `${identity}HouseEditSalePublication`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseEditSalePublication`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/rental/:id/edit',
      name: `${identity}FactoryEditRentalPublication`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}PublishDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryEditRentalPublication`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/sale/:id/edit',
      name: `${identity}FactoryEditSalePublication`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryEditSalePublication`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/rental/:id/edit',
      name: `${identity}LandEditRentalPublication`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}PublishDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandEditRentalPublication`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/sale/:id/edit',
      name: `${identity}LandEditSalePublication`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandEditSalePublication`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/rental/:id/edit',
      name: `${identity}OfficeEditRentalPublication`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}PublishDetail`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}OfficeEditRentalPublication`,
            category: 'office',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/sale/:id/edit',
      name: `${identity}OfficeEditSalePublication`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}OfficeEditSalePublication`,
            category: 'office',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/rental/:id/edit',
      name: `${identity}ShopEditRentalPublication`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.rentalPlanPublication',
            pathName: `${identity}RentalPublish`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}PublishDetail`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}ShopEditRentalPublication`,
            category: 'shop',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/sale/:id/edit',
      name: `${identity}ShopEditSalePublication`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}ShopEditSalePublication`,
            category: 'shop',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/rental/:id/improve',
      name: `${identity}ImprovetRentalPublication`,
      component: () => import('@/views/publish/ThirdStep.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.salePlanPublication',
            pathName: `${identity}SalePublish`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}SalePublishDetail`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.improveEffect',
            pathName: `${identity}ImprovetRentalPublication`,
            category: ':category',
          },
        ],
        title: 'breadcrumbList.improveEffect',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/bonus/:type/:id',
      name: `${identity}HouseBonus`,
      component: () => import('@/views/memberCenter/page/common/publish/exposure/ExposureView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.improve',
            pathName: `${identity}HouseBonus`,
            category: ':category',
          },
        ],
        title: 'breadcrumbList.improve',
        description: 'about.description1',
        image: metaBg,
        tabId: 1,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/deal/rental/:id',
      name: `${identity}DealRentalDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/deal/sale/:id',
      name: `${identity}DealSaleDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/closed/rental/:id',
      name: `${identity}ClosedRentalDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/:category/closed/sale/:id',
      name: `${identity}ClosedSaleDetail`,
      component: () => import('@/views/memberCenter/page/common/publish/detail/DetailView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: ':category',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: ':category',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: ':category',
          },
        ],
        title: 'detail.housingDetails',
        description: 'about.description1',
        image: metaBg,
        showEditBtn: true,
        requiredAuth: true,
      },
    },
    // 編輯成交
    {
      path: 'publish/house/deal/rental/:id/edit',
      name: `${identity}HouseDealEditRental`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseDealEditRental`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/deal/sale/:id/edit',
      name: `${identity}HouseDealEditSale`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseDealEditSale`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/deal/rental/:id/edit',
      name: `${identity}FactoryDealEditRental`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryDealEditRental`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/deal/sale/:id/edit',
      name: `${identity}FactoryDealEditSale`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryDealEditSale`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/deal/rental/:id/edit',
      name: `${identity}LandDealEditRental`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandDealEditRental`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/deal/sale/:id/edit',
      name: `${identity}LandDealEditSale`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandDealEditSale`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/deal/rental/:id/edit',
      name: `${identity}OfficeDealEditRental`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}OfficeDealEditRental`,
            category: 'office',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/deal/sale/:id/edit',
      name: `${identity}OfficeDealEditSale`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}OfficeDealEditSale`,
            category: 'office',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/deal/rental/:id/edit',
      name: `${identity}ShopDealEditRental`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}DealRentalDetail`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}ShopDealEditRental`,
            category: 'shop',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/deal/sale/:id/edit',
      name: `${identity}ShopDealEditSale`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.removedHouses',
            pathName: `${identity}DealHouse`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}DealSaleDetail`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}ShopDealEditSale`,
            category: 'shop',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    // 編輯關閉
    {
      path: 'publish/house/closed/rental/:id/edit',
      name: `${identity}HouseClosedEditRental`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseClosedEditRental`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/closed/sale/:id/edit',
      name: `${identity}HouseClosedEditSale`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}HouseClosedEditSale`,
            category: 'house',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/closed/rental/:id/edit',
      name: `${identity}FactoryClosedEditRental`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryClosedEditRental`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/closed/sale/:id/edit',
      name: `${identity}FactoryClosedEditSale`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}FactoryClosedEditSale`,
            category: 'factory',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/closed/rental/:id/edit',
      name: `${identity}LandClosedEditRental`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandClosedEditRental`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/closed/sale/:id/edit',
      name: `${identity}LandClosedEditSale`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}LandClosedEditSale`,
            category: 'land',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/closed/rental/:id/edit',
      name: `${identity}OfficeClosedEditRental`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}OfficeClosedEditRental`,
            category: 'office',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/closed/sale/:id/edit',
      name: `${identity}OfficeClosedEditSale`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}OfficeClosedEditSale`,
            category: 'office',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/closed/rental/:id/edit',
      name: `${identity}ShopClosedEditRental`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedRentalDetail`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}ShopClosedEditRental`,
            category: 'shop',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/closed/sale/:id/edit',
      name: `${identity}ShopClosedEditSale`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.housesClosed',
            pathName: `${identity}ClosedHouse`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}ClosedSaleDetail`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.editListing',
            pathName: `${identity}ShopClosedEditSale`,
            category: 'shop',
          },
        ],
        title: 'breadcrumbList.editListing',
        description: 'about.description1',
        image: metaBg,
        requiredAuth: true,
      },
    },
    // 編輯未刊登
    {
      path: 'publish/house/edit-rental/:id',
      name: `${identity}EditRentalHouse`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}EditRentalHouse`,
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/house/edit-sale/:id',
      name: `${identity}EditSaleHouse`,
      component: () => import('@/views/publish/house/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'house',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'house',
          },
          {
            name: ':id',
            pathName: `${identity}EditSaleHouse`,
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/edit-rental/:id',
      name: `${identity}EditRentalFactory`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}EditRentalFactory`,
            category: 'factory',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/factory/edit-sale/:id',
      name: `${identity}EditSaleFactory`,
      component: () => import('@/views/publish/factory/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'factory',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'factory',
          },
          {
            name: ':id',
            pathName: `${identity}EditSaleFactory`,
            category: 'factory',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/edit-rental/:id',
      name: `${identity}EditRentalLand`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}EditRentalLand`,
            category: 'land',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/land/edit-sale/:id',
      name: `${identity}EditSaleLand`,
      component: () => import('@/views/publish/land/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'land',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'land',
          },
          {
            name: ':id',
            pathName: `${identity}EditSaleLand`,
            category: 'land',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/edit-rental/:id',
      name: `${identity}EditRentalOffice`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}EditRentalOffice`,
            category: 'office',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/office/edit-sale/:id',
      name: `${identity}EditSaleOffice`,
      component: () => import('@/views/publish/office/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'office',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'office',
          },
          {
            name: ':id',
            pathName: `${identity}EditSaleOffice`,
            category: 'office',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/edit-rental/:id',
      name: `${identity}EditRentalShop`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}EditRentalShop`,
            category: 'shop',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    {
      path: 'publish/shop/edit-sale/:id',
      name: `${identity}EditSaleShop`,
      component: () => import('@/views/publish/shop/SecondStep.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.publishManagement',
            pathName: `${identity}Publish`,
            category: 'shop',
          },
          {
            name: 'breadcrumbList.housesEdit',
            pathName: `${identity}EditHouse`,
            category: 'shop',
          },
          {
            name: ':id',
            pathName: `${identity}EditSaleShop`,
            category: 'shop',
          },
        ],
        title: 'memberCenter.editListing',
        description: 'about.description1',
        image: metaBg,
        tabId: 4,
        requiredAuth: true,
      },
    },
    // 店鋪
    {
      path: 'store',
      component: () => import('@/views/memberCenter/page/common/store/StoreLayout.vue'),
      children: [
        {
          path: '',
          name: 'mediumStore',
          component: () => import('@/views/memberCenter/page/common/store/storeInfo/StoreView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.storeManagement',
                pathName: 'mediumStore',
              },
            ],
            title: 'memberCenter.storeManagement',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'flyers',
          name: 'mediumStoreFlyers',
          component: () => import('@/views/memberCenter/page/common/store/storeFlyers/StoreFlyers.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.flyers',
                pathName: 'mediumStoreFlyers',
              },
            ],
            title: 'memberCenter.flyers',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
      ],
    },
    // 紀錄
    {
      path: 'history',
      component: () => import('@/views/memberCenter/page/common/history/HistoryLayout.vue'),
      children: [
        {
          path: '',
          name: 'mediumHistory',
          component: () => import('@/views/memberCenter/page/common/history/HistoryView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.history',
                pathName: 'mediumHistory',
              },
            ],
            title: 'breadcrumbList.history',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
        {
          path: 'favorite',
          name: 'mediumHistoryFavorite',
          component: () => import('@/views/memberCenter/page/common/history/HistoryView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.history',
                pathName: 'mediumHistory',
              },
              {
                name: 'breadcrumbList.favoriteObjects',
                pathName: 'mediumHistoryFavorite',
              },
            ],
            title: 'breadcrumbList.favoriteObjects',
            description: 'about.description1',
            image: metaBg,
            requiredAuth: true,
          },
        },
      ],
    },
    // 推廣
    {
      path: 'promotions',
      component: () => import('@/views/memberCenter/page/common/promotions/PromotionsLayout.vue'),
      children: [
        {
          path: '',
          name: 'mediumPromotions',
          component: () => import('@/views/memberCenter/page/common/promotions/PromotionsView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.promotions',
                pathName: 'mediumPromotions',
              },
            ],
            title: 'breadcrumbList.promotions',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
        {
          path: ':id',
          name: 'mediumPromotionsDetail',
          component: () => import('@/views/memberCenter/page/common/promotions/PromotionsDetail.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.promotions',
                pathName: 'mediumPromotions',
              },
              {
                name: 'breadcrumbList.activityDetails',
                pathName: 'mediumPromotionsDetail',
              },
            ],
            title: 'breadcrumbList.activityDetails',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
      ],
    },
    // 交易
    {
      path: 'transaction',
      component: () => import('@/views/memberCenter/page/common/transaction/TransactionLayout.vue'),
      children: [
        {
          path: '',
          name: 'mediumTransaction',
          component: () => import('@/views/memberCenter/page/common/transaction/point/TransactionView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.transaction',
                pathName: 'mediumTransaction',
              },
            ],
            title: 'breadcrumbList.transaction',
            description: 'about.description1',
            image: metaBg,
            showBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'plan',
          name: 'mediumTransactionOfPlan',
          component: () => import('@/views/memberCenter/page/common/transaction/plan/PlanView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.plansManagement',
                pathName: 'mediumTransactionOfPlan',
              },
            ],
            title: 'breadcrumbList.plansManagement',
            description: 'about.description1',
            image: metaBg,
            showBtn: false,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'cash',
          name: 'mediumTransactionOfCash',
          component: () => import('@/views/memberCenter/page/common/transaction/cash/CashView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.cashManagement',
                pathName: 'mediumTransactionOfCash',
              },
            ],
            title: 'breadcrumbList.cashManagement',
            description: 'about.description1',
            image: metaBg,
            showBtn: false,
            requiredAuth: true,
          },
        },
        {
          path: 'upgrade',
          name: 'mediumTransactionOfUpgrade',
          component: () => import('@/views/memberCenter/page/common/transaction/upgrade/PlanView.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'memberCenter.upgradeManagement',
                pathName: 'mediumTransactionOfUpgrade',
              },
            ],
            title: 'memberCenter.upgradeManagement',
            description: 'about.description1',
            image: metaBg,
            showBtn: false,
            requiredAuth: true,
          },
        },
        // TODO: 信用卡先隱藏
        // {
        //   path: 'credit-card',
        //   name: 'mediumCreditCard',
        //   component: () => import('@/views/memberCenter/page/common/transaction/creditCard/CreditCard.vue'),
        //   meta: {
        //     breadcrumbs: [
        //       {
        //         name: 'breadcrumbList.home',
        //         pathName: 'home',
        //       },
        //       {
        //         name: 'breadcrumbList.paymentManagement',
        //         pathName: 'mediumCreditCard',
        //       },
        //     ],
        //     showBtn: false,
        //   },
        // },
      ],
    },
    {
      path: 'transaction/purchase/:type/:id',
      name: 'mediumPurchasePlan',
      component: () => import('@/views/memberCenter/page/common/transaction/PurchasePlan.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.plansManagement',
            pathName: 'mediumTransactionOfPlan',
          },
          {
            name: 'breadcrumbList.purchasePlan',
            pathName: 'mediumPurchasePlan',
          },
        ],
        title: 'breadcrumbList.purchasePlan',
        description: 'about.description1',
        image: metaBg,
        showBtn: false,
        requiredAuth: true,
      },
    },
    // TODO: 信用卡先隱藏
    // {
    //   path: 'credit-card/add',
    //   name: 'mediumAddCreditCard',
    //   component: () => import('@/views/memberCenter/page/common/transaction/creditCard/AddCreditCard.vue'),
    //   meta: {
    //     breadcrumbs: [
    //       {
    //         name: 'breadcrumbList.home',
    //         pathName: 'home',
    //       },
    //       {
    //         name: 'breadcrumbList.paymentManagement',
    //         pathName: 'mediumCreditCard',
    //       },
    //       {
    //         name: 'breadcrumbList.addNewCard',
    //         pathName: 'mediumAddCreditCard',
    //       },
    //     ],
    //     showBtn: false,
    //   },
    // },
    {
      path: 'advertisement',
      component: () => import('@/views/memberCenter/page/common/advertisement/AdLayout.vue'),
      children: [
        {
          path: '',
          name: 'mediumAdvertisement',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'mediumAdvertisement',
              },
            ],
            title: 'breadcrumbList.advertisement',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
        {
          path: 'closed',
          name: 'mediumAdvertisementClosed',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'mediumAdvertisementClosed',
              },
            ],
            title: 'breadcrumbList.advertisement',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application',
          name: 'mediumAdvertisementApplication',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'mediumAdvertisementApplication',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/approve',
          name: 'mediumAdvertisementApplicationApprove',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'mediumAdvertisementApplicationApprove',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/review',
          name: 'mediumAdvertisementApplicationReview',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'mediumAdvertisementApplicationReview',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/not-reviewed',
          name: 'mediumAdvertisementApplicationNotReview',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'mediumAdvertisementApplicationNotReview',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showEditBtn: true,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/decline',
          name: 'mediumAdvertisementApplicationDecline',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'mediumAdvertisementApplicationDecline',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showBtn: true,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
        {
          path: 'application/plan',
          name: 'mediumAdvertisementPlan',
          component: () => import('@/views/memberCenter/page/common/advertisement/publish/AdPublish.vue'),
          meta: {
            breadcrumbs: [
              {
                name: 'breadcrumbList.home',
                pathName: 'home',
              },
              {
                name: 'breadcrumbList.memberHome',
                pathName: 'mediumHome',
              },
              {
                name: 'breadcrumbList.advertisement',
                pathName: 'mediumAdvertisementPlan',
              },
            ],
            title: 'breadcrumbList.advertisementApplicationRecord',
            description: 'about.description1',
            image: metaBg,
            showBtn: true,
            scrollAnchor: true,
            requiredAuth: true,
          },
        },
      ],
    },
    {
      path: 'advertisement/apply/:id',
      name: 'mediumAdvertisementApply',
      component: () => import('@/views/memberCenter/page/common/advertisement/apply/ApplyView.vue'),
      meta: {
        breadcrumbs: [
          {
            name: 'breadcrumbList.home',
            pathName: 'home',
          },
          {
            name: 'breadcrumbList.memberHome',
            pathName: 'mediumHome',
          },
          {
            name: 'breadcrumbList.advertisement',
            pathName: 'mediumAdevertisement',
          },
          {
            name: 'breadcrumbList.applyForAds',
            pathName: 'mediumAdvertisementApply',
          },
        ],
        title: 'breadcrumbList.applyForAds',
        description: 'about.description1',
        image: metaBg,
        showBtn: false,
        requiredAuth: true,
      },
    },
  ],
};
export default mediumRouter;
