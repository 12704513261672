<template>
  <ul class="dropdown" :class="{ 'visible': open }" style="width: 240px; right: 0px">
    <li>
      <div class="navbar_items--outline">
        <div class="navbar_items--outline--section">
          <router-link :to="{ name: 'video' }" class="navbar_items--video">
            <SvgIcon icon-class="play_circle_fill" class-name="navbar_items--video--icon" />
            <span>
                  {{ $t('navbar.video') }}
                </span>
          </router-link>
        </div>
      </div>
    </li>
    <li
        v-for="(item, index) in list"
        :key="index"
        class="dropdown_option"
        @click.stop="chooseOption(item)"
    >
      <router-link :to="{ name: item.route }">
        {{ item.name }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MoreDropdown',
  props: {
    open: {
      type: Boolean,
    },
  },
  emits: ['close'],
  data() {
    return {
      list: [{
        name: this.$t('navbar.forum'),
        route: 'forumAllArticlesOnHome',
      },
      {
        name: this.$t('footer.mortgageCalculator'),
        route: 'mortgageCalculator',
      },
      {
        name: this.$t('files.download'),
        route: 'files',
      }],
    };
  },
  watch: {
    lang(value, oldValue) {
      if (value !== oldValue) {
        this.list = [{
          name: this.$t('navbar.forum'),
          route: 'forumAllArticlesOnHome',
        },
        {
          name: this.$t('footer.mortgageCalculator'),
          route: 'mortgageCalculator',
        },
        {
          name: this.$t('files.download'),
          route: 'files',
        }];
      }
    },
  },
  computed: {
    ...mapGetters(['lang', 'isLogin', 'identity', 'name', 'role', 'isSub']),
  },
  mounted() {
    document.addEventListener('click', this.clickOtherElement);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickOtherElement);
  },
  methods: {
    chooseOption(value) {
      this.$router.push({ name: value.route });
      this.$emit('close');
    },
    clickOtherElement(e) {
      const userStatus = document.querySelector('#more');
      if (!userStatus.contains(e.target)) {
        this.$emit('close');
      }
    },
  },
};
</script>
