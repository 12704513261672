<template>
  <div class="loader_view" v-show="visible">
    <span class="loader"></span>
  </div>
</template>

<script>
export default {
  name: 'LoaderView',
  props: {
    visible: {
      type: Boolean,
    },
  },
};
</script>
